/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable prettier/prettier */
import { useLogout } from '../hooks';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography } from '@mui/material';
import connection from '../assets/images/connection-problem.svg';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

type Props = {
  message: string;
};

const invitationLinkAlreadyUsed = 'invitation not found or already used';
const usedDifferentmailratherThanInvited =
  'You can only activate your account with the email address used on your invitation';

const Error = ({ message }: Props) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const locMessage = location?.state?.message;
  const { doLogout } = useLogout();
  // function handleRedirect() {
  //   isAuthenticated ? doLogout() : loginWithRedirect();
  //   window.location.href = '/';
  // }

  function handleRedirect() {
    // if (message === usedDifferentmailratherThanInvited) {
    //   // window.location.href = `/#/login?org=${orgName}&prompt=true`;
    //   doLogout();
    // } else {
    //   isAuthenticated ? doLogout() : loginWithRedirect();
    //   window.location.href = '/';
    // }
    doLogout();
  }

  const orgName = localStorage.getItem('orgName');

  if (message === invitationLinkAlreadyUsed && !isAuthenticated) {
    handleRedirect();
    return null;
  }

  const customizedText = () => {
    return (
      <Box mb={2} mt={2}>
        <Typography fontSize="16px" pb={1} color={'primary'}>
          Please click Back to Dosen if you:
        </Typography>
        <ul className="listStyle-dots pl-12">
          <li>Previously used email and password to sign up with this account</li>
          <li>Tried to log in with an email that is different to the invited email</li>
          <li>Tried to log in before clicking 'Activate Account' in your invitation email</li>
        </ul>
      </Box>
    );
  };
  const userDoesNotExist = () => {
    return (
      <Box mb={2} mt={2}>
        <Typography fontSize="16px" pb={1} color={'primary'}>
          Please click Back to Dosen if you:
        </Typography>
        <ul className="listStyle-alpha pl-12">
          <li>Tried to log in with an email that is different to the invited email</li>
          <li>Tried to log in before clicking 'Activate Account' in your invitation email</li>
        </ul>
      </Box>
    );
  };

  const checkCustomMessage = () => {
    if (message.includes('client requires organization membership')) {
      return customizedText();
    }
    if (message.includes(`User doesn't exist`)) {
      return userDoesNotExist();
    }
    if (message.includes('is not part of the')) {
      return (
        <Typography fontSize="16px" pt={1} pb={4}>
          This email is not associated with this Organization. Please ensure that you’re using the email address that
          you were invited with{' '}
        </Typography>
      );
    }
    return (
      <Typography fontSize="16px" pt={1} pb={6}>
        {locMessage ? locMessage : message}
      </Typography>
    );
  };

  return (
    <Box display="flex" justifyContent="center" alignItems={{ xs: 'top', sm: 'center' }} height="100vh">
      <Box width="480px" border={{ xs: 'none', sm: '1px solid #e7e3e3' }} borderRadius="8px" bgcolor={'#FFFFFF'}>
        <Box
          mx={{ xs: 3, sm: 10 }}
          my={6}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <img src={connection} alt="error" height="101px" width="86px" />
          <Typography variant="h3" pt={3}>
            Somethings gone wrong
          </Typography>
          {checkCustomMessage()}

          <Button color="primary" variant="contained" fullWidth sx={{ height: '50px' }} onClick={handleRedirect}>
            Back to Dosen
          </Button>
          <Typography fontSize="16px" my={1}>
            or
          </Typography>
          <Typography fontSize="16px">Contact us at support@dosen.io</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Error;
