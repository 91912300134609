/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { TextField, DialogActions, Typography, Grid, Autocomplete, InputLabel } from '@mui/material';
import _ from 'lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import { API } from '../../../api';
import { toast } from 'react-toastify';
import { dialogActions } from '../../../redux/slices/dialog-slice/dialogSlice';
import { filterAcsUsersWithExistingUsers, getChatUsers } from '../../../routes/helpers';
import { useQueryClient } from '@tanstack/react-query';
import { fetchAcsChannelParticipants } from '../../../redux/slices/acs-channel-participants/acsChannelParticipants';
import { useSearchParams } from 'react-router-dom';
import ROLES from '../../../utils/roles';
import { getUserDetails } from '../../../utils/orgName';

const schema = yup
  .object({
    participants: yup
      .array()
      .of(
        yup.object({
          displayName: yup.string().required('display name required'),
          id: yup.object({
            communicationUserId: yup.string().required('communication id required')
          })
        })
      )
      .test('required', 'Participants are required', (value, context) => {
        if (value?.length) return true;
        return false;
      })
  })
  .required();

const ValidationError = ({ errors, fieldName }: { errors: Object; fieldName: string }) => (
  <Typography color="red" px={0.5} variant="subtitle2">
    {_.get(errors, `${fieldName}.message`, '')}
  </Typography>
);
let threadValue = '';

const AcsAddUserToExistingChannel: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const threadId = searchParams.get('threadid') || '';
  threadValue = threadId || '';
  const { user, getIdTokenClaims } = useAuth0();
  const [channelCreateLoading, setChannelCreateLoading] = useState(false);
  const acsToken = useAppSelector((state) => state.acsToken.data.token);
  const acsOrgUsers = useAppSelector((state) => state.acsCommunityUsers.data);
  const existingUsers = useAppSelector((state) => state.acsChannelParticipants.data);
  const dialogDetails = useAppSelector((state) => state.dialogActions.dialogDetails);
  const queryClient = useQueryClient();
  const loginUser = useAppSelector((state) => state.userProfile.data);
  const admins = _.get(loginUser, 'admins');

  const filteredUsers = filterAcsUsersWithExistingUsers(acsOrgUsers, existingUsers);
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const desiredAcsUsers = useMemo(() => {
    const loginUserRole = localStorage.getItem('role');
    const dropdownParticipants = watch('participants') ? watch('participants') : [];
    const existingUserCommunicationIds: any = _.map(existingUsers, (each) => _.get(each, 'id.communicationUserId'));
    const findExisting = _.filter(acsOrgUsers, (each: any) =>
      existingUserCommunicationIds.includes(_.get(each, 'id.communicationId', ''))
    );
    const selectedParticipants = [...dropdownParticipants, ...findExisting];
    const finalParticipants = getChatUsers(acsOrgUsers, loginUserRole, selectedParticipants, admins);
    return _.filter(
      finalParticipants,
      (each) =>
        !_.map(selectedParticipants, (each) => _.get(each, 'id.communicationId')).includes(
          _.get(each, 'id.communicationId')
        )
    );
  }, [filteredUsers, watch('participants'), existingUsers]);

  const onSubmit = async (values: any) => {
    // const token = await getAccessTokenSilently()
    const idToken = await getIdTokenClaims();
    const { orgId } = getUserDetails();
    const data = {
      ...values,
      threadId: _.get(dialogDetails, 'threadId'),
      azureToken: acsToken
    };
    setChannelCreateLoading(true);
    try {
      const { status } = await API.addParticipantsToExistingChannel({
        idtoken: _.get(idToken, '__raw', ''),
        data,
        orgId
      });
      if (status === 200) {
        setChannelCreateLoading(false);
        dispatch(dialogActions.atnCloseDialog());
        dispatch(
          fetchAcsChannelParticipants({
            acsToken: acsToken,
            threadId: threadValue
          })
        );
        queryClient.fetchQuery({
          queryKey: ['recieveMessages', _.get(dialogDetails, 'threadId', '')]
        });
        toast.success('Participant added successfully');
      }
    } catch (err) {
      setChannelCreateLoading(false);
      // toast.error(_.get(err, 'response.data', ''));
      toast.error('Something went wrong, please try again');
    }
  };

  const getName = (mem: any) => {
    // const chatProfileFirstName: any = _.get(mem, 'id.firstName', '');
    // const chatProfileLastName: any = _.get(mem, 'id.lastName', '');
    // return chatProfileFirstName
    //   ?.charAt(0)
    //   ?.toUpperCase()
    //   ?.concat(chatProfileFirstName?.slice(1)?.toLowerCase())
    //   ?.concat(
    //     ' ',
    //     chatProfileLastName?.charAt(0)?.toUpperCase()?.concat(chatProfileLastName?.slice(1)?.toLowerCase())
    //   );
    return _.get(mem, 'displayName', '');
  };

  return (
    <Grid>
      <Grid item xs={12}>
        <InputLabel>Member</InputLabel>
        <Controller
          name="participants"
          control={control}
          render={({ field: { onChange, value } }) => {
            if (!value) {
              onChange([]);
            }

            return (
              <Autocomplete
                multiple
                id="participants"
                options={desiredAcsUsers || []}
                getOptionLabel={(option) => getName(option)}
                defaultValue={[]}
                onChange={(e, value) => {
                  onChange(value);
                }}
                value={value || []}
                isOptionEqualToValue={(option, value) =>
                  _.get(option, 'id.communicationUserId') === _.get(value, 'id.communicationUserId')
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={_.get(option, 'id.communicationUserId', '')}>
                      {getName(option)}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Add Member" />}
              />
            );
          }}
        />
        <ValidationError errors={errors} fieldName="participants" />
      </Grid>
      <DialogActions sx={{ px: 0, pb: 0, pt: 2 }}>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          fullWidth
          color="primary"
          loading={channelCreateLoading}
          disabled={channelCreateLoading}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Grid>
  );
};

export default AcsAddUserToExistingChannel;
