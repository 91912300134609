/* eslint-disable no-duplicate-imports */
import { useEffect, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import favicon from '../../assets/images/favicon.svg';
import verticallogo from '../../assets/images/verticallogo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUserDetails } from '../../utils/orgName';
import { breadCrumbsActions } from '../../redux/slices/breadcrumbs/breadcrumbsSlice';
import ROLES from '../../utils/roles';
import _ from 'lodash';
import useProfileDetails from '../../hooks/useProfileDetails';
/** New Icons */
import activehome from '../../assets/images/sidebar/activhome.svg';
import home from '../../assets/images/sidebar/home.svg';
import activechat from '../../assets/images/sidebar/activechat.svg';
import chat from '../../assets/images/sidebar/chat.svg';
import activecommunity from '../../assets/images/sidebar/activecommunity.svg';
import community from '../../assets/images/sidebar/community.svg';
import activelibrary from '../../assets/images/sidebar/activeibrary.svg';
import library from '../../assets/images/sidebar/library.svg';
import activeprogram from '../../assets/images/sidebar/activeprogram.svg';
import program from '../../assets/images/sidebar/program.svg';
import activecalendar from '../../assets/images/sidebar/activecalendar.svg';
import calendar from '../../assets/images/sidebar/calendar.svg';
import activehelp from '../../assets/images/sidebar/activehelp.svg';
import help from '../../assets/images/sidebar/help.svg';
import { groupRoleListUpdate } from '../../redux/slices/groupRolesListSlice';
import { API } from '../../api';
import { notificationCount } from './components/common';
import { AGILE_ORG } from '../../utils/constants';
import { CommonStyle } from '../../utils/muiStyle';
import useResponsiveScreenSwitch from '../../utils/useResponsiveScreenSwitch';
import ChatUnreadNotificationsBox from './tabs/chat/ChatUnreadNotificationsBox';
import { groupLoaderActions } from '../../redux/slices/groupLoaderSlice';

const Sidebar = () => {
  const classes = CommonStyle();
  const { admins = [], mentee = [], mentors = [] } = useProfileDetails();
  const orgGroupData = useAppSelector((state) => state.orgLevelGroup);
  const grpId = orgGroupData.data?.grpId || 'admin';
  const { coId } = getUserDetails();
  const { orgId } = getUserDetails();
  const dispatch = useAppDispatch();
  const role = localStorage.getItem('role') || '';
  const navigate = useNavigate();
  const location = useLocation();
  const acsChannels = useAppSelector((state) => state.acsChannels);
  const chatNotifications = useAppSelector((state) => state.messageNotifications.notifications);
  const groupThreadsMsgCount = useAppSelector((state) => state?.acsChannels.groupThreads) || [];
  const personalThreadMsgCount: any = useAppSelector((state) => state?.acsChannels.personalThreadsArr) || [];
  /** Combine both arrays */
  const allThreads: any = groupThreadsMsgCount.concat(personalThreadMsgCount);
  /** Calculate total message count */
  const totalMsgCount = allThreads.reduce((acc: any, thread: any) => {
    return thread.msgCount > 0 ? acc + thread.msgCount : acc;
  }, 0);
  localStorage.setItem('totalMsgCount', totalMsgCount);
  const getTotalCount: any = localStorage.getItem('totalMsgCount') || 0;
  const pathName = location.pathname;
  const mentorClicked = localStorage.getItem('mentorClicked');
  const [homeRoute, setHomeRoute] = useState('');
  const selectedGroup = useAppSelector((state) => state.selectedGroup.data);
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);
  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
  const orgDetails = useAppSelector((state) => state.orgDetails.data);
  const callsCount = useAppSelector((state) => state.callsCount.callsCount);

  useEffect(() => {
    if (mentorClicked) {
      setHomeRoute(
        localStorage.getItem('mentorClicked') === 'true' && !_.isEmpty(mentors)
          ? '/app/home/mentor'
          : !_.isEmpty(mentee)
          ? '/app/home/mentee'
          : '/app/home'
      );
    } else {
      setHomeRoute(!_.isEmpty(mentors) ? '/app/home/mentor' : !_.isEmpty(mentee) ? '/app/home/mentee' : '/app/home');
    }
  }, [mentorClicked]);

  const sideNavbarArray = [
    {
      id: 'home',
      name: 'Home',
      icon: <img src={activehome} alt="" className="sidebar-icon" />,
      notActive: <img src={home} alt="" className="sidebar-icon home inActive" />,
      route: '/app/redirect'
    },
    {
      id: 'organizations',
      name: 'Orgs',
      icon: <img src={activeprogram} alt="" className="sidebar-icon" />,
      notActive: <img src={program} alt="" className="sidebar-icon inActive" />,
      route: '/app/organizations'
    },
    // {
    //   id: 'programdetails',
    //   name: 'Program',
    //   icon: <img src={activeprogram} alt='' className="sidebar-icon" />,
    //   notActive: <img src={program} alt='' className="sidebar-icon inActive" />,
    //   route: `/app/programdetails/${orgId}/${coId}/${grpId}/overview`
    // },
    {
      id: 'chat',
      name: 'Chat',
      icon: <img src={activechat} alt="" className="sidebar-icon" />,
      notActive: <img src={chat} alt="" className="sidebar-icon inActive" />,
      route: '/app/chat'
    },
    {
      id: 'community',
      name: 'Community',
      icon: <img src={activecommunity} alt="" className="sidebar-icon" />,
      notActive: <img src={community} alt="" className="sidebar-icon inActive" />,
      route: '/app/community'
    },
    {
      id: 'library',
      name: 'Library',
      icon: <img src={activelibrary} alt="" className="sidebar-icon" />,
      notActive: <img src={library} alt="" className="sidebar-icon inActive" />,
      route: '/app/library'
    },
    // {
    //   id: "knowledge",
    //   name: "Knowledge",
    //   route: "/app/knowledge",
    // },
    {
      id: 'calendar',
      name: 'Calendar',
      icon: <img src={activecalendar} alt="" className="sidebar-icon" />,
      notActive: <img src={calendar} alt="" className="sidebar-icon inActive" />,
      route: '/app/calendar'
    },
    {
      id: 'help',
      name: 'Help',
      icon: <img src={activehelp} alt="" className="sidebar-icon" />,
      notActive: <img src={help} alt="" className="sidebar-icon inActive" />,
      route: '/app/help'
    }
    // {
    //   id: 'liveChat',
    //   notActive: <img src={circleicon} alt='' />,
    //   route: '/app/livechat'
    // }
  ];

  const grpRoleListState = useAppSelector((state) => state.groupRoleList);

  const handleNavigateChatBot = () => {
    navigate('/app/aichatbot');
  };

  const fetchUserGroupList = async () => {
    try {
      dispatch(groupRoleListUpdate.upadateState({ ...grpRoleListState, loader2: true }));
      const response = await API.groupsListForUser(orgId);
      if (response.status === 200) {
        dispatch(
          groupRoleListUpdate.upadateState({
            loading: false,
            data: response.data.data || undefined,
            error: false,
            errroText: '',
            loader2: false
          })
        );
      }
    } catch (e) {
      dispatch(
        groupRoleListUpdate.upadateState({
          loading: false,
          data: undefined,
          error: true,
          errroText: _.get(e, 'response.data.message', `Failed to get member group role list`),
          loader2: false
        })
      );
    }
  };

  return (
    <Box className="sideBarMain">
      <Box className="logoArea">
        <img src={verticallogo} alt="" width="48" height="55" />
      </Box>
      <Box className="sideBarTabsBox">
        <Box className="sideBarTabsMain">
          {sideNavbarArray.map((each) => {
            if (each.id === 'home') {
              if (
                role === ROLES.platAdmin ||
                (oid === AGILE_ORG && mentee.length > 0 && admins.length === 0 && mentors.length === 0)
              ) {
                return null;
              }
            }

            if (each.id === 'organizations') {
              if (role !== ROLES.platAdmin) {
                return null;
              }
            }

            if (
              each.id === 'library' &&
              (role === ROLES.platAdmin ||
                (role !== ROLES.orgAdmin && admins.length === 0 && mentors.length === 0 && mentee.length === 0))
            ) {
              return null;
            }
            if (
              each.id === 'help' &&
              (role === ROLES.platAdmin ||
                (role === ROLES.orgAdmin && admins.length === 0 && mentors.length === 0 && mentee.length === 0))
            ) {
              return null;
            }

            const activeCondition =
              pathName.includes(`/app/${each.id}`) ||
              ((each.id === 'organizations' || each.id === 'home') && pathName.includes('programdetails'));
            const handleItemClick = () => {
              if (each.id === 'chat' && pathName.includes(`/app/${each.id}`)) {
                return; // Don't navigate if already on the chat route
              }
              if (each.id === 'programdetails') {
                dispatch(groupLoaderActions.updateState(true));
                dispatch(
                  breadCrumbsActions.removeBreadCrumbs({
                    id: grpId,
                    name: 'Program',
                    url: `/app/programdetails/${orgId}/${coId}/${grpId}/overview`
                  })
                );
              }
              // if (each.id === 'home' && location.pathname.includes('/app/home')) {
              //   return;
              // }
              if (each.id === 'home') {
                fetchUserGroupList();
              }
              navigate(each.route);
            };
            return (
              <Box
                className="sideBarTabsIcons"
                onClick={selectedGroup.active ? handleItemClick : each.id === 'home' ? handleItemClick : () => {}}
                key={each.id}
              >
                <IconButton aria-label={each.id}>{activeCondition ? each.icon : each.notActive}</IconButton>
                <Typography className="tabName" color={activeCondition ? 'white' : '#FFFFFF99'}>
                  {each.name}
                </Typography>

                {/* Chat Unread Notifications */}
                {each.name === 'Chat' && getTotalCount > 0 && (
                  <ChatUnreadNotificationsBox count={Number(getTotalCount)} right={'-7px'} printAres="sidebar" />
                )}

                {/* Calendar Unread Notifications */}
                {each.name === 'Calendar' && callsCount > 0 && (
                  <ChatUnreadNotificationsBox
                    count={callsCount}
                    right={forMobile ? '-6px' : '5px'}
                    printAres="sidebar"
                  />
                )}
              </Box>
            );
          })}
        </Box>

        {/* Dosen btn */}
        {!forMobile && (
          <Box className={classes.dosenBtn} width="79px" height="79px" onClick={handleNavigateChatBot}>
            <img src={favicon} alt="" width="40" height="40" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Sidebar;
