/* eslint-disable no-empty */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import _ from 'lodash';
import { API } from '../../../../api';
import { useAuth0 } from '@auth0/auth0-react';
// import ROLES from '../../../../utils/roles';
import LibraryFilter from './components/LibraryFilter';
import LibraryDetails from './components/LibraryDetails';
import LibrarySideBar from './components/LibrarySideBar';
import { useParams, useLocation } from 'react-router-dom';
import { getUserDetails } from '../../../../utils/orgName';
// import { useLoader } from '../../components/useLoader';
// import AppLoader from '../../../../components/AppLoader';
import { useXpPoints } from '../../components/menteeXpPoints';
import useProfileDetails from '../../../../hooks/useProfileDetails';
// import RecommendedArticle from './components/RecommendedArticle';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import { fetchAllArticlesUser } from '../../../../redux/slices/article/getAllArticleUserSlice';
import { industryArray, topicArray, updateInsightDataByArticleId } from '../../components/common';

export interface IArticleData {
  articleId: string;
  location: string;
  orgId: string;
  groups: string[];
  articleName: string;
  type: string;
  coverImageUrl: string;
  fileUrl: string;
  authorName: string;
  authorId: string;
  createdBy?: string;
  readTime: string;
  accessFor: string;
  tags: string[];
  industries: string[];
  articleDescription: string;
  embeddedLinks: string;
  approved: boolean;
  upVotes: number;
  downVotes: number;
  views: number;
  isPublished: boolean;
  createdAt: number;
  __v: number;
  isVoted: boolean;
  isViewed: boolean;
}

const LibraryTab = () => {
  const activeItemRef = useRef<any>(null);
  const location = useLocation();
  const navigatedArticleId = location.state?.navigatedArticleId;
  const navigatedArticleLocation = location.state?.navigatedArticleLocation;
  const allTags = location.state?.allTags;
  const getXpPoints = useXpPoints();
  const role = localStorage.getItem('role');
  const dispatch = useAppDispatch();
  const [articleDetails, setArticleDetails] = useState<IArticleData | undefined>();
  const [articleDetailsLoading, setArticleDetailsLoading] = useState(false);
  const allArticleData = useAppSelector((state) => state.allArticleUser?.data?.ArticleList) ?? [];
  const [articleData, setArticleData] = useState<any>();

  const loading11 = useAppSelector((state) => state?.allArticleUser?.loading);
  const error = useAppSelector((state) => state?.allArticleUser?.error);
  const { mentee } = useProfileDetails();
  const [selectedArticleId, setSelectedArticleId] = useState(
    navigatedArticleId ? navigatedArticleId : allArticleData[0]?.articleId || ''
  );
  const userLastseen = localStorage.getItem('lastseen');
  const lastseenInLocal = userLastseen ? JSON.parse(userLastseen) : null;
  const { category, timezone, phoneNumber, lastName, lastseen } = useProfileDetails();
  const [likeStatus, setLikeStatus] = useState('');
  let [isvote, setIsvote] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDown, setLoadingDown] = useState(false);
  const params = useParams();
  const grpId = params.id || '';
  const { orgId } = getUserDetails();
  const userloc = getUserDetails().location;
  const [openFilter, setOpenFilter] = useState(false);
  let tags: string[] = [];
  let types: string[] = [];
  let industries: string[] = [];

  /** For responsive */
  const { forMobile, mobileBackBtn, mobileLeftOff, showContainerBoxRight, showContainerBoxLeft } =
    useResponsiveScreenSwitch(); // Use the hook
  const [userLocation, setUserLocation] = useState<any>(
    navigatedArticleLocation ? navigatedArticleLocation : allArticleData[0]?.location || ''
  );

  useEffect(() => {
    if (allArticleData?.length) {
      setArticleData(allArticleData);
    }
  }, [allArticleData]);

  const handleArticle = async (ele: any) => {
    if (ele.articleId !== selectedArticleId || forMobile) {
      setSelectedArticleId(ele.articleId);
      setLikeStatus('');
      setUserLocation(ele?.location);
      mobileLeftOff(); // Call the mobileLeftOff function
    }

    const libraryRightClass = 'containerBoxRight';
    document.getElementsByClassName(libraryRightClass)[0]?.scrollTo(0, 0);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (activeItemRef.current) {
        activeItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedIndustryTags, setSelectedIndustryTags] = useState<string[]>([]);
  const { location1, orgId: userOrgId } = getUserDetails();
  const [tagList, setTagList] = useState<string[]>([]);
  const [industryTags, setIndustryTags] = useState<string[]>([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  useEffect(() => {
    if (allTags) {
      setSelectedTags(allTags);
    }
  }, []);

  const getTopics = async () => {
    try {
      const response = await API.getAllTopicsForUser(userloc);
      if (response.status === 200) {
        return response.data?.topics;
      } else {
        return [];
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Topics failed to load`));
      return [];
    }
  };

  const getIndustries = async () => {
    try {
      const response = await API.getAllIndustriesForUser(userloc);
      if (response.status === 200) {
        return response.data?.industries;
      } else {
        return [];
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Topics failed to load`));
      return [];
    }
  };

  useEffect(() => {
    Promise.all([getTopics(), getIndustries()]).then(([tagList1, tagList2]) => {
      setTagList(tagList1);
      setIndustryTags(tagList2);
    });
  }, []);

  const fetchTagList = async (type: string) => {
    try {
      const responseFYE = await API.getTagsListnew(userOrgId, location1, type, '', true);
      if (responseFYE.status === 200) {
        return responseFYE?.data?.tagListResponse.map((each: any) => each.value) || [];
      } else {
        return [];
      }
    } catch {
      return [];
    }
  };

  const handleTypeChange = (type: string) => {
    setSelectedTypes((prevTypes) =>
      prevTypes.includes(type) ? prevTypes.filter((t) => t !== type) : [...prevTypes, type]
    );
  };
  const topics = topicArray();
  const industryList = industryArray();
  const handleTagChange = (type: string) => {
    const index = topics.findIndex((each) => each.label === type);
    const name = index !== -1 ? topics[index].name : '';
    setSelectedTags((prevTypes) =>
      prevTypes.includes(name) ? prevTypes.filter((t) => t !== name) : [...prevTypes, name]
    );
  };

  const handleIndustryChange = (industry: string) => {
    const index = industryList.findIndex((each) => each.label === industry);
    const name = index !== -1 ? industryList[index].name : '';
    setSelectedIndustryTags((prevIndustryTags) =>
      prevIndustryTags.includes(name) ? prevIndustryTags.filter((t) => t !== name) : [...prevIndustryTags, name]
    );
  };

  const handleClickOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleClickCloseIcon = () => {
    setOpenFilter(false);
    setApplyFilter(false);
    setFiltersApplied(false);
    // setSelectedArticleId('');
    // setUserLocation('');
  };
  const [applyFilter, setApplyFilter] = useState<boolean>(false);

  const handleClickApply = () => {
    setApplyFilter(true);

    fetchFilteredArticles(selectedTypes, selectedTags, selectedIndustryTags);

    // dispatch(
    //   fetchAllArticlesUser({
    //     orgId: orgId,
    //     type: selectedTypes,
    //     tags: selectedTags,
    //     industries: selectedIndustryTags,
    //     helpSettings: false,
    //     useCache: false
    //   })
    // ).then((res) => {
    //   const data = _.get(res, 'payload.data.ArticleList');
    //   if (_.size(data) > 0) {
    //     console.log(_.head(data));

    //     handleArticle(_.head(data));
    //   }
    // });
    setOpenFilter(false);
    setFiltersApplied(true);
  };

  const fetchFilteredArticles = async (
    selectedTypes: string[],
    selectedTags: string[],
    selectedIndustryTags: string[]
    // industry: string[],
    // skillType: string[]
  ) => {
    setLoading(true);
    const filteredResponse =
      allArticleData && (selectedTypes?.length || selectedTags?.length || selectedIndustryTags?.length)
        ? allArticleData?.filter((member: any) => {
            // //   const roleMatch = member?.userType?.some((each: any) => roleSelected?.map(s => s.toLowerCase().includes(each.toLowerCase())))
            // const roleMatch = member?.type?.find((each: any) => selectedTypes?.includes(each.charAt(0).toUpperCase() + each.slice(1)))
            const roleMatch = selectedTypes.length > 0 ? selectedTypes?.includes(member?.type) : true;
            //  const hobbiesMatch = member?.hobbies?.some((hobby: any) => hobbiesAndInterest?.map(s => s.toLowerCase()).includes(hobby.toLowerCase()));
            const tagsMatched =
              selectedTags.length > 0
                ? member?.tags?.some((tag: any) =>
                    selectedTags?.map((s) => s.toLowerCase()).includes(tag.toLowerCase())
                  )
                : true;
            const industryMatched =
              selectedIndustryTags.length > 0
                ? member?.industries?.some((ind: any) =>
                    selectedIndustryTags?.map((s) => s.toLowerCase()).includes(ind.toLowerCase())
                  )
                : true;

            // //   return roleMatch || hobbiesMatch || industryMatch || skillsMatched
            return roleMatch && tagsMatched && industryMatched;
          })
        : allArticleData;

    // allArticleData : allArticleData

    // const filteredResponse = allArticleData
    setArticleData(filteredResponse);
    setSelectedArticleId(filteredResponse[0]?.articleId);

    setUserLocation(filteredResponse[0]?.location);
    setLoading(false);
  };

  // const isAgileExec = IsAgileExecutive();

  // const firstRender = useRef(true);
  // useEffect(() => {
  //   if (firstRender.current) {
  //     firstRender.current = false;
  //     return;
  //   }
  //   // dispatch(
  //   //   communityFilterAction.updateCommunityFilter({
  //   //     selectedRole,
  //   //     selectedUserType,
  //   //     selectedIndustry,
  //   //     selectedSkillType,
  //   //     selectedHobbies
  //   //   })
  //   // );
  // }, [applyFilter]);

  useEffect(() => {
    if (!navigatedArticleId && allArticleData?.length > 0 && articleData?.length > 0) {
      // setSelectedArticleId(allArticleData[0]?.articleId);
      setSelectedArticleId(articleData[0]?.articleId);

      // setUserLocation(allArticleData[0]?.location);
      setUserLocation(articleData[0]?.location);
    }
  }, [allArticleData, articleData, orgId, applyFilter, filtersApplied]);

  const handleresetFilter = () => {
    setSelectedTypes([]);
    setSelectedTags([]);
    setSelectedIndustryTags([]);
    setOpenFilter(false);
    fetchFilteredArticles([], [], []);

    // dispatch(
    //   fetchAllArticlesUser({ orgId: orgId, type: types, tags: tags, industries: industries, helpSettings: false })
    // );
    setFiltersApplied(false);
    setApplyFilter(false);
  };

  useEffect(() => {
    if (allTags) {
      setArticleDetails(undefined);
      dispatch(
        fetchAllArticlesUser({
          orgId: orgId,
          type: types,
          tags: allTags,
          industries: industries,
          helpSettings: false,
          useCache: true
        })
      );
      if (articleData && articleData.length > 0) {
        setSelectedArticleId(articleData[0]?.articleId);
        setUserLocation(articleData[0]?.location);
      }
    }
    if (!allTags) {
      dispatch(
        fetchAllArticlesUser({
          orgId: orgId,
          type: types,
          tags: tags,
          industries: industries,
          helpSettings: false,
          useCache: true
        })
      );
    }
  }, []);

  useEffect(() => {
    async function fetchArticle() {
      try {
        setArticleDetailsLoading(true);
        const response = await API.getAllArticleById({
          orgId: orgId,
          articleId: selectedArticleId,
          groupId: grpId,
          isContentSettings: false,
          articleLocation: userLocation ? userLocation : '',
          useCache: true
        });

        if (response && response?.status === 200) {
          setArticleDetails(response.data.article);
          setIsvote(response.data.isVoted);
          setLikeStatus(response.data.isVoted ? 'upVote' : response.data.isVoted === false ? 'downVote' : '');
          setArticleDetailsLoading(false);
        } else {
        }
      } catch (error) {}
      updateInsightDataByArticleId(orgId, selectedArticleId, userLocation);
    }

    if (selectedArticleId) {
      fetchArticle();
    }
  }, [selectedArticleId, orgId, applyFilter, filtersApplied]);

  const handleLikeUp = async () => {
    setLoading(true);
    if (likeStatus === 'upVote') {
      const response = await API.articleAction({
        orgId: orgId,
        articleId: selectedArticleId,
        data: { action: 'upVote' }
      });
      if (response?.status === 200) {
        setLikeStatus('');
        setArticleDetails(response.data.getArticle);
        setLoading(false);
      }
    } else {
      const response = await API.articleAction({
        orgId: orgId,
        articleId: selectedArticleId,
        data: { action: 'upVote' }
      });

      if (response?.status === 200) {
        setArticleDetails(response.data.getArticle);
        setLikeStatus('upVote');
        setLoading(false);
      }
    }
  };

  const handleLikeDown = async () => {
    setLoadingDown(true);
    if (likeStatus === 'downVote') {
      const response = await API.articleAction({
        orgId: orgId,
        articleId: selectedArticleId,
        data: { action: 'downVote' }
      });

      if (response?.status === 200) {
        setArticleDetails(response.data.getArticle);

        setLikeStatus('');
        setLoadingDown(false);
      }
    } else {
      const response = await API.articleAction({
        orgId: orgId,
        articleId: selectedArticleId,
        data: { action: 'downVote' }
      });

      if (response?.status === 200) {
        setLikeStatus('downVote');
        setArticleDetails(response.data.getArticle);
        setLoadingDown(false);
      }
    }
  };

  if (error) {
    return <Box>Error occurred while fetching data.</Box>;
  }
  return (
    <>
      <Box className="containerBox">
        {showContainerBoxLeft ? (
          <Box className={`containerBoxLeft`}>
            <LibrarySideBar
              allArticleData={articleData ?? []}
              allArticleLoading={loading11}
              onClick={handleArticle}
              selectedArticleId={selectedArticleId}
              handleClickOpenFilter={handleClickOpenFilter}
              activeItemRef={activeItemRef}
            />
          </Box>
        ) : null}
        {showContainerBoxRight ? (
          <Box className={`containerBoxRight`}>
            {/* For Mobile -> details to back button */}
            {mobileBackBtn()}

            <Grid container width={'100%'}>
              <Grid item xs={12} md={12}>
                <Box className="CardBox">
                  {articleData?.length ? (
                    <LibraryDetails
                      articleDetails={articleDetails}
                      selectedArticleId={selectedArticleId}
                      handleLikeUp={handleLikeUp}
                      handleLikeDown={handleLikeDown}
                      likeStatus={likeStatus}
                      isVoted={isvote}
                      loading={loading}
                      loadingDown={loadingDown}
                      isconcernData={false}
                      articleDetailsLoading={articleDetailsLoading || loading11}
                    />
                  ) : (
                    <LibraryDetails
                      articleDetails={undefined}
                      selectedArticleId={selectedArticleId}
                      handleLikeUp={handleLikeUp}
                      handleLikeDown={handleLikeDown}
                      likeStatus={likeStatus}
                      isVoted={isvote}
                      loading={loading}
                      loadingDown={loadingDown}
                      isconcernData={false}
                      articleDetailsLoading={articleDetailsLoading || loading11}
                    />
                  )}
                </Box>
              </Grid>
              {/* <Grid item xs={12} md={4}>
                <RecommendedArticle
                  direction="column"
                  place="library"
                  // data={[]}
                  handleArticleClick={(id) => console.log('Clicked on article with ID:', id)}
                />
              </Grid> */}
            </Grid>
          </Box>
        ) : null}
      </Box>

      {/* Filter */}
      <LibraryFilter
        loading={loading11}
        openFilter={openFilter}
        handleClickApply={handleClickApply}
        handleTypeChange={handleTypeChange}
        selectedTypes={selectedTypes}
        selectedTags={selectedTags}
        handleTagChange={handleTagChange}
        handleresetFilter={handleresetFilter}
        handleClickCloseIcon={handleClickCloseIcon}
        tagList={tagList}
        industryTags={industryTags}
        selectedIndustryTags={selectedIndustryTags}
        handleIndustryChange={handleIndustryChange}
      />
    </>
  );
};

export default LibraryTab;
