/* eslint-disable no-useless-catch */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API } from '../../../api';
import { Key } from 'react';
interface CallData {
  [x: string]: Key | null | undefined;
  callId: string;
  callTitle: string;
  startTime: string;
  endTime: string;
  // Add other properties as needed
}

interface CallsState {
  loading: boolean;
  data: CallData[];
  error: boolean;
  errorText: string;
}

const initialState: CallsState = {
  loading: false,
  data: [],
  error: false,
  errorText: ''
};

export const fetchCalls = createAsyncThunk(
  'getScheduleCall',
  async ({
    user_id,
    userCommunicationId,
    orgId,
    location,
    page,
    pageSize
  }: {
    user_id: string;
    userCommunicationId: string;
    orgId: string;
    location: string;
    page : number;
    pageSize : number;
  }) => {
    try {
      const response = await API.getScheduleCall(user_id, userCommunicationId, orgId, location , page , pageSize);
      return response.data.data || [];
    } catch (error) {
      return [];
    }
  }
);

const callsSlice = createSlice({
  name: 'calls',
  initialState,
  reducers: {
    setCallRows: (state, action: PayloadAction<CallData[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCalls.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorText = '';
      })
      .addCase(fetchCalls.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.errorText = '';
        state.data = action.payload;
      })
      .addCase(fetchCalls.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorText = action.error.message || 'Failed to load.';
      });
  }
});

export const { setCallRows } = callsSlice.actions;
export default callsSlice.reducer;
