// PasswordAndSecurity.js
import React, { useState } from 'react';
import _ from 'lodash';
import { Box, Grid, Divider, TextField, IconButton, InputLabel, Typography, InputAdornment } from '@mui/material';
import * as yup from 'yup';
import { API } from '../../../../api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import MyTooltip from '../../components/MyTooltip';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CryptoJS from 'crypto-js';

// Function to encrypt text using AES-256-CBC
function encrypt(text: any, key: string): string {
  const encrypted = CryptoJS.AES.encrypt(text, key).toString();
  return encrypted;
}

interface FormData {
  oldPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

interface PasswordRequirementProps {
  label: string;
  descriptionConditions: Array<{ condition: boolean; description: string }>;
  checkIconCondition: Array<{ condition: boolean; description: string }>;
  successColor?: string;
  failureColor?: string;
}

const PasswordAndSecurity = () => {
  const orgId = useParams().orgId || '';
  const [passwordScreen, setPasswordScreen] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const passwordTypes = ['old', 'new', 'confirm'] as const;
  const [passwordVisibility, setPasswordVisibility] = useState({
    old: false,
    new: false,
    confirm: false
  });

  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required('Old password is required'),
    // newPassword: yup.string().required('New Password is required'),
    newPassword: yup
      .string()
      .required('New password is required')
      .test('password-different-from-old', 'New password must be different from the old password', (value, context) => {
        const oldPassword = context.parent.oldPassword || ''; // Get the value of oldPassword from the form data
        return value !== oldPassword;
      })
      .test('password-meets-requirements', 'Your password does not meet the requirements', function (value) {
        const minLength = 8;
        const requiredCharacters = {
          lowercase: /[a-z]/,
          uppercase: /[A-Z]/,
          numbers: /[0-9]/,
          specialCharacters: /[!@#$%^&*]/
        };
        const isValid =
          value?.length && // Check if value is not undefined before accessing its length property
          value.length >= minLength &&
          Object.values(requiredCharacters).filter((regex) => regex.test(value)).length >= 3;
        return isValid || this.createError({ message: 'Your password does not meet the requirements' });
      }),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Confirm password must match new password') // Check if confirmPassword matches newPassword
      .required('Confirm password is required')
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema) // Adding Yup resolver to react-hook-form
  });

  /** Password required characters */
  const requiredCharacters = {
    lowercase: /[a-z]/,
    uppercase: /[A-Z]/,
    numbers: /[0-9]/,
    specialCharacters: /[!@#$%^&*]/
  };

  /** Check password validity */
  const checkPasswordValidity = (password: string) => {
    const minLength = 8;
    const requiredCharacters = {
      lowercase: /[a-z]/,
      uppercase: /[A-Z]/,
      numbers: /[0-9]/,
      specialCharacters: /[!@#$%^&*]/
    };

    const isValid =
      password.length >= minLength &&
      Object.values(requiredCharacters).filter((regex) => regex.test(password)).length >= 3;

    return isValid;
  };

  /** Submit */
  const onSubmit = async (data: FormData) => {
    try {
      setSaveLoader(true);

      const newData = {
        oldPassword: encrypt(data.oldPassword, process.env.REACT_APP_SECRET_KEY || ''),
        newPassword: encrypt(data.newPassword, process.env.REACT_APP_SECRET_KEY || ''),
        confirmNewPassword: encrypt(data.confirmPassword, process.env.REACT_APP_SECRET_KEY || '')
      };

      const response = await API.resetPassword({
        orgId,
        data: newData
      });

      if (response.status === 200) {
        setSaveLoader(false);
        toast.success('Password reset successfully');
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Something went wrong, please try again`));
    } finally {
      setSaveLoader(false);
    }
  };

  /** Handle toggle password visibility */
  const handleTogglePasswordVisibility = (passwordType: (typeof passwordTypes)[number]) => {
    setPasswordVisibility((prevVisibility) => ({
      ...prevVisibility,
      [passwordType]: !prevVisibility[passwordType]
    }));
  };

  /** Array of password strength levels */
  const strengthLevelsForPassword = {
    'Very Weak': { password: 'Very weak', color: '#EFF0F4' }, // '#DC3545'
    Weak: { password: 'Weak', color: '#E99940' },
    Strong: { password: 'Strong', color: '#0071A9' },
    'Very Strong': { password: 'Very strong', color: '#28A745' }
  };

  const calculatedStrength = 'Very Weak';
  const calculatedStrengthObject = strengthLevelsForPassword[calculatedStrength];
  const calculatedColor = calculatedStrengthObject.color;
  const calculatedPassword = calculatedStrengthObject.password;

  /** Border design */
  const indicatorBoxes = Object.keys(strengthLevelsForPassword).map((level, index) => (
    <Box
      key={index}
      width={`${100 / Object.keys(strengthLevelsForPassword).length}%`}
      border={`3px solid ${
        index <= Object.keys(strengthLevelsForPassword).indexOf(calculatedStrength) ? calculatedColor : '#EFF0F4'
      }`}
    />
  ));

  /** Password strength indicator using border */
  const passwordStrengthIndicator = () => {
    return (
      <Box display="flex" pt={2} alignItems="center" gap="8px">
        <Box display="flex" alignItems="center" gap={1} width="calc(100% - 110px)">
          {indicatorBoxes}
        </Box>
        <Typography width="110px" textAlign="right" color="secondary.light">
          {strengthLevelsForPassword[calculatedStrength].password} <MyTooltip title="" placement="right" />
        </Typography>
      </Box>
    );
  };

  /** Password strength checking using text */
  const getPasswordRequirement = ({
    label,
    descriptionConditions,
    checkIconCondition,
    successColor = '#28A745',
    failureColor = 'secondary'
  }: PasswordRequirementProps) => {
    return (
      <Box>
        <Typography variant="h6" mb={1}>
          {label}
        </Typography>
        {descriptionConditions.map(({ condition, description }, index) => (
          <Box
            key={index}
            mb={0.5}
            gap="8px"
            display="flex"
            alignItems="center"
            ml={index >= descriptionConditions.length - 4 ? 2 : 0}
          >
            <CheckIcon fontSize="small" color={checkIconCondition[index].condition ? 'success' : 'secondary'} />
            <Typography
              lineHeight="normal"
              variant={condition ? 'caption' : 'body2'}
              color={condition ? successColor : failureColor}
            >
              {description}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  /** Password requirements design */
  const passwordContain = (value: string | undefined) => {
    return (
      <Box mt={1}>
        {/* <Typography>Your password must contain:</Typography> */}
        {getPasswordRequirement({
          label: 'Password requirements:',
          descriptionConditions: [
            { condition: !!(value?.length && value.length >= 8), description: 'At least 8 characters' },
            {
              condition: !!(
                value && Object.values(requiredCharacters).filter((regex) => regex.test(value)).length >= 3
              ),
              description: 'At least 3 of the following'
            },
            {
              condition: !!(
                (value && requiredCharacters.lowercase.test(value)) ||
                Object.values(requiredCharacters).filter((regex) => regex.test(value!)).length >= 3
              ),
              description: 'Lower case letters (a-z)'
            },
            {
              condition: !!(
                (value && requiredCharacters.uppercase.test(value)) ||
                Object.values(requiredCharacters).filter((regex) => regex.test(value!)).length >= 3
              ),
              description: 'Upper case letters (A-Z)'
            },
            {
              condition:
                !!(value && requiredCharacters.numbers.test(value)) ||
                Object.values(requiredCharacters).filter((regex) => regex.test(value!)).length >= 3,
              description: 'Numbers (0-9)'
            },
            {
              condition:
                !!(value && requiredCharacters.specialCharacters.test(value)) ||
                Object.values(requiredCharacters).filter((regex) => regex.test(value!)).length >= 3,
              description: 'Special characters (e.g. !@#$%^&*)'
            }
          ],
          checkIconCondition: [
            { condition: !!(value?.length && value.length >= 8), description: 'At least 8 characters' },
            {
              condition: !!(
                value && Object.values(requiredCharacters).filter((regex) => regex.test(value)).length >= 3
              ),
              description: 'At least 3 of the following'
            },
            {
              condition: !!(value && requiredCharacters.lowercase.test(value)),
              description: 'Lower case letters (a-z)'
            },
            {
              condition: !!(value && requiredCharacters.uppercase.test(value)),
              description: 'Upper case letters (A-Z)'
            },
            { condition: !!(value && requiredCharacters.numbers.test(value)), description: 'Numbers (0-9)' },
            {
              condition: !!(value && requiredCharacters.specialCharacters.test(value)),
              description: 'Special characters (e.g. !@#$%^&*)'
            }
          ]
        })}
      </Box>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} lg={8} className="CardBox">
        <Box p={2}>
          <Typography variant="h5">Password and security</Typography>
        </Box>
        <Divider />

        <Box p={2}>
          {passwordScreen ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={2}>
                <InputLabel>Old password *</InputLabel>
                <Controller
                  name="oldPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={passwordVisibility.old ? 'text' : 'password'}
                      variant="outlined"
                      size="small"
                      placeholder="Old password"
                      fullWidth
                      autoFocus
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => handleTogglePasswordVisibility('old')} edge="end">
                              {passwordVisibility.old ? (
                                <VisibilityIcon color="secondary" />
                              ) : (
                                <VisibilityOffIcon className="secondary-light" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      error={!!errors.oldPassword}
                      helperText={errors.oldPassword?.message}
                    />
                  )}
                />
              </Box>

              {/* New Password */}
              <Box mb={2}>
                <InputLabel>New password *</InputLabel>
                <Controller
                  name="newPassword"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        type={passwordVisibility.new ? 'text' : 'password'}
                        variant="outlined"
                        size="small"
                        placeholder="New password"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => handleTogglePasswordVisibility('new')} edge="end">
                                {passwordVisibility.new ? (
                                  <VisibilityIcon color="secondary" />
                                ) : (
                                  <VisibilityOffIcon className="secondary-light" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        error={!!errors.newPassword}
                        helperText={errors.newPassword?.message}
                      />
                      {/* Password requirements */}
                      {passwordContain(field.value)}
                    </>
                  )}
                />
              </Box>

              {/* Confirm new password */}
              <Box mb={2}>
                <InputLabel>Confirm new password *</InputLabel>
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={passwordVisibility.confirm ? 'text' : 'password'}
                      variant="outlined"
                      size="small"
                      placeholder="Confirm new password"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => handleTogglePasswordVisibility('confirm')} edge="end">
                              {passwordVisibility.confirm ? (
                                <VisibilityIcon color="secondary" />
                              ) : (
                                <VisibilityOffIcon className="secondary-light" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword?.message}
                    />
                  )}
                />
              </Box>

              {/* Save btn */}
              <Box display="flex" justifyContent="flex-end">
                <LoadingButton loading={saveLoader} type="submit" variant="contained" className="h-50px w-150px">
                  Save
                </LoadingButton>
              </Box>
            </form>
          ) : (
            <Box height={{ xs: '360px', lg: '400px' }} display="flex" alignItems="center" justifyContent="center">
              <Typography fontSize="26px" textAlign="center">
                Please contact your system
                <br />
                administrator to edit your password
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default PasswordAndSecurity;
