import React, { useState } from 'react';
import { Box, Button, IconButton, Popover, TableCell, TableRow, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatAvatarComponent from '../../../chat/ChatAvatarComponent';
import { IGroupInvitedUser, groupUsersActions } from '../../../../../../redux/slices/group-users/groupUsersSlice';
import ProgramDeletDialog from '../ProgramDeletDialog';
import { API } from '../../../../../../api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useAppDispatch } from '../../../../../../redux/hooks';
import InviteUserDialog from '../InviteUserDialog';
import { DeleteIcon } from '../../../../../../utils/Icons';

interface Props {
  data: IGroupInvitedUser;
  showGoalProgress?: boolean;
  showCompany?: boolean;
}

const ProgramInvitedUsers = ({ data, showGoalProgress, showCompany }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const [openDelete, setDelete] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const id = open ? 'simple-popover' : undefined;
  const orgId = useParams().orgId || '';

  const [openDialog, setOpenDialog] = useState(false);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRevokeInvitation = async (invitedId: string) => {
    setDeleteLoader(true);
    try {
      const response = await API.revokeGroupInvitation({
        orgId,
        invitedId: data.invitationId
      });
      if (response.status === 200) {
        dispatch(
          groupUsersActions.removeInvitedUser({
            invitationId: data.invitationId
          })
        );
        toast.success(_.get(response, 'data.message', 'Invitation revoked successfully'));
        setDeleteLoader(false);
      }
    } catch (e) {
      toast.error("Invitation revoke failed");
      setDeleteLoader(false);
    }
  };
  return (
    <>
      <TableRow key={data.userId}>
        <TableCell colSpan={10}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ChatAvatarComponent
              image=""
              type="noStatus"
              firstLetter={data.firstName.charAt(0)}
              width="40px"
              height="40px"
            />
            <Box sx={{ marginLeft: '10px' }}>
              <Typography variant="h5">{data.firstName + ' ' + data.lastName}</Typography>
              {/* <Typography>Marketing</Typography> */}
            </Box>
          </Box>
        </TableCell>

        <TableCell colSpan={3} />
        {showGoalProgress && <TableCell />}
        {showCompany && <TableCell colSpan={5} />}

        <TableCell colSpan={3}>
          <Button
            color="info"
            size="medium"
            variant="outlined"
            sx={{
              padding: '8px 16px',
              fontWeight: '400'
            }}
            onClick={() => setOpenDialog(true)}
          >
            Resend Invite
          </Button>
        </TableCell>

        <TableCell colSpan={3}>
          <IconButton onClick={handleOpen}>
            <MoreVertIcon color="primary" />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right'
            }}
          >
            <Box
              className="PopoverListStyle"
              onClick={() => {
                setDelete(true);
              }}
            >
              <DeleteIcon />
              <Typography color="primary">Revoke Invitation</Typography>
            </Box>
          </Popover>
        </TableCell>
      </TableRow>
      <ProgramDeletDialog
        openDelete={openDelete}
        setDelete={setDelete}
        deleteLoader={deleteLoader}
        text="Are you sure you want to revoke the invitation for this member?"
        handleDelete={handleRevokeInvitation}
        btnText="Yes, Revoke"
      />
      <InviteUserDialog
        handleClose={handleClose}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        data={data}
        type="invite"
      />
    </>
  );
};

export default ProgramInvitedUsers;
