import { useQuery } from '@tanstack/react-query';
import { API } from '../api';
import { useEffect } from 'react';
import { useAppDispatch } from '../redux/hooks';
import { grpDetailsActions } from '../redux/slices/group-details/groupDetails';

const useGroupDetails = (grpId: string, location: string) => {
  const dispatch = useAppDispatch();
  const {
    data: grpData,
    error: grpError,
    isLoading: grpLoading,
    isError,
    refetch
  } = useQuery({
    queryKey: ['groupDetails', grpId],
    queryFn: () => {
      const newGrpId = grpId === 'admin' ? '' : grpId;
      return API.getGroupDetails(newGrpId, location, false);
    },
    enabled: false
  });

  useEffect(() => {
    if (grpData) {
      dispatch(grpDetailsActions.updateGroupData(grpData.data));
    }
  }, [grpData]);

  useEffect(() => {
    dispatch(grpDetailsActions.updateLoading(grpLoading));
  }, [grpLoading]);

  useEffect(() => {
    dispatch(grpDetailsActions.updateError({ error: isError, errorText: grpError?.message }));
  }, [grpError]);
  return { refetch };
};

export default useGroupDetails;
