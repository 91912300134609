import { useQuery } from '@tanstack/react-query';
import { API } from '../api';
import { useEffect } from 'react';
import { useAppDispatch } from '../redux/hooks';
import { groupActions } from '../redux/slices/getAllGroups/getAllGroupsSlice';

const useGroupList = ({ orgId, grpId, location }: { orgId: string; location: string; grpId: string }) => {
  const dispatch = useAppDispatch();
  const {
    data: grpListData,
    error: grpListError,
    isLoading: grpListLoading,
    refetch,
    isError
  } = useQuery({
    queryKey: ['groupList', grpId],
    queryFn: () => {
      const newGrpId = grpId === 'admin' ? '' : grpId;
      return API.getAllGroups({ orgId, grpId: newGrpId, loc: location, useCache: false });
    },
    enabled: false
  });

  useEffect(() => {
    if (grpListData) {
      dispatch(groupActions.updateList(grpListData.data));
    }
  }, [grpListData]);

  useEffect(() => {
    dispatch(groupActions.updateLoading(grpListLoading));
  }, [grpListLoading]);

  useEffect(() => {
    dispatch(groupActions.updateError({ error: isError, errorText: grpListError?.message }));
  }, [grpListError]);
  return { refetch };
};

export default useGroupList;
