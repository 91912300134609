import React, { useEffect, useState } from 'react';
import {
  Box,
  // Link,
  Grid,
  Divider,
  Typography
} from '@mui/material';
import { Line } from 'react-chartjs-2';
import { API } from '../../../../../api';
import { useParams } from 'react-router-dom';
import MyTooltip from '../../../components/MyTooltip';
import { formatNumber, formatNumberWithCommas, formattedSuffixedDate, getAverage } from '../../../components/common';

interface DataPoint {
  date: string;
  value: number;
}

const EngagementCard = () => {
  const opctionColor = {
    connections: 'rgb(20 201 201 / 20%)',
    calls: 'rgb(22 93 255 / 20%)',
    messages: 'rgb(247 186 30 / 20%)',
    articles: 'rgb(114 46 209 / 20%) '
  };
  let grpId = useParams().id || '';
  if (grpId === 'admin') {
    grpId = '';
  }
  const [callChartArray, setCallChartArray] = useState<any>([]);
  const [messageChartArray, setMessageChartArray] = useState<any>([]);
  const [connectionChartArray, setConnectionChartArray] = useState<any>([]);
  const [articleChartArray, setArticleChartArray] = useState<any>([]);
  const [insightsMetricCountValues, setInsightsMetricCountValues] = useState<any>([]);
  interface DataObject {
    date: string;
    value: number;
  }

  const formattedData: any = (data: DataObject[]) => {
    const monthlyAccumulated: Record<string, number> = data.reduce((acc: any, obj) => {
      // Extract year and month from date
      const [year, month] = obj.date.split('-').slice(0, 2);
      const key = `${year}-${month}`;

      // If the key doesn't exist in acc, initialize it with value 0
      if (!acc[key]) {
        acc[key] = 0;
      }

      // Accumulate the value for the month
      acc[key] += obj.value;

      return acc;
    }, {});

    // Convert accumulated object back to array of objects
    const accumulatedData: { date: string; value: number }[] = Object.keys(monthlyAccumulated).map((key) => ({
      date: key,
      value: monthlyAccumulated[key]
    }));
    return accumulatedData;
  };
  // Initialize an object to accumulate values by month

  const [callChartData, setCallData] = useState<DataPoint[]>([]);
  const [messageChartData, setMessageData] = useState<DataPoint[]>([]);
  const [connectionChartData, setConnectionData] = useState<DataPoint[]>([]);
  const [articleChartData, setArticleData] = useState<DataPoint[]>([]);
  const [finalDates, setFinalDates] = useState<string[]>([]);

  const callsDates = callChartArray?.map((entry: any) => entry.date) || [];
  const messagesDates = messageChartArray?.map((entry: any) => entry.date) || [];
  const connectionsDates = connectionChartArray?.map((entry: any) => entry.date) || [];
  const articlesDates = articleChartArray?.map((entry: any) => entry.date) || [];
  const allDates = [...callsDates, ...messagesDates, ...connectionsDates, ...articlesDates];

  const valueMappingWithDate = (chartArray: any, uniqueSortedDates: any) => {
    if (chartArray && chartArray.length > 0) {
      let accumulatedValue = 0;
      const mappedValues = uniqueSortedDates.map((date: any) => {
        const entry = chartArray.find((call: any) => call.date === date);
        if (entry) {
          accumulatedValue += entry.value;
          return { date: entry.date, value: accumulatedValue };
        } else {
          return { date, value: accumulatedValue };
        }
      });
      return mappedValues;
    } else {
      return [];
    }
  };

  useEffect(() => {
    const uniqueSortedDates = [...new Set(allDates)].sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    setFinalDates(uniqueSortedDates); // sorting every dates
  }, [callChartArray, messageChartArray]);

  useEffect(() => {
    const mappedCallsArray = valueMappingWithDate(callChartArray, finalDates);
    setCallData(mappedCallsArray);
  }, [finalDates, callChartArray]);

  useEffect(() => {
    const mappedMessagesArray = valueMappingWithDate(messageChartArray, finalDates);
    setMessageData(mappedMessagesArray);
  }, [finalDates, messageChartArray]);

  useEffect(() => {
    const mappedConnectionsArray = valueMappingWithDate(connectionChartArray, finalDates);
    setConnectionData(mappedConnectionsArray);
  }, [finalDates, connectionChartArray]);

  useEffect(() => {
    const mappedArticlesArray = valueMappingWithDate(articleChartArray, finalDates);
    setArticleData(mappedArticlesArray);
  }, [finalDates, articleChartArray]);

  /** For chart */
  const fetchChartInsightValues = async (grpId: string) => {
    try {
      const response = await API.fetchChartInsights(grpId); // Replace with your API call
      if (response.status === 200) {
        setCallChartArray(formattedData(response?.data?.data?.calls));
        setMessageChartArray(formattedData(response?.data?.data?.msgSent));
        setArticleChartArray(formattedData(response?.data?.data?.articleViews));
        setConnectionChartArray([]);
      } else {
        setCallChartArray([]);
        setMessageChartArray([]);
        setConnectionChartArray([]);
        setArticleChartArray([]);
      }
    } catch (e) {
    } finally {
    }
  };

  /** For chart related count */
  const fetchInsightsMetricsCountValues = async (grpId: string) => {
    try {
      const response = await API.fetchInsightsMetricsCount(grpId); // Replace with your API call
      if (response.status === 200) {
        setInsightsMetricCountValues(response?.data?.data);
      } else {
        setInsightsMetricCountValues([]);
      }
    } catch (e) {
    } finally {
    }
  };

  useEffect(() => {
    fetchChartInsightValues(grpId);
    fetchInsightsMetricsCountValues(grpId);
  }, [grpId]);

  /** datasets */
  const datasets = [
    {
      label: 'Connections',
      data: connectionChartData,
      backgroundColor: opctionColor.connections
    },
    {
      label: 'Calls',
      data: callChartData,
      backgroundColor: opctionColor.calls
    },
    {
      label: 'Messages',
      data: messageChartData,
      backgroundColor: opctionColor.messages
    },
    {
      label: 'Articles',
      data: articleChartData,
      backgroundColor: opctionColor.articles
    }
  ];

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomLegend = ({ datasets, onLegendClick, hiddenDatasets }: any) => {
    return (
      <Box display="flex" alignItems="center" gap="20px">
        {datasets.map((dataset: any) => (
          <Box
            key={dataset.label}
            display="flex"
            alignItems="center"
            gap="4px"
            style={{ cursor: 'pointer', opacity: hiddenDatasets.includes(dataset.label) ? 0.5 : 1 }}
            onClick={() => onLegendClick(dataset.label)}
          >
            <Box width="12px" height="12px" bgcolor={dataset.backgroundColor} borderRadius="4px" marginRight="8px" />
            <Typography color="primary" fontSize="10px">
              {dataset.label}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  /** toggle dataset visibility */
  const [hiddenDatasets, setHiddenDatasets] = useState<string[]>([]);

  /** toggle dataset visibility */
  const toggleDatasetVisibility = (datasetLabel: string) => {
    if (hiddenDatasets.includes(datasetLabel)) {
      setHiddenDatasets(hiddenDatasets.filter((label) => label !== datasetLabel));
    } else {
      setHiddenDatasets([...hiddenDatasets, datasetLabel]);
    }
  };

  const dateLabels = finalDates.map((date: any) => formattedSuffixedDate(date));

  /** Chart data */
  const chartData = {
    labels: dateLabels,
    datasets: datasets.map((dataset) => ({
      label: dataset.label,
      data: dataset.data.map((dataPoint) => dataPoint.value),
      backgroundColor: dataset.backgroundColor, // Adjust the alpha value as needed
      fill: true,
      pointRadius: 0,
      pointHoverRadius: 0,
      borderWidth: 0,
      hidden: hiddenDatasets.includes(dataset.label)
    }))
  };

  /** Chart options */
  const chartOptions = {
    devicePixelRatio: 2,
    maintainAspectRatio: false,
    responsive: true,

    scales: {
      x: {
        ticks: {
          display: true
        },
        grid: {
          display: false
        },
        border: {
          display: false // This hides the y-axis line
        },
      },
      y: {
        ticks: {
          display: false
        },
        grid: {
          color: '#EFF0F4',
          drawBorder: false, // Remove the border line on the y-axis
          drawOnChartArea: true, // Draw grid lines on the chart area
          drawTicks: false
        },
        border: {
          display: false // Ensure the y-axis line itself is not displayed
        },
        beginAtZero: true,
        min: 0, // Ensure the y-axis starts at 0
        max: 200
      }
    },

    plugins: {
      legend: {
        display: false,
        position: 'top',
        labels: {
          boxWidth: 11,
          boxHeight: 11,
          borderRadius: 4,
          useBorderRadius: true
        },
        align: 'end'
      },
      tooltip: {
        enabled: true
      },
      export: {
        resolution: 4
      }
    },

    onClick: (_: any, elements: any) => {
      if (elements.length > 0) {
        const datasetLabel = chartData.datasets[elements[0].datasetIndex].label;
        toggleDatasetVisibility(datasetLabel);
      }
    }
  };

  /** Metric card design */
  const generateMetricCard = (props: any) => (
    <Grid item xs={12} sm={6} lg={3} key={props.title}>
      <Box className="CardBox" height={'100%'} minHeight="146px" textAlign="center">
        <Box border={`6px solid ${props.tooltipColor}`} borderRadius="8px 8px 0 0" />
        <Box pb={2} px={2} pt={1}>
          <Box display="flex" justifyContent={'space-between'}>
            <Box display="flex">
              <Typography variant="h6">{props.title}</Typography>
              <MyTooltip gapleft={0.5} iconColor="#152536" title={props.tooltipTitle} />
            </Box>
            {/* <Link color={'#0071A9'} href={props.link}>View</Link> */}
          </Box>
          <Typography variant="h1" fontSize="44px" noWrap>
            {formatNumberWithCommas(props.metricValue)}
          </Typography>
          {props.supplementaryInfo && <Typography fontStyle="italic">{props.supplementaryInfo}</Typography>}
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Box className="CardBox">
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <Typography variant="h6">Engagement</Typography>
        <CustomLegend datasets={datasets} onLegendClick={toggleDatasetVisibility} hiddenDatasets={hiddenDatasets} />
      </Box>
      <Divider />
      <Box p={2}>
        <Box width="100%" height="165px" mb={2}>
          {/* <Typography py={4} variant="h1" textAlign="center">Coming soon</Typography> */}
          <Line data={chartData} options={chartOptions as any} height="180px" />
        </Box>
        <Box>
          <Grid container spacing={2}>
            {[
              generateMetricCard({
                title: 'Connections',
                metricValue: insightsMetricCountValues?.connections?.totalConnections ?? '---',
                tooltipTitle: 'The total number of relationships created in this group',
                tooltipColor: opctionColor.connections,
                link: '#'
              }),
              generateMetricCard({
                title: 'Calls',
                metricValue: insightsMetricCountValues?.calls?.totalCalls ?? 0,
                tooltipTitle: 'The total number of calls completed by members in this group',
                tooltipColor: opctionColor.calls,
                supplementaryInfo: `${
                  getAverage(
                    insightsMetricCountValues?.calls?.totalTime,
                    insightsMetricCountValues?.calls?.totalCalls
                  ) ?? 0
                } mins average duration`,
                link: '#'
              }),
              generateMetricCard({
                title: 'Messages',
                metricValue: insightsMetricCountValues?.messages?.totalMessages ?? 0,
                tooltipTitle: 'The total number of messages shared by members in this group',
                tooltipColor: opctionColor.messages,
                supplementaryInfo: `${
                  getAverage(
                    insightsMetricCountValues?.messages?.totalMessages,
                    insightsMetricCountValues?.messages?.totalMembersInGrp
                  ) ?? 0
                } messages per member`,
                link: '#'
              }),
              generateMetricCard({
                title: 'Article views',
                metricValue: insightsMetricCountValues?.articles?.articlesViewed ?? 0,
                tooltipTitle: 'The total number of article views across all program members',
                tooltipColor: opctionColor.articles,
                supplementaryInfo: `${formatNumber(insightsMetricCountValues?.articles?.articlesReadTime ?? 0)} mins spent learning`,
                link: '#'
              })
            ]}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default EngagementCard;
