/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Link,
  Grid,
  Button,
  Dialog,
  Divider,
  Popover,
  Typography,
  DialogContent,
  DialogActions
} from '@mui/material';
import _ from 'lodash';
import {
  dummyAvatar,
  dummyMyMentorsList,
  formatNumberWithCommas,
  GroupBasedRoleFormatter,
  getGoalProgressShowOrNot
} from '../../components/common';
import AvatarRow from './Avatars';
import { API } from '../../../../api';
import { LoadingButton } from '@mui/lab';
import MyMentorCard from './MyMentorCard';
import ProgressChart from './ProgressChart';
import { useAuth0 } from '@auth0/auth0-react';
import UserPeersPopup from './UserPeersPopup';
import { useNavigate } from 'react-router-dom';
import MenteeTaskPopup from './MenteeTaskPopup';
import GettingStartedCard from './GettingStartedCard';
import { useQueryClient } from '@tanstack/react-query';
import GettingStartedModal from './GettingStartedModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MySkeleton from '../../../../components/Skeleton';
import { queryKeys } from '../../../../queries/queryKeys';
import { getUserDetails } from '../../../../utils/orgName';
import useMyMentors from '../../../../queries/useMyMentors';
import { EditIcon, DeleteIcon } from '../../../../utils/Icons';
import { CardStyle } from '../../style-components/CardStyling';
import community from '../../../../assets/images/directory.svg';
import useMenteePeers from '../../../../queries/useMenteePeers';
import useGoalProgress from '../../../../queries/useGoalProgress';
// import CheckInWithMentorDialog from './CheckInWithMentorDialog';
import Trophybadge from '../../../../assets/images/Trophybadge.svg';
import useAllGroupTasks from '../../../../queries/useAllGroupTasks';
import { GetTasksLoader } from '../../../../Loader/MenteeHomeLoader';
import Trophybadge2 from '../../../../assets/images/Trophybadge2.svg';
import greycheckimg from '../../../../assets/images/greycheckimg.svg';
import greencheckimg from '../../../../assets/images/greencheckimg.svg';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import ThrophyBadgeGreen from '../../../../assets/images/ThrophyBadgeGreen.svg';
import { taskActions } from '../../../../redux/slices/goals-tasks/getTasksSlice';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import { fetchGroupDetails } from '../../../../redux/slices/group-details/groupDetails';
import { menteeTaskSlicAction } from '../../../../redux/slices/user-task/userMenteeTask';
import { chatProfileActions } from '../../../../redux/slices/chat-profile/chatProfileSlice';
import { updateXpPoints } from '../../../../redux/slices/menteeXpPoints/menteeXpPointsSlice';
import { fetchUserWorkHistory } from '../../../../redux/slices/user-work/userWorkHistorySlice';
import { fetchUserEducation } from '../../../../redux/slices/user-education/userEducationSlice';
import { fetchCommunityMembers } from '../../../../redux/slices/acs-community-members/acsCommunityMembers';
import useProfileDetails from '../../../../hooks/useProfileDetails';
import useGroupDetails from '../../../../queries/useGroupDetails';

const Mentee = () => {
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const queryClient = useQueryClient();
  const mentorRoleText =
    selectedGroupId.id === '64fa3b0ef3cbc20ba20de466' ? 'Matches' : GroupBasedRoleFormatter('mentor', 'Mentors');
  const { user } = useAuth0();
  const { location, orgId } = getUserDetails();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classess = CardStyle();
  const selectedUserId = useAppSelector((state) => state.goalTasks.userId);
  const { gettingStarted } = useProfileDetails();

  const {
    refetch,
    isFetching,
    isLoading,
    data: newData,
    isStale
  } = useAllGroupTasks(selectedGroupId.id, selectedUserId);
  const goalProgress = useGoalProgress(newData?.data?.data[0]?.levels || []);
  const [selectedMember, setSelectedMember] = useState<any>(null);

  const [xpPoints, setXpPoints] = useState<any>();

  //  Getting Started Modal

  const [openGettingStartedModal, setOpenGettingStartedModal] = useState(false);
  const [selectedStep, setSelectedStep] = useState(0);
  const handleClickGettingStartedModal = (flag: boolean) => {
    setOpenGettingStartedModal(flag);
  };

  const [goalProgressValue, setGoalProgressValue] = useState(0);
  const personalThreadsArr = useAppSelector((state) => state.acsChannels.personalThreadsArr);
  const [displayName, setDisplayName] = useState('');
  const [participantUser, setParticipantUser] = useState([]);
  const [reloadFlag, setReloadFlag] = useState(false);
  const communityMembers = useAppSelector((state) => state.acsCommunityUsers.data);
  const commList = useAppSelector((state) => state.getCommunityMembers.data);
  const groupId = selectedGroupId.id || '';
  const menteeTask = useAppSelector((state) => state?.goalTasks);
  const taskLoading = isLoading;
  const [taskData, setTaskData] = useState([]);
  const { loading: loadingGoal, data: userGoals } = useAppSelector((state) => state.groupDetails) || {};
  const goalCount = Object.keys(userGoals?.goals ? userGoals?.goals : {}).length;
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const groupDetails = useAppSelector((state) => state?.groupDetails);
  /** Show goal progress */
  const [hasShowGoalProgressValue, setHasShowGoalProgressValue] = useState<any>(true);
  const showGoalProgress = getGoalProgressShowOrNot(hasShowGoalProgressValue);
  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
  const [isPeersPopupOpen, setPeersPopupOpen] = React.useState(false);

  const {
    data: mentorsData,
    isLoading: mentorLoading,
    refetch: mentorsRefetch
  } = useMyMentors({
    grpId: groupId,
    orgId,
    location: getUserDetails().location
  });
  const {
    data: peersData,
    isLoading: peersLoading,
    refetch: peersRefetch
  } = useMenteePeers({
    grpId: groupId,
    orgId,
    location: getUserDetails().location
  });
  const loader = mentorLoading || peersLoading;

  const handlePeersPopup = (open: boolean) => {
    setPeersPopupOpen(open);
  };
  const handlePeersOpen = (open: boolean) => {
    setPeersPopupOpen(open);
  };

  const doReload = () => {
    queryClient.invalidateQueries({
      queryKey: [queryKeys.GET_MENTEE_PEERS, groupId]
    });
    queryClient.invalidateQueries({
      queryKey: [queryKeys.GET_MY_MENTORS, groupId]
    });
  };

  const [selectedLevelId, setSelectedLevelId] = useState<any[]>([]);
  const [selectedLevelName, setSelectedLevelName] = useState('');
  const [rightTopPop, setRightTopPop] = useState(null);
  const rightTopDotsClick = (level: any) => (event: any) => {
    setEditModalOpen(true);
    setSelectedLevelId(level._id);
    setSelectedLevelName(level.levelName);
    setRightTopPop(event.currentTarget);
  };
  const handleClose = () => {
    setRightTopPop(null);
    setEditModalOpen(false);
  };
  const openMoreVerts = Boolean(rightTopPop);
  const rightTopPopover = openMoreVerts ? 'simple-popover' : undefined;
  const [openDelete, setDelete] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const avatars = peersData?.data.data?.map((each: any) => ({ avatar: each?.headshot, firstName: each?.displayName }));
  const groupList = useAppSelector((state) => state.groupRoleList);
  const moveToUsercommunityProfile = (userId: string) => {
    const mem = communityMembers.find((item: { userId: string }) => item.userId === userId);
    const modifiedMem = { ...mem, id: _.get(mem, 'id.coId', '') };
    if (mem) {
      navigate('/app/communitymember', {
        state: { mem: modifiedMem, allCommunityMembersList: commList }
      });
    }
  };

  const getMenteesInsights = async () => {
    const menteeInsightsData = await API.getMenteeInsights(orgId, location, groupId);
    if (menteeInsightsData) {
      setXpPoints(menteeInsightsData?.data?.xpPoints);
      dispatch(updateXpPoints(menteeInsightsData?.data?.xpPoints));
      setGoalProgressValue(menteeInsightsData?.data?.goalProgress);
      setHasShowGoalProgressValue(menteeInsightsData?.data?.showGoalProgress);
    }
  };

  const getChannels = () => {
    dispatch(
      fetchCommunityMembers({
        orgId: getUserDetails().orgId,
        location: getUserDetails().location
      })
    );
  };

  useEffect(() => {
    if (groupDetails.data) {
      const allowEditing = groupDetails?.data?.includeTask && groupDetails?.data?.allowEditing ? true : false;
      if (!gettingStarted.editTasks && allowEditing) {
        setOpenGettingStartedModal(true);
      }
      if (!gettingStarted.nonEditTasks && !allowEditing) {
        setOpenGettingStartedModal(true);
      }
    }
  }, [groupId, gettingStarted, groupDetails.data]);

  useEffect(() => {
    getChannels();
  }, []);

  useEffect(() => {
    if (menteeTask) {
      setTaskData(newData?.data?.data);
    } else {
      setTaskData([]);
    }
  }, [newData]);

  const { refetch: fetchGroupDetails } = useGroupDetails(groupId, getUserDetails().location);

  useEffect(() => {
    try {
      if (groupId) {
        fetchGroupDetails();
      }
    } catch (error) {}
  }, [groupId]);

  const saveAnswer = useAppSelector((state) => state.saveAnswer.flag);
  useEffect(() => {
    if (isStale && !saveAnswer) {
      refetch();
    }
  }, [selectedGroupId.id, selectedUserId, saveAnswer, isStale]);

  useEffect(() => {
    if (selectedMember?.userId) {
      dispatch(fetchUserWorkHistory(selectedMember?.userId));
      dispatch(fetchUserEducation(selectedMember?.userId));
    }
    if (groupId) {
      if (selectedGroupId.role === 'mentee') {
        dispatch(taskActions.setUserId({ userId: '' }));
      }
    }
  }, []);

  const getName = (each: any) => {
    const chatProfileFirstName: any = _.get(each, 'firstName', '');
    const chatProfileLastName: any = _.get(each, 'lastName', '');
    return chatProfileFirstName
      ?.charAt(0)
      ?.toUpperCase()
      ?.concat(chatProfileFirstName?.slice(1)?.toLowerCase())
      ?.concat(
        ' ',
        chatProfileLastName?.charAt(0)?.toUpperCase()?.concat(chatProfileLastName?.slice(1)?.toLowerCase())
      );
  };

  const navigateMember = (member: any) => {
    setSelectedMember(member);
  };

  /** Calendar modal */
  const handleCalendarOpen = (member: any) => {
    setDisplayName(member.displayName);
    setParticipantUser(member);
    // setPeersOpen(false);
  };

  const navigateToChat = () => {
    let communicationId = _.get(selectedMember, 'communicationUserId', '');
    if (!communicationId) {
      communicationId = _.get(selectedMember, 'communicationId', '');
    }
    const channel = personalThreadsArr?.find((each) => each?.displayNames?.includes(communicationId));
    dispatch(chatProfileActions.atnSetChatProfileState(null));
    if (communicationId && channel) {
      navigate(`/app/chat/?threadid=${_.get(channel, 'id')}&type=Chats&name=${_.get(channel, 'topic')}`);
    } else if (communicationId && !channel) {
      navigate(`/app/chat/?commId=${communicationId}`);
    }
  };

  useEffect(() => {
    if (selectedMember) {
      navigateToChat();
    }
  }, [selectedMember]);

  useEffect(() => {});

  useEffect(() => {
    if (typeof groupId === 'string' && groupId !== '') {
      queryClient.fetchQuery({
        queryKey: [queryKeys.GET_MENTEE_PEERS, groupId]
      });
      getMenteesInsights();
      queryClient.fetchQuery({
        queryKey: [queryKeys.GET_MY_MENTORS, groupId]
      });
    }
  }, [groupId, reloadFlag]);

  if (groupList.error) {
    return <Box>{groupList.errorText}</Box>;
  }

  if (groupList.data?.length === 0) {
    return <Box>No Groups found</Box>;
  }

  const handleAddLevels = () => {
    dispatch(menteeTaskSlicAction.openDialog({ isOpen: true, mode: 'add' }));
  };

  const handleAddTasks = (level: any) => {
    dispatch(menteeTaskSlicAction.setSelectedLevel({ selectedLevel: level.levelName, selectedLevelId: level._id }));
    dispatch(menteeTaskSlicAction.openDialog({ isOpen: true, mode: 'addTask' }));
  };

  const handleEditLevel = () => {
    dispatch(
      menteeTaskSlicAction.setSelectedLevel({
        selectedLevel: selectedLevelName,
        selectedLevelId: selectedLevelId
      })
    );
    dispatch(menteeTaskSlicAction.openDialog({ isOpen: true, mode: 'edit' }));
    // handleClose();
  };

  const handleDeleteLevel = () => {
    dispatch(
      menteeTaskSlicAction.setSelectedLevel({
        selectedLevelId: selectedLevelId
      })
    );
    dispatch(menteeTaskSlicAction.openDialog({ isOpen: true, mode: 'delete' }));
    // handleClose();
  };

  const getTasks = (each: any) => {
    return (
      <>
        {each?.map((level: any, index: any) => {
          return (
            <Box className="MylevelsCard">
              <Box
                borderRadius="8px 8px 0 0"
                borderTop={
                  level.hexColor === 'gold' || level.hexColor === 'green' ? '10px solid #0071A9' : '10px solid #ABB5BE'
                }
              />
              <Box>
                <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Box width="calc(100% - 45px)">
                    <Typography color="secondary.light" variant="h6">
                      Level {index + 1}
                    </Typography>
                    <Typography variant="h5" noWrap>
                      {level.levelName}
                    </Typography>
                  </Box>
                  <img
                    src={
                      level.hexColor === 'gold'
                        ? Trophybadge
                        : level.hexColor === 'green'
                        ? ThrophyBadgeGreen
                        : Trophybadge2
                    }
                    alt="Trophybadge"
                    width="35px"
                    height="35px"
                  />
                  {!level?.level && (
                    <Box>
                      <MoreVertIcon
                        color="primary"
                        className="cursor-pointer"
                        onClick={(event) => rightTopDotsClick(level)(event)}
                      />
                    </Box>
                  )}
                </Box>

                <Divider />
                <Box p={2}>
                  {level.tasks.map((task: any, index: number) => {
                    return (
                      <React.Fragment key={task._id}>
                        <Box
                          gap="15px"
                          display="flex"
                          alignItems="center"
                          onClick={() => navigate(`/app/home/mentee/level/${level._id}/task/${task._id}`)}
                        >
                          <img
                            src={task.taskColor === 'green' ? greencheckimg : greycheckimg}
                            alt="greencheckimg"
                            width="20px"
                            height="20px"
                          />
                          <Typography
                            noWrap
                            className="clickable"
                            color={task.taskColor === 'green' ? 'secondary' : '#0071A9'}
                          >
                            {task.taskName}
                          </Typography>
                        </Box>

                        {index !== level.tasks.length - 1 && (
                          <Box borderLeft="1px dotted #D9D9D9" ml="9.5px" py="10px" />
                        )}
                      </React.Fragment>
                    );
                  })}
                </Box>
              </Box>
              {!level?.level && (
                <Box pb={2} pl={2}>
                  <Link onClick={() => handleAddTasks(level)} color="#0071A9" variant="caption">
                    + Add Task
                  </Link>
                </Box>
              )}
            </Box>
          );
        })}

        {/* edit or delete modal */}
        <Popover
          id={rightTopPopover}
          open={editModalOpen}
          anchorEl={rightTopPop}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Box className="PopoverListStyle" gap="8px" onClick={() => handleEditLevel()}>
            <EditIcon />
            <Typography color="primary">Edit Level</Typography>
          </Box>
          <Box className="PopoverListStyle" onClick={() => handleDeleteLevel()} gap="8px">
            <DeleteIcon />
            <Typography color="primary">Delete Level</Typography>
          </Box>
        </Popover>
      </>
    );
  };

  return (
    <>
      <Box className="bodyBox">
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          {/* My Goal */}
          <Grid item xs={12} md={6} lg={4.5}>
            <Box className={classess.cardBox} minHeight={forMobile ? 'auto' : '240px'}>
              <Box p={2}>
                <Typography variant="h5">{loader ? <MySkeleton width={100} /> : 'My Goal'}</Typography>
              </Box>
              <Divider />
              <Box className="mygoalBox">
                {/* Goal progress chart */}
                {showGoalProgress && (
                  <Box sx={{ textAlign: '-webkit-center' }}>
                    {loader ? (
                      <MySkeleton variant="circular" height={160} width={160} />
                    ) : (
                      <ProgressChart percentage={goalProgress} />
                    )}
                  </Box>
                )}
                <Box>
                  <Box className="d-desktop">
                    <Box display="flex" alignItems="center" gap="8px" width="100%">
                      {loader ? (
                        <MySkeleton variant="circular" height={50} width={50} />
                      ) : (
                        <Box
                          width="50px"
                          height="50px"
                          borderRadius="50%"
                          border="2px solid #EFF0F4"
                          overflow="hidden"
                          padding="8px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <img src={community} alt="logo" width="28px" height="28px" />
                        </Box>
                      )}
                      <Typography variant="h1" fontSize="40px" textAlign="center">
                        {loader ? (
                          <MySkeleton variant="rectangular" height={50} width={50} />
                        ) : (
                          formatNumberWithCommas(peersData?.data.data?.length)
                        )}
                      </Typography>
                      <Typography variant="h6" color="secondary">
                        {loader ? <MySkeleton width={50} /> : 'Peers'}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" gap="8px" my={1} flexWrap={'wrap'}>
                    <Box>
                      <AvatarRow avatars={loader ? dummyAvatar : avatars} loading={loader} />
                    </Box>
                    {peersData?.data.data?.length > 0 && (
                      <Typography
                        color="#0071A9"
                        fontSize="14px"
                        fontWeight={400}
                        className="cursor-pointer"
                        onClick={() => handlePeersOpen(!loader)}
                      >
                        {loader ? <MySkeleton width={150} /> : 'View all peers'}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          {/* If this User have mentor */}
          <Grid item xs={12} md={6} lg={4.5}>
            <Box className={classess.cardBox} minHeight={forMobile ? 'auto' : '240px'}>
              <Box p={2}>
                <Typography variant="h5">{loader ? <MySkeleton width={150} /> : `My ${mentorRoleText}`}</Typography>
              </Box>
              <Divider />
              {/* <MyMentorCard myMentorList={myMentorsList} userProfileRow={handleUserProfile} /> */}
              <MyMentorCard
                myMentorList={loader ? dummyMyMentorsList : mentorsData?.data.data}
                loading={loader}
                myRequestedMentorList={loader ? dummyMyMentorsList : mentorsData?.data.requestedMentors}
                doReload={doReload}
              />
            </Box>
          </Grid>
          {/* Getting started card */}
          <Grid item xs={12} md={12} lg={3}>
            <GettingStartedCard
              loading={loader}
              handleClickGettingStartedModal={handleClickGettingStartedModal}
              setSelectedStep={setSelectedStep}
            />
          </Grid>
        </Grid>
        <Box className="CardBox" mt={{ xs: 1, sm: 2 }}>
          <Box p={2} gap="8px" display="flex" alignItems="center">
            <Typography variant="h5">My personal development plan</Typography>
            {goalCount > 0 && (
              <Link onClick={handleAddLevels} color="#0071A9" variant="caption">
                {groupDetails?.data?.includeTask ? (groupDetails?.data?.allowEditing ? '+ Add Level' : null) : null}
              </Link>
            )}
            <MenteeTaskPopup setReloadFlag={setReloadFlag} />
          </Box>
          <Divider />
          {taskLoading ? (
            <GetTasksLoader />
          ) : taskData?.length ? (
            <>
              {taskData?.map((eachLevel: any) => {
                return (
                  <Box
                    px={2}
                    key={eachLevel}
                    pb={forMobile ? (eachLevel?.levels?.length > 1 ? 2 : 0) : eachLevel?.levels?.length > 3 ? 2 : 0}
                  >
                    {eachLevel?.levels.length > 0 ? (
                      <Box className="MylevelsCardMain">{getTasks(eachLevel.levels)}</Box>
                    ) : (
                      <Typography textAlign="center">No task assigned</Typography>
                    )}
                  </Box>
                );
              })}
            </>
          ) : (
            <Box p={2}>
              <Typography textAlign="center">No task assigned</Typography>
            </Box>
          )}
        </Box>
      </Box>

      {/* Group of Peers Modal */}
      {isPeersPopupOpen && (
        <UserPeersPopup
          open={isPeersPopupOpen}
          onClose={() => handlePeersPopup(false)}
          peersList={peersData?.data.data}
          onProfileClick={(mid) => moveToUsercommunityProfile(mid)}
          onChatClick={(each) => navigateMember(each)}
          onCalendarClick={(each) => handleCalendarOpen(each)}
          communityMembers={communityMembers} // Pass the communityMembers prop here
          navigate={navigate} // Pass the navigate prop here
        />
      )}

      {/* Remove Confirmation Popup */}
      <Dialog open={openDelete} onClose={() => setDelete(false)}>
        {/* Dialog Header */}
        {/* <CommonModalHeader
          title={`Confirmation`}
          handleCloseModal={() => setDelete(false)}
        /> */}
        <DialogContent>
          <Typography variant="h5" textAlign="center">
            Are you sure you want to delete this <br /> level?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button fullWidth color="secondary" variant="outlined" onClick={() => setDelete(false)}>
            No
          </Button>

          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            loading={deleteLoader}
            // onClick={handleRemoveClose}
            onClick={() => setDelete(false)}
          >
            Yes, Remove
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Check in with Mentor Modal */}
      {/* <CheckInWithMentorDialog
        checkInWithMentor={checkInWithMentor}
        handleCheckInWithMentor={handleCheckInWithMentor}
      /> */}

      <GettingStartedModal
        open={openGettingStartedModal}
        handleClickGettingStartedModal={handleClickGettingStartedModal}
        allowEditing={groupDetails?.data?.includeTask && groupDetails?.data?.allowEditing ? true : false}
        selectedStep={selectedStep}
      />
    </>
  );
};

export default Mentee;
