import React, { useState } from 'react';
import { Box, Typography, Divider, InputLabel, TextField } from '@mui/material';
import { API } from '../../../../api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';

// interface ConcernFormProps {
//   onSubmit: () => void; // Add a proper type for the onSubmit function
// }

const HelpConcern = () => {
  const [concernText, setConcernText] = useState('');
  const [loader, setLoader] = useState(false);
  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
  const handleTextareaChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    // Update the state with the text input
    setConcernText(event.target.value);
  };

  const handleConcernSubmit = async () => {
    if (!concernText) {
      toast.error('Please add anything relevant to the concern.');
      return;
    }
    try {
      setLoader(true);
      const data = { reasonOfConcern: concernText };
      const responseFYE = await API.saveConcernWithEmail(data);
      if (responseFYE.status === 200) {
        setLoader(false);
        toast.success('Concern raised successfully');
        setConcernText('');
      }
    } catch {
      setLoader(false);
      toast.error('Something went wrong, please try again');
      setConcernText('');
    }
  };

  return (
    <Box>
      <Box p={2}>
        <Typography variant="h5">Raise a concern</Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <InputLabel>
          To raise a concern with your program administrator, please add a comment below and click Submit.
        </InputLabel>
        <TextField
          multiline
          value={concernText}
          minRows={forMobile ? 5 : 15}
          placeholder="Hi, I’d like to raise a concern with respect to"
          onChange={handleTextareaChange}
          sx={{
            '& .MuiInputBase-input': {
              fontWeight: '400 !important',
              color: '#152536 !important'
            }
          }}
        />
        <Box
          mt={2}
          display={'flex'}
          flexDirection={forMobile ? 'column' : 'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={forMobile ? '20px' : '8px'}
        >
          <Typography variant="body2" color={'secondary'}>
            * Please add anything related to your concern to your message to help us resolve the issue
          </Typography>
          <LoadingButton
            variant="contained"
            loading={loader}
            sx={{
              height: '50px',
              width: forMobile ? '100%' : '100px'
            }}
            // Handle the submit logic here
            onClick={() => {
              handleConcernSubmit();
            }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default HelpConcern;
