/* eslint-disable guard-for-in */
/* eslint-disable prefer-destructuring */
/* eslint-disable radix */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import moment from 'moment';
import _ from 'lodash';
import * as Yup from 'yup';
import {
  Box,
  Grid,
  Radio,
  Switch,
  Divider,
  TextField,
  RadioGroup,
  InputLabel,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
  FormControlLabel
} from '@mui/material';
import { useForm, Controller, FieldValues, SubmitHandler } from 'react-hook-form';
import Fields from './Fields';
import { Members } from './Members';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import {
  lockIcon,
  topicArray,
  industryArray,
  imageSizeLimit,
  communityFieldsArray,
  handleImageCompression,
  GroupBasedRoleFormatter
} from '../../../../../components/common';
import { API } from '../../../../../../../api';
import ROLES from '../../../../../../../utils/roles';
import BasicSettingsTasks from './BasicSettingsTasks';
import { yupResolver } from '@hookform/resolvers/yup';
// import AddLoginSignupLinks from './AddLoginSignupLinks';
import { useParams, useSearchParams } from 'react-router-dom';
import { UploadIcon } from '../../../../../../../utils/Icons';
import { useLoader } from '../../../../../components/useLoader';
import { getUserDetails } from '../../../../../../../utils/orgName';
import ChatAvatarComponent from '../../../../chat/ChatAvatarComponent';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import useProfileDetails from '../../../../../../../hooks/useProfileDetails';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import BasicSettingsLoader from '../../../../../../../Loader/BasicSettingsLoader';
import { groupActions } from '../../../../../../../redux/slices/getAllGroups/getAllGroupsSlice';
import { breadCrumbsActions } from '../../../../../../../redux/slices/breadcrumbs/breadcrumbsSlice';
import { ORG_TYPES } from '../../../../../../../utils/constants';
import { store } from '../../../../../../../redux/store';
import useGroupDetails from '../../../../../../../queries/useGroupDetails';
const imgSize = imageSizeLimit();
let communityFields: any[] = communityFieldsArray();

/** optional Fields Array */
const initialValues = [
  {
    label: 'Gender',
    name: 'gender',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Ethnicity',
    name: 'ethnicity',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'First generation student?',
    name: 'firstGenerationStudent',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Disability type',
    name: 'disabilityType',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'DOB',
    name: 'dob',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Country',
    name: 'country',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Video intro',
    name: 'videoIntro',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Work History',
    name: 'workHistory',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Education History',
    name: 'educationHistory',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Hobbies & Interests',
    name: 'hobbiesInterests',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  },
  {
    label: 'Company',
    name: 'company',
    mentor: false,
    mentee: false,
    admin: false,
    mandatory: false
  }
];

const BasicSettings = () => {
  const dispatch = useAppDispatch();
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const orgType = useAppSelector((state) => state.orgDetails.data?.orgType) || '';
  // Extra topics and industries for Dev Bank Org Banks

  // UI is getting hanged if we use topicArray and industryArray like below, so defined them out of the component
  const isNotDevBank = orgType === ORG_TYPES.govt ? true : false;

  let topics: any[] = topicArray(!isNotDevBank);
  let industries: any[] = industryArray(!isNotDevBank);

  const grpId = useParams().id || '';
  const orgId = useParams().orgId || '';
  const mentorRoleTextLowerCase = GroupBasedRoleFormatter('mentor', 'mentor');
  const menteeRoleTextLowerCase = GroupBasedRoleFormatter('mentee', 'mentee');
  const [fieldValues, setFieldValues] = useState(initialValues);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [searchParams] = useSearchParams();
  const [saveLoader, setSaveLoader] = useState(false);
  const [optional, setOptional] = useState<boolean>(false);
  const [imageUploaded, setImageUploaded] = useState<any>(false);
  const [tempImage, setTempImage] = useState<any>(null);
  const [matchUpdateFlag, setMatchUpdateFlag] = useState(false);
  const [basicUpdateFlag, setBasicUpdateFlag] = useState(false);
  const [industryUpdateFlag, setIndustryUpdateFlag] = useState(false);
  const [optionalFieldsUpdateFlag, setOptionalFieldsUpdateFlag] = useState(false);
  const [topicsUpdateFlag, setTopicsUpdateFlag] = useState(false);
  const [groupDisplayRoles, setGroupDisplayRoles] = useState<any>();
  const [inputFields, setInputFields] = useState<any>({
    nam: false,
    end: false,
    logo: false,
    matchPerMentee: false,
    matchPerMentor: false
  });
  const { nam, end, logo, matchPerMentee, matchPerMentor } = inputFields;
  const groupData = useAppSelector((state) => state.groupDetails);
  const role = localStorage.getItem('role') || '';
  let locValue = getUserDetails().location;
  if (role === ROLES.platAdmin) {
    locValue = searchParams.get('loc') || '';
  }

  const initialCommunityData = {
    mentor: true,
    mentee: true,
    admin: true
  };

  const [selectedTopicsValue, setSelectedTopicsValue] = useState<any>({});
  const [selectedIndustriesValue, setSelectedIndustriesValue] = useState<any>({});
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);
  const [includeValue, setIncludeValue] = React.useState(true);
  const [allowEditing, setAllowEditing] = React.useState(true);
  const [selectedCommunityValue, setSelectedCommunityValue] = useState<any>(initialCommunityData);

  const handleCurrencyChange = (value: string | null | undefined = '') => {
    setSelectedCurrency(value ?? '');
  };

  const yupFieldRequirements = Yup.object({
    name: Yup.string()
      .required('Program Name is required')
      .test('no-empty-spaces', 'Program Name cannot be all empty spaces', (value) => {
        if (value) {
          return !/^[\s]+$/.test(value);
        }
        return true;
      })
      .min(3, 'Program Name must be at least 3 characters')
      .max(75, 'Maximum 75 characters'),
    endDate: Yup.date()
      .nullable()
      .min(new Date(new Date().setDate(new Date().getDate() - 1)), 'Event date must be in the future or today')
      .transform((value, originalValue) => {
        if (originalValue === '') {
          return null;
        }
        return value;
      })
      .typeError('End date must be a valid date'),
    logo: Yup.mixed()
      .test('fileValidation', '', (value, { createError }) => {
        if (!value || !(value instanceof File)) {
          return true;
        }
        if (value.size > 5 * 1024 * 1024) {
          return createError({ message: 'File size must not exceed 5MB' });
        }
        if (!/\.(jpg|jpeg|png|svg|gif)$/i.test(value.name)) {
          return createError({
            message: 'Only jpg, png, svg, and gif files are allowed'
          });
        }
        return true;
      })
      .nullable(),
    maxMatchesPerMentee: Yup.number()
      .required('Maximum matches per ' + menteeRoleTextLowerCase + ' required')
      .min(1, 'Max matches per ' + menteeRoleTextLowerCase + ' should not be less than 1')
      .transform((value, originalValue) => {
        if (originalValue === '') {
          return null;
        }
        return value;
      })
      .typeError('Please Enter a Number'),
    maxMatchesPerMentor: Yup.number()
      .required('Maximum matches per ' + mentorRoleTextLowerCase + ' required')
      .min(1, 'Max matches per ' + mentorRoleTextLowerCase + ' should not be less than 1')
      .transform((value, originalValue) => {
        if (originalValue === '') {
          return null;
        }
        return value;
      })
      .typeError('Please Enter a Number'),

    mentee: Yup.mixed()
      .nullable()
      .test({
        message: 'This field is required',
        test: (value) => {
          const menteeValues: any = getValues('mentee');
          const menteeValuesArray = typeof menteeValues === 'string' ? [menteeValues] : menteeValues;
          return Array.isArray(menteeValuesArray) && menteeValuesArray.length > 0;
        }
      }),
    mentor: Yup.mixed()
      .nullable()
      .test({
        message: 'This field is required',
        test: (value) => {
          const mentorValues: any = getValues('mentor');
          const mentorValuesArray = typeof mentorValues === 'string' ? [mentorValues] : mentorValues;
          return Array.isArray(mentorValuesArray) && mentorValuesArray.length > 0;
        }
      }),

    topics: Yup.object().test('at-least-one-topic', 'Please select at least one topic', (value) => {
      // Check if at least one topic is true
      return Object.values(selectedTopicsValue ?? {}).some((topic) => topic === true);
    }),
    industries: Yup.object().test('at-least-one-industrie', 'Please select at least one industry', (value) => {
      // Check if at least one topic is true
      return Object.values(selectedIndustriesValue ?? {}).some((industry) => industry === true);
    })
  });

  const {
    control,
    formState: { errors },
    reset,
    getValues,
    trigger,
    handleSubmit
  } = useForm({
    resolver: yupResolver(yupFieldRequirements)
  });

  const updateMemberValue = () => {
    const changedRoles = {
      mentee: getValues('mentee'),
      admin: 'admin',
      mentor: getValues('mentor')
    };
    setGroupDisplayRoles(changedRoles);
  };

  const checkError = (fieldName: string) => Boolean(errors[fieldName]);
  const getError = (fieldName: string) => errors[fieldName]?.message;
  const { isSettingsAllowed } = useProfileDetails();
  const { refetch: fetchGroupDetails } = useGroupDetails(grpId, locValue);
  const initApi = () => {
    fetchGroupDetails().then((response: any) => {
      dispatch(
        breadCrumbsActions.updateBreadCrumbName({
          id: grpId,
          name: response.data.data.name || getValues('name')
        })
      );
      dispatch(groupActions.updateMainGroupName(response.data.data.name || getValues('name')));
    });
  };

  useEffect(() => {
    if (groupData.data) {
      const fields = groupData.data.optionalFields;
      const userOptionalFields = groupData.data.userOptionalFields;
      const fieldKeys = Object.keys(userOptionalFields || {});
      const updatedValues = initialValues.map((each) => {
        if (fieldKeys.includes(each.name)) {
          const permissions = userOptionalFields[each.name];
          const allPermissionsTrue = Object.values(permissions).every((val) => val);
          const updatedPermissions = {
            mentor: permissions.mentor === false ? true : permissions.mentor === undefined ? false : permissions.mentor,
            mentee: permissions.mentee === false ? true : permissions.mentee === undefined ? false : permissions.mentee,
            admin: permissions.admin === false ? true : permissions.admin === undefined ? false : permissions.admin
          };
          return {
            ...each,
            mentor: updatedPermissions.mentor,
            mentee: updatedPermissions.mentee,
            admin: updatedPermissions.admin,
            mandatory: allPermissionsTrue // If all permissions are true, make mandatory false
          };
        } else {
          return {
            ...each,
            mentor: false,
            mentee: false,
            admin: false,
            mandatory: false
          };
        }
      });
      setFieldValues(updatedValues);
    }
  }, [groupData.data, initialValues, setFieldValues]);

  const updateBasicSettings = async () => {
    if (basicUpdateFlag) {
      try {
        let data: any = {
          endDate: getValues('endDate'),
          name: getValues('name'),
          logo: tempImage ? tempImage : getValues('logo'),
          includeTask: includeValue,
          allowEditing: allowEditing
        };

        // if (!isSettingsAllowed) {
        data = { ...data, mentee: getValues('mentee'), mentor: getValues('mentor') };
        // }

        data.endDate = data.endDate ? moment(data.endDate).format('yyyy-MM-DD') : '';
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        return await API.updateGroupInfo({
          orgId,
          grpId,
          formData
        });
      } catch (e: any) {
        toast.error(_.get(e, 'response.data.message', `Something went wrong, please try again`));
      }
    }
  };

  const updateOptionalFields = async () => {
    if (optionalFieldsUpdateFlag) {
      let payload: { [key: string]: { [key: string]: boolean } } = {};
      fieldValues.forEach((each) => {
        payload[each.name] = {};
        if (each.mandatory) {
          if (each.mentee) payload[each.name].mentee = true;
          if (each.admin) payload[each.name].admin = true;
          if (each.mentor) payload[each.name].mentor = true;
        } else {
          if (each.mentee) payload[each.name].mentee = false;
          if (each.admin) payload[each.name].admin = false;
          if (each.mentor) payload[each.name].mentor = false;
        }
      });

      try {
        return await API.updateOptionalFields({
          grpId,
          data: payload
        });
      } catch (e) {
        toast.error(_.get(e, 'response.data.message', `Something went wrong, please try again`));
      }
    }
  };

  const updateMatchSettings = async () => {
    if (matchUpdateFlag) {
      try {
        const { maxMatchesPerMentee = 1, maxMatchesPerMentor = 1 } = getValues();
        return await API.updateAllMatches({
          grpId,
          orgId,
          data: { maxMatchesPerMentee, maxMatchesPerMentor, matchRequest }
        });
      } catch (e) {
        toast.error(_.get(e, 'response.data.message', `Something went wrong, please try again`));
      }
    }
  };

  const updateTopicSettings = async () => {
    if (topicsUpdateFlag) {
      try {
        // const { maxMatchesPerMentee = } = getValues();
        return await API.updateTopics({
          grpId,
          orgId,
          data: selectedTopicsValue
        });
      } catch (e) {
        toast.error(_.get(e, 'response.data.message', `Something went wrong, please try again`));
      }
    }
  };

  const updateIndustrySettings = async () => {
    if (industryUpdateFlag) {
      try {
        return await API.updateIndustry({
          grpId,
          orgId,
          data: selectedIndustriesValue
        });
      } catch (e) {
        toast.error(_.get(e, 'response.data.message', `Something went wrong, please try again`));
      }
    }
  };

  const updatebasicSettingsCommunity = async () => {
    try {
      // const { maxMatchesPerMentee = } = getValues();
      return await API.basicSettingsCommunity({
        grpId,
        orgId,
        data: selectedCommunityValue
      });
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Something went wrong, please try again`));
    }
  };

  const updateSettings = async () => {
    try {
      const response = await API.updateGroupSettings({ groupId: grpId, location: locValue });
      if (response.status === 200) {
        initApi();
        setImageUploaded(false);
        setTempImage(null);
        setSaveLoader(false);
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Settings failed to update`));
      setSaveLoader(false);
    }
  };

  const upadteAllApis = async () => {
    try {
      await Promise.all([
        updateBasicSettings(),
        updateOptionalFields(),
        updateMatchSettings(),
        updateTopicSettings(),
        updateIndustrySettings()
        // updatebasicSettingsCommunity()
      ]);
    } catch (e: any) {
      toast.error('Something went wrong, please try again');
    } finally {
      console.log(groupData.data?.settingsUpdate, 'groupData.data?.settingsUpdate');
      if (!groupData.data?.settingsUpdate) {
        updateSettings();
      } else {
        initApi();
        setImageUploaded(false);
        setTempImage(null);
        setSaveLoader(false);
      }
    }
  };

  useEffect(() => {
    const initialSelectedTopicsValue = topics.reduce((acc, topic) => {
      acc[topic.name] = groupData?.data?.topics.includes(topic.name);
      return acc;
    }, {});
    setSelectedTopicsValue(initialSelectedTopicsValue);
  }, [groupData?.data?.topics]);

  /** For industries */
  useEffect(() => {
    const initialSelectedIndustriesValue = industries.reduce((acc, industries) => {
      acc[industries.name] = groupData?.data?.industries.includes(industries.name);
      // acc[industries.name] = false;
      return acc;
    }, {});
    setSelectedIndustriesValue(initialSelectedIndustriesValue);
  }, [groupData?.data?.industries]);

  useEffect(() => {
    if (groupData.data) {
      setSelectedCommunityValue(groupData?.data?.community);
    }
  }, [groupData?.data?.community]);

  useEffect(() => {
    if (groupData.data) {
      setAllowEditing(groupData.data.allowEditing);
      setIncludeValue(groupData.data.includeTask);
    }
  }, [groupData?.data]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setSaveLoader(true);
    upadteAllApis();
  };

  useEffect(() => {
    if (groupData?.data) {
      const { name, logo, endDate, currency, displayRoles } = groupData.data;
      const formattedEndDate = endDate ? moment.unix(endDate).format('yyyy-MM-DD') : '';
      const {
        gender = false,
        country = false,
        firstGenerationStudent = false,
        dob = false,
        ethnicity = false,
        disabilityType = false
      } = groupData.data.optionalFields || {};

      const { maxMatchesPerMentee = 1, maxMatchesPerMentor = 1 } = groupData?.data?.matchesSettings || {
        maxMatchesPerMentee: 1,
        maxMatchesPerMentor: 1
      };

      const initialData = {
        name,
        logo,
        endDate: formattedEndDate,
        currency,
        mentee: displayRoles.mentee,
        mentor: displayRoles.mentor,
        gender,
        country,
        firstGenerationStudent,
        dob,
        ethnicity,
        disabilityType,
        maxMatchesPerMentee,
        maxMatchesPerMentor
      };
      reset(initialData);
      setGroupDisplayRoles(groupData?.data?.displayRoles);
    }
  }, [groupData?.data]);

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>, fieldOnChange: (file: File | null) => void) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const droppedFile = event.dataTransfer.files[0];
      fieldOnChange(droppedFile);
      event.dataTransfer.clearData();
    }
  };
  const imageValue = getValues('logo');
  const [matchRequest, setMatchRequest] = useState(groupData.data?.matchesSettings.matchRequest);

  useEffect(() => {
    setMatchRequest(groupData.data?.matchesSettings.matchRequest);
  }, []);

  /** Topic section */
  const getTopics = () => {
    return (
      <Box>
        <Box display="flex" gap="10px">
          <Typography variant="h5" mb={2}>
            Topics
          </Typography>
          <FormHelperText error>{getError('topics')?.toString()}</FormHelperText>
        </Box>
        <Grid container spacing={2}>
          {_.sortBy(topics, 'label').map((each, index: any) => {
            return (
              <Controller
                key={index}
                name={each.name}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Grid item xs={12} md={6} display="flex" alignItems="center" gap="8px">
                      <Switch
                        // {...label}
                        checked={selectedTopicsValue[each.name] || false}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          setOptional(!optional);
                          // Update the selectedTopicsValue based on the switch state
                          setSelectedTopicsValue((prev: any) => ({
                            ...prev,
                            [each.name]: e.target.checked
                          }));
                          setTopicsUpdateFlag(true);
                        }}
                        name={each.name}
                      />
                      <Typography color="primary" noWrap>
                        {each.label}
                      </Typography>
                    </Grid>
                  );
                }}
              />
            );
          })}
        </Grid>
      </Box>
    );
  };

  /** Industries section */
  const getIndustries = () => {
    return (
      <Box>
        <Box display="flex" gap="10px">
          <Typography variant="h5" mb={2}>
            Industries
          </Typography>
          <FormHelperText error>{getError('industries')?.toString()}</FormHelperText>
        </Box>
        <Grid container spacing={2}>
          {_.sortBy(industries, 'label').map((each, index: any) => {
            return (
              <Controller
                key={index}
                name={each.name}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Grid item xs={12} md={6} display="flex" alignItems="center" gap="8px">
                      <Switch
                        checked={selectedIndustriesValue[each.name] || false}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          setOptional(!optional);
                          // Update the selectedTopicsValue based on the switch state
                          setSelectedIndustriesValue((prev: any) => ({
                            ...prev,
                            [each.name]: e.target.checked
                          }));
                          setIndustryUpdateFlag(true);
                        }}
                        name={each.name}
                      />
                      <Typography color="primary" noWrap>
                        {each.label}
                      </Typography>
                    </Grid>
                  );
                }}
              />
            );
          })}
        </Grid>
      </Box>
    );
  };

  /** Community section */
  const getCommunity = (disabled = false) => {
    return (
      <Box className={disabled ? 'opacity-25' : ''}>
        <Box display="flex" gap={0.5} alignItems="center" mb={2}>
          <Typography variant="h5">Community</Typography>
          {disabled && lockIcon({})}
        </Box>
        <Typography variant="subtitle2" pb={1}>
          Display on Community
        </Typography>
        <Grid container spacing={2}>
          {communityFields.map((each, index: any) => {
            return (
              <Controller
                key={index}
                name={each.name}
                defaultValue={selectedCommunityValue}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Grid item xs={6} md={4} display="flex" alignItems="center" gap="8px">
                      <Switch
                        disabled={disabled}
                        {...label}
                        checked={selectedCommunityValue[each.name]}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          setOptional(!optional);
                          setSelectedCommunityValue((prev: any) => ({
                            ...prev,
                            [each.name]: e.target.checked
                          }));
                        }}
                        name={each.name}
                      />
                      <Typography color="primary" noWrap>
                        {each.label}
                      </Typography>
                    </Grid>
                  );
                }}
              />
            );
          })}
        </Grid>

        <Box pt={2}>
          <Typography variant="subtitle2">Allow members to see</Typography>
          <FormControl disabled={disabled}>
            <RadioGroup row aria-labelledby="allowMembers-label" name="allowMembers" id="allowMembers">
              <Box display="flex" alignItems="center" pr={2}>
                <FormControlLabel value="all-organization-members" control={<Radio />} label="" className="mr-0" />
                <Typography color="primary">All Organization members</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <FormControlLabel value="program-members-only" control={<Radio />} label="" className="mr-0" />
                <Typography color="primary">Program members only</Typography>
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    );
  };

  /** Matches settings */
  const matchesSettings = (disabled = false) => {
    return (
      <Box className={disabled ? 'opacity-25' : ''}>
        <Box display="flex" gap={0.5} alignItems="center" mb={2}>
          <Typography variant="h5">Matches Settings</Typography>
          {disabled && lockIcon({})}
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputLabel>Maximum matches per {menteeRoleTextLowerCase} *</InputLabel>
            <Controller
              control={control}
              name="maxMatchesPerMentee"
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  disabled={disabled}
                  onBlur={() => {
                    trigger('maxMatchesPerMentee');
                    if (!checkError('maxMatchesPerMentee')) {
                      setInputFields({ ...inputFields, matchPerMentee: !matchPerMentee });
                    }
                  }}
                  type="number"
                  placeholder="0"
                  variant="outlined"
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setMatchUpdateFlag(true);
                    const inputValue = e.target.value.trim() === '' ? '' : parseInt(e.target.value);
                    onChange(inputValue);
                    trigger('maxMatchesPerMentee');
                  }}
                  name="maxMatchesPerMentee"
                  value={value || ''}
                  id="maxMatchesPerMentee"
                  error={checkError('maxMatchesPerMentee')}
                  helperText={getError('maxMatchesPerMentee')?.toString()}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>Maximum matches per {mentorRoleTextLowerCase} *</InputLabel>
            <Controller
              control={control}
              name="maxMatchesPerMentor"
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  disabled={disabled}
                  onBlur={() => {
                    trigger('maxMatchesPerMentor');
                    if (!checkError('maxMatchesPerMentor')) {
                      setInputFields({ ...inputFields, matchPerMentor: !matchPerMentor });
                    }
                  }}
                  type="number"
                  placeholder="0"
                  variant="outlined"
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setMatchUpdateFlag(true);
                    const inputValue = e.target.value.trim() === '' ? '' : parseInt(e.target.value);
                    onChange(inputValue);
                    trigger('maxMatchesPerMentor');
                  }}
                  value={value || ''}
                  id="maxMatchesPerMentor"
                  name="maxMatchesPerMentor"
                  error={checkError('maxMatchesPerMentor')}
                  helperText={getError('maxMatchesPerMentor')?.toString()}
                />
              )}
            />
          </Grid>

          {/* Members 'Request a Match' */}
          <Grid item xs={12} display="flex" alignItems="center" gap="8px">
            <Typography noWrap>Members 'Request a Match'</Typography>
            <Switch
              {...label}
              checked={matchRequest}
              onChange={(e) => {
                setMatchUpdateFlag(true);
                setMatchRequest(e.target.checked);
              }}
              disabled={disabled}
              id="requestMatch"
              name="requestMatch"
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  /** loader */
  const loading = useLoader(groupData.loading, 1000);
  if (loading) {
    return <BasicSettingsLoader />;
  }

  if (groupData.error) {
    return <Box>{groupData.errorText}</Box>;
  }

  if (groupData.data) {
    return (
      <Grid container bgcolor="#FFFFFF" border="1px solid #EFF0F4" borderRadius="8px">
        <Grid item xs={12} md={8}>
          <Box borderRight="1px solid #EFF0F4">
            <Box p={2}>
              <Typography variant="h4">Basic Settings</Typography>
            </Box>
            <Divider />
            <Box p={2}>
              <Box>
                <Grid container spacing={2} className="basicSettings">
                  <Grid item xs={12}>
                    <InputLabel>Program Name *</InputLabel>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          variant="outlined"
                          required
                          type="text"
                          name="name"
                          onChange={(e) => {
                            onChange(e.target.value);
                            trigger('name');
                            setBasicUpdateFlag(true);
                          }}
                          value={value || ''}
                          id="programName"
                          onBlur={() => {
                            trigger('name');
                            if (!checkError('name')) {
                              setInputFields({ ...inputFields, nam: !nam });
                            }
                          }}
                          error={checkError('name')}
                          helperText={getError('name')?.toString()}
                          placeholder="John"
                          style={{ width: '100%' }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>End Date</InputLabel>
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          type="date"
                          name="endDate"
                          placeholder="Select Date"
                          onChange={(e) => {
                            onChange(e.target.value);
                            trigger('endDate');
                            setBasicUpdateFlag(true);
                          }}
                          error={checkError('endDate')}
                          helperText={getError('endDate')?.toString()}
                          onBlur={() => {
                            trigger('endDate');
                            if (!checkError('endDate')) {
                              setInputFields({ ...inputFields, end: !end });
                            }
                          }}
                          value={value || ''}
                          id="endDate"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* Members */}
              <Divider className="my-20" />
              <Members
                control={control}
                updateMemberValue={updateMemberValue}
                setBasicUpdateFlag={setBasicUpdateFlag}
                place="BasicSettings"
                checkError={checkError}
                getError={getError}
                // disabled={true}
              />

              {/* Topics */}
              <Divider className="my-20" />
              {getTopics()}

              {/* Industries */}
              <Divider className="my-20" />
              {getIndustries()}

              {/* Community */}
              {/* <Divider className='my-20' />
              {getCommunity()} */}

              {/* Currency */}
              {/* <Divider className='my-20' />
              <CurrencySelect control={control} /> */}

              {/* Fields */}
              <Divider className="my-20" />
              <Fields
                setFieldValues={setFieldValues}
                fieldValues={fieldValues}
                setOptionalFieldsUpdateFlag={setOptionalFieldsUpdateFlag}
                groupDisplayRoles={groupDisplayRoles}
              />

              {/* Matches Settings */}
              <Divider className="my-20" />
              {matchesSettings()}

              {/* Task */}
              <Divider className="my-20" />
              <BasicSettingsTasks
                includeValue={includeValue}
                setIncludeValue={setIncludeValue}
                allowEditing={allowEditing}
                setAllowEditing={setAllowEditing}
                setBasicUpdateFlag={setBasicUpdateFlag}
              />

              {/* Signup & Login Links */}
              {/* <Divider className='my-20' />
              <AddLoginSignupLinks /> */}
            </Box>
          </Box>
        </Grid>

        {/* Right side */}
        <Grid item xs={12} md={4}>
          <Box position="relative" height="100%">
            <Box p={2}>
              <Typography variant="h4">Program Logo</Typography>
            </Box>
            <Divider />
            <Box p={2} display="flex" flexDirection="column" height="calc(100% - 66px)" justifyContent="space-between">
              <Box>
                <Box display="flex" alignItems="center" columnGap="8px">
                  <Box className="profileImageBox" onClick={handleImageClick}>
                    <label htmlFor="image-upload" className="cursor-pointer">
                      <Box className={!imageUploaded && !_.head(imageUploaded) ? '' : 'ProfileCameraBgOverlay'}>
                        {tempImage ? (
                          <CameraAltOutlinedIcon
                            className={`IfNoIcon color-${imageValue && _.head(imageValue) ? 'secondary' : 'white'}`}
                          />
                        ) : (
                          <Box position="relative">
                            <ChatAvatarComponent
                              width="80px"
                              height="80px"
                              image={_.get(groupData, 'data.logo', '')}
                              type="noStatus"
                              fontSize="18x"
                            />
                            {groupData.data?.logo ? <Box top="0px" className="ProfileCameraBgOverlay" /> : null}

                            <CameraAltOutlinedIcon
                              className={`IfNoIcon color-${groupData.data?.logo ? 'white' : 'secondary'}`}
                            />
                          </Box>
                        )}
                      </Box>
                      {typeof tempImage !== 'string' && imageUploaded && (
                        <img
                          className="profileImage"
                          src={tempImage ? URL.createObjectURL(tempImage) : ''}
                          alt="preview"
                        />
                      )}
                    </label>
                  </Box>
                  <Typography variant="h6">Upload Logo</Typography>
                </Box>
                <Box>
                  <Controller
                    name="logo"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
                        const file = event.target.files?.[0];
                        const maxSize = imgSize.limitValue;

                        if (file && file.size > maxSize) {
                          toast.error('Only png, jpeg, svg and gif files of less than 10MB can be uploaded');
                          return;
                        }

                        if (file) {
                          const allowedExtensions = ['png', 'jpeg', 'jpg', 'svg', 'gif'];
                          const fileExtension = file?.name?.split('.').pop()?.toLowerCase() || '';
                          if (!allowedExtensions.includes(fileExtension)) {
                            toast.error('Only jpeg, png, svg and gif files can be uploaded');
                            return;
                          }
                        }

                        if (event.target.files?.length) {
                          setImageUploaded(true);
                          try {
                            let photo = file;
                            const allowedExtensions = ['png', 'jpeg', 'jpg'];
                            const fileExtension = file?.name?.split('.').pop()?.toLowerCase() || '';
                            if (allowedExtensions.includes(fileExtension)) {
                              photo = await handleImageCompression(file);
                            }
                            setTempImage(photo);
                            setInputFields({ ...inputFields, logo: !logo });
                            onChange(photo);
                            setBasicUpdateFlag(true);
                          } catch (error) {}
                        } else if (event.target.value === '') {
                          setImageUploaded(false);
                        }
                      };
                      return (
                        <Box
                          mt={2}
                          px={2}
                          pt="5px"
                          pb="15px"
                          bgcolor="#FFFFFF"
                          borderRadius="8px"
                          onClick={handleImageClick}
                          onDragOver={handleDragOver}
                          className="cursor-pointer"
                          border="1px dashed #DEDFDF"
                          onDrop={(event) => handleDrop(event, onChange)}
                        >
                          <Box textAlign="center" className="cursor-pointer">
                            <IconButton>
                              <UploadIcon size={25} />
                            </IconButton>
                            <input
                              type="file"
                              name="logo"
                              id="selectedImage"
                              ref={fileInputRef}
                              placeholder="Logo"
                              className="d-none"
                              onChange={handleImageChange}
                              accept=".png,.jpeg,.jpg,.svg,"
                            />
                            <Typography color="error" variant="body2" fontSize="12px">
                              {getError('logo')?.toString()}
                            </Typography>
                            <Typography fontSize="12px" fontWeight="600" color="#999999">
                              <span className="color-blue"> Click to upload </span>
                              or drag and drop <br /> jpg, png, svg or gif <br />
                              (ideal dimensions 800x800 px)
                            </Typography>
                          </Box>
                        </Box>
                      );
                    }}
                  />
                </Box>
              </Box>
              <Box mt={2} textAlign="right">
                <LoadingButton
                  color="primary"
                  variant="contained"
                  loading={saveLoader}
                  className="h-50px w-150px"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default BasicSettings;
