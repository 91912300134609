/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
// import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Dialog, DialogContent, DialogActions, Button, IconButton, Typography } from '@mui/material';
import upload from '../../../assets/images/upload.svg';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { API } from '../../../api';
// import videoImage from '../../../assets/images/videoimage.png';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { userActions, userInfoUpdate } from '../../../redux/slices/user-profile/userProfieSlice';
import { getUserDetails } from '../../../utils/orgName';
import { LoadingButton } from '@mui/lab';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import { popupCountActions } from '../../../redux/slices/poupsCountsSlice';
import { useXpPoints } from './menteeXpPoints';
import ROLES from '../../../utils/roles';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';
import VideoSection from '../tabs/chat/chat-main/VideoSection';

const DigitalIntroForm = () => {
  const getXpPoints = useXpPoints();
  const { mentee } = useProfileDetails();
  const { user, getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const openDigital = useAppSelector((state) => state.onboardingPopups.data.digitalIntro);
  const userData = useAppSelector((state) => state.userProfile.data);
  const { videoIntro } = useProfileDetails();
  const [video, setVideo] = useState<File | null>(null);
  const [videoUrl, setVideoUrl] = useState<string>(videoIntro);
  const selectedGroup = useAppSelector((state) => state.selectedGroup.data);

  const [videoUrlTest, setVideoUrlTest] = useState<string>(videoIntro);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isHovering, setIsHovering] = useState(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [openDelete, setDelete] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const role = localStorage.getItem('role');

  // const [videoDataLink, setVideoDataLink] = useState<string>(videoIntroValue);

  useEffect(() => {
    // setVideoDataLink(videoIntroValue)
    const data = localStorage.getItem('digitalIntro');
    const videoData = data && ftue ? JSON.parse(data) : videoIntro;
    setVideoUrl(videoData);
  }, [videoIntro]);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsHovering(true);
    } else if (e.type === 'dragleave') {
      setIsHovering(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleVideoChange(e.dataTransfer.files[0]);
      setShowPreview(true);
    }
  };

  function handleImageClick() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      setShowPreview(true);
    }
  }

  function handleVideoChange(selectedFile: File) {
    if (selectedFile) {
      const maxFileSize = 60 * 1024 * 1024;

      const allowedMimeTypes = [
        'video/mp4',
        'video/webm',
        'video/ogg'
        // 'video/mpeg',
        // 'video/x-msvideo',
        // 'video/quicktime',
        // 'video/x-flv',
        // 'video/x-ms-wmv',
        // 'video/x-matroska'
        // Add any other video MIME types you want to allow
      ];

      if (!allowedMimeTypes?.includes(selectedFile.type)) {
        toast.error('Only mp4, webm, ogg, mpeg, x-msvideo, quicktime, video/x-flv, x-ms-wmv and x-matroska files can be uploaded');
        return;
      }

      if (selectedFile.size > maxFileSize) {
        toast.error('Only webp and mp4 files of less than 100MB can be uploaded');
        return;
      }

      setVideo(selectedFile);
      const url = URL.createObjectURL(selectedFile);
      // setVideoUrl(url);
      setVideoUrlTest(url);
      setErrorMessage('');
      setShowPreview(true);
    }
  }

  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleCloseDigital = () => {
    dispatch(popupActions.updatePopups({ digitalIntro: false }));
    setShowPreview(false);
    setVideo(null);
    setErrorMessage('');
    // setVideoUrl("");
    setVideoUrlTest('');
  };
  const { settings } = useProfileDetails();

  const handleSave = async () => {
    const isRequired = settings?.videoIntro;
    if (isRequired && !video && !videoUrl && !videoUrlTest) {
      setErrorMessage('Please select a video file.');
      return;
    }
    if (!ftue) {
      handleCloseDigital();
    }
    handleSubmit();
  };

  useEffect(() => {
    setShowPreview(true);
  }, []);

  const { ftue } = useProfileDetails();
  const popuplength = useAppSelector((state) => state.popupsCount.popuplength);
  const count = useAppSelector((state) => state.popupsCount.count);
  const nextPopUpOrNot = popuplength - count;
  const [loading, setLoading] = useState(false);

  const updateFtue = async () => {
    setLoading(true);
    try {
      const response = await API.updateUserFtue({ ftue: false, userId });
      if (response.status === 200) {
        dispatch(userActions.userFtueUpdate(0));
        setLoading(false);
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Couldn't update profile details`));
      setLoading(false);
    }
  };

  const handleSubmit = async (): Promise<boolean> => {
    if (ftue && nextPopUpOrNot > 1) {
      dispatch(popupCountActions.increment(0));
    }

    setIsLoading(true);

    const idTokenClaims = await getIdTokenClaims();
    const accessToken = await getAccessTokenSilently();
    const { orgId } = getUserDetails();

    try {
      if (video !== null) {
        const url = URL.createObjectURL(video);
        localStorage.setItem('digitalIntro', JSON.stringify(url));
        dispatch(userInfoUpdate({ ...userData, videoIntro: url }));

        const formData = new FormData();
        formData.append('video', video);

        const response = await API.addUserVideoUpload({
          data: formData,
          orgId
        });
        if (response.status === 200) {
          if (!ftue) {
            toast.success('Video introduction uploaded successfully');
            if (selectedGroup?.role === 'mentee') {
              getXpPoints('mentee');
            }
          }
          if (!ftue) {
            localStorage.removeItem('digitalIntro');
          }
          dispatch(userInfoUpdate({ ...userData, videoIntro: response.data.URL }));
        }
      }
      if (ftue && nextPopUpOrNot === 1) {
        updateFtue();
      }
    } catch (err) {
      localStorage.removeItem('digitalIntro');
      toast.error('Video failed to upload');
    } finally {
      setIsLoading(false);
    }

    return true;
  };

  const { userId } = getUserDetails();

  const handleDelete = async () => {
    setDeleteLoader(true);
    try {
      const response = await API.deleteVideoIntro({
        userId: userId
      });

      if (response.status === 200) {
        toast.success('Video introduction deleted successfully');
        setVideoUrl('');
        setVideoUrlTest('');
        setVideo(null);
        dispatch(userInfoUpdate({ ...userData, videoIntro: '' }));
        // setDelete(false);
        setDeleteLoader(false);
        handleCloseDigital();
      }

      setDelete(false);
    } catch (e: any) {
      const { status, statusText } = e.response;
      toast.error(_.get(e, 'response.data.message', `${status}: ${statusText}`));
      setDeleteLoader(false);
    }
  };

  const handleRemoveClose = () => {
    // handleCloseDigital();
    if (videoUrl.includes('blob:') || videoUrlTest.includes('blob:')) {
      setDelete(false);
      localStorage.removeItem('digitalIntro');
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setVideo(null);
      setVideoUrl('');
      setVideoUrlTest('');
      handleCloseDigital();
      dispatch(userInfoUpdate({ ...userData, videoIntro: '' }));
      return;
    }
    handleDelete();
  };

  useEffect(() => {
    return () => {
      handleCloseDigital();
    };
  }, []);

  return (
    <>
      <DialogContent>
        <Box
          p={2}
          bgcolor={'#FFFFFF'}
          border={'1px dashed #DEDFDF'}
          borderRadius={'8px'}
          className={'cursor-pointer'}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={handleImageClick}
        >
          <Box display="flex" flexDirection="column" gap={2} textAlign="center">
            {showPreview && (videoUrlTest || videoUrl) ? (
              <Box
                sx={{ width: '45%', position: 'relative' }}
                margin="auto"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <VideoSection videoUrl={videoUrlTest || videoUrl} parentComponent="chatMain" autoPlay={false} />
              </Box>
            ) : (
              <>
                {/* <Box
                  width='140px'
                  height='80px'
                  display='flex'
                  bgcolor='#F0F0F0'
                  alignItems='center'
                  justifyContent='center'
                >
                  <img
                    src={videoImage}
                    alt="placeholder"
                    style={{
                      width: 'auto',
                      height: 'auto'
                    }}
                  />
                </Box> */}
              </>
            )}
            <Box>
              <input
                type="file"
                id="video-upload"
                accept="video/*"
                onChange={(e) => e.target.files && handleVideoChange(e.target.files[0])}
                ref={fileInputRef}
                className="d-none"
              />

              <IconButton>
                <img
                  src={upload}
                  alt="upload"
                  width="38"
                  height="38"
                  style={{ filter: isHovering ? 'brightness(1.2)' : 'none' }}
                />
              </IconButton>

              <Typography fontWeight="600">
                <span style={{ color: '#3D8BFD' }}>Click to upload</span> <span>or drag</span>
              </Typography>
              <Typography variant="caption" color="secondary">
                Video file (max size 60MB)
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Delete button while onboarding */}
        {/* {ftue && (videoIntro || video || videoUrl) && (
            <Box textAlign="right" mt={1}>
              <Button
                onClick={() => setDelete(true)}
                color="secondary"
                endIcon={<DeleteIcon />}
                variant="outlined"
                size="small"
                sx={{
                  fontSize: '12px'
                }}
              >
                Remove
              </Button>
            </Box>
          )} */}
        <Typography color="error" textAlign="center" variant="caption" mt={1}>
          {errorMessage}
        </Typography>
      </DialogContent>

      <DialogActions>
        {ftue && (
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            // onClick={handleRemoveClose}
            onClick={() => dispatch(popupCountActions.decrement(0))}
          >
            Back
          </Button>
        )}

        {!ftue && videoIntro && (
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            // onClick={handleRemoveClose}
            onClick={() => setDelete(true)}
          >
            Remove
          </Button>
        )}

        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={handleSave}
          id="videointroductionSubmit"
          disabled={loading}
        >
          {nextPopUpOrNot === 1 && ftue ? 'Finish' : nextPopUpOrNot > 1 && ftue ? 'Next' : 'Save'}
        </Button>
      </DialogActions>

      {/* Remove Confirmation Popup */}
      <Dialog open={openDelete} onClose={() => setDelete(false)}>
        {/* Dialog Header */}
        <CommonModalHeader title={`Confirmation`} handleCloseModal={() => setDelete(false)} />
        <DialogContent>
          <Typography variant="h5" textAlign={'center'}>
            Are you sure you want to remove this <br /> Video introduction?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="outlined" color="secondary" onClick={() => setDelete(false)}>
            No
          </Button>

          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            loading={deleteLoader}
            onClick={handleRemoveClose}
          >
            Yes, Remove
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DigitalIntroForm;
