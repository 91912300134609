/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-invalid-this */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getUserDetails } from '../../../utils/orgName';
import { API } from '../../../api';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { toast } from 'react-toastify';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import { userInfoUpdate } from '../../../redux/slices/user-profile/userProfieSlice';
import countryCodeArray from '../../../constants/countrycode';
import { disabilityPopupActions } from '../../../redux/slices/disability/disabilityPopupSlice';
import { countries } from '../../profile-page/countryData';
import { timezones } from '../../profile-page/timeZones';
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Controller, SubmitHandler, FieldValues, useForm } from 'react-hook-form';
// import ChatAvatarComponent from "../tabs/chat/ChatAvatarComponent";
import { validatePhoneNumber } from '../../../constants/PhoneNumberValidator';
import PhoneInput from '../../../constants/PhoneInput';
import { fetchOrgDetails } from '../../../redux/slices/orgDetails/orgDetailsSlice';
import ROLES from '../../../utils/roles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import EditProfileForm from './EditProfileForm';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';

interface Props {
  setOpen: any;
  setData?: any;
  disableClose: boolean;
}

// const userData = {
//   firstName: getUserDetails().firstName,
//   lastName: getUserDetails().lastName,
//   gender: '',
//   country: '',
//   phoneNumber: '',
//   ethnicity: '',
//   timezone: '',
//   headshot: getUserDetails().picture,
//   firstGenStudent: 'No',
//   email: getUserDetails().email,
//   category: '',
//   dob: ''
// };
const EditProfile = () => {
  const { open, disable } = useAppSelector((state) => state.onboardingPopups.data.profile);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    if (!disable) {
      dispatch(popupActions.updatePopups({ profile: { open: false, disable: false } }));
    }
  };

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);
  useEffect(() => {}, [open]);
  return (
    <Dialog open={open} onClose={handleClose} disableEnforceFocus scroll="body" fullWidth>
      <CommonModalHeader title={`Personal information`} handleCloseModal={handleClose} />
      <EditProfileForm />
    </Dialog>
  );
};

export default EditProfile;
