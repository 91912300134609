/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-duplicate-imports */
import React, { useState } from 'react';
import {
  Box,
  Table,
  Popover,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  TableContainer
} from '@mui/material';
import {
  AddLevelIcon,
  CircleAddIcon,
  // FilterIcon,
  PlusCircleIcon
} from '../../../../../utils/Icons';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { API } from '../../../../../api';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import AddProgram from '../Dialogs/AddProgram';
import ProgramTableRow from './ProgramTableRow';
// import FilterModal from '../../../components/filter/FilterModal';
import { groupActions } from '../../../../../redux/slices/getAllGroups/getAllGroupsSlice';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { fetchAllGroupsList } from '../../../../../redux/slices/getAllGroupsListSlice';
import { getUserDetails } from '../../../../../utils/orgName';
import ProgramTableLoader from '../../../../../Loader/ProgramTableLoader';

const ProgramTable = () => {
  const allGroupData = useAppSelector((state) => state.allGroups.data);
  const grpId = useParams().id || '';
  const [addGrpLoader, setAddGrpLoader] = useState(false);
  const coId = useParams().coId || '';
  const [dialogType, setDialogType] = useState<string>('');
  // const orgGroupData = useAppSelector((state) => state.orgLevelGroup);
  // const allGroupsData = useAppSelector((state) => state.allGroups);
  // const grpList = useAppSelector((state) => state.groupRoleList);
  // const orgId = orgGroupData.data?.orgId || '';
  // const grpId2 = orgGroupData.data?.grpId || '';
  const dispatch = useAppDispatch();
  let requiredText = 'Program name is required';
  if (dialogType === 'level') {
    requiredText = 'Level name is required';
  }

  const schema = yup.object({
    name: yup
      .string()
      .required(requiredText)
      .test('no-whitespace', 'Min 3 characters without spaces', (value) => {
        if (!value) return false; // if value is undefined or null
        return value.replace(/\s/g, '').length >= 3;
      })
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(schema)
  });

  /** Filter */
  // const [openFilter, setopenFilter] = useState(false);
  // const handleopenFilter = (flag: boolean) => setopenFilter(flag);
  const [openAddProgram, setOpenAddProgram] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const loading = useAppSelector((state) => state.allGroups.loading);
  const id = openPopover ? 'simple-popover' : undefined;
  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenAddProgram = (type?: string) => {
    setOpenAddProgram(true);
    setDialogType(type || '');
  };

  const handleAddProgramDialog = (flag: boolean) => {
    setOpenAddProgram(flag);
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setAddGrpLoader(true);
    try {
      let response;
      if (dialogType === 'level') {
        response = await API.createLevel({
          orgId: coId,
          parent: grpId,
          name: data.name
        });
      } else {
        response = await API.createGroup({
          orgId: coId,
          parent: grpId,
          name: data.name
        });
      }
      if (response.status === 200) {
        setAddGrpLoader(false);
        handleAddProgramDialog(false);
        if (dialogType === 'level') {
          dispatch(groupActions.addGroup(response.data.LevelDetails));
        } else {
          dispatch(groupActions.addGroup(response.data.groupDetails));
        }
        if (grpId) {
          dispatch(fetchAllGroupsList({ orgId: getUserDetails().orgId, groupId: grpId }));
        }
        // dispatch(fetchAllGroups({ orgId, grpId:grpId2 , useCache: false }));
        setValue('name', '');
        handleClose();
      }
    } catch (e) {
      toast.error('Could not create a group');
      setAddGrpLoader(false);
    }
  };

  const displayGroups = () => {
    return (
      <>
        {loading ? (
          <ProgramTableLoader />
        ) : (
          <>
            {allGroupData && allGroupData.groups ? (
              <Box mt={2}>
                <TableContainer>
                  <Table className="tableBody" width="100%" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} width="50%" align="left">
                          <Typography variant="h5">
                            {allGroupData.mainGroup ? 'All Programs' : 'Groups List'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={3} width="50%">
                          <Box display="flex" justifyContent="flex-end" gap="5px">
                            {/* <FilterIcon size={34} onClick={() => handleopenFilter(true)} /> */}
                            {grpId !== 'admin' && <PlusCircleIcon size={34} onClick={(e) => handleOpen(e)} />}
                          </Box>
                          <Popover
                            id={id}
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left'
                            }}
                            transformOrigin={{
                              vertical: 'center',
                              horizontal: 'right'
                            }}
                          >
                            <Box className="PopoverListStyle" onClick={() => handleClickOpenAddProgram('level')}>
                              <AddLevelIcon size={21} />
                              <Typography color="primary">Add a Level</Typography>
                            </Box>
                            <Box
                              className="PopoverListStyle"
                              onClick={() => handleClickOpenAddProgram(allGroupData.mainGroup ? 'programs' : 'groups')}
                            >
                              <CircleAddIcon size={21} />
                              <Typography color="primary">
                                Add a {allGroupData.mainGroup ? 'Program' : 'Group'}
                              </Typography>
                            </Box>
                          </Popover>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{allGroupData?.mainGroup ? 'PROGRAM' : 'GROUP'} NAME</TableCell>
                        <TableCell>ENROLLED</TableCell>
                        {/* <TableCell>ACTIVE</TableCell> */}
                        {/* <TableCell>ROI</TableCell> */}
                        {/* <TableCell>GOAL PROGRESS</TableCell> */}
                        {/* <TableCell>XP</TableCell> */}
                        {/* <TableCell>DAYS REMAINING</TableCell> */}
                        <TableCell>START DATE</TableCell>
                        {/* <TableCell>END DATE</TableCell> */}
                        <TableCell>STATUS</TableCell>
                        <TableCell>ACTION</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allGroupData.groups.length > 0 ? (
                        allGroupData.groups.map((item) => <ProgramTableRow data={item} key={item.grpId} />)
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Typography textAlign="center">No Groups Found</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              <Typography textAlign="center" className="CardBox" mt={2} p={2}>
                {' '}
                No Data Found{' '}
              </Typography>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      {/* Add a Program */}
      {displayGroups()}
      <AddProgram
        handleAddProgramDialog={handleAddProgramDialog}
        addProgramDialogFlag={openAddProgram}
        onSubmit={onSubmit}
        addGrpLoader={addGrpLoader}
        control={control}
        errors={errors}
        handleSubmit={handleSubmit}
        dialogType={dialogType}
        reset={reset}
      />

      {/* Filter */}
      {/* <FilterModal
        loading={addGrpLoader}
        openFilter={openFilter}
        handleopenFilter={handleopenFilter}
      /> */}
    </>
  );
};

export default ProgramTable;
