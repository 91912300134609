import React, { useState } from 'react';
import { Dialog, TextField, Typography, DialogContent, DialogActions } from '@mui/material';
import { getUserDetails } from '../../../../utils/orgName';
import { toast } from 'react-toastify';
import { API } from '../../../../api';
import { LoadingButton } from '@mui/lab';
import useCallDetails from '../../../../queries/useCallDetails';
import CommonModalHeader from '../../../../components/DialogUi/CommonModalHeader';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { callsCountActions } from '../../../../redux/slices/callCountSlice';

interface RejectionCallModalProps {
  openRejectionCall: boolean;
  closeRejectionModal: () => void;
  callMeetingId: any;
  onRejectSuccess: () => void;
}

const RejectionCallModal: React.FC<RejectionCallModalProps> = ({
  openRejectionCall,
  closeRejectionModal,
  callMeetingId,
  onRejectSuccess
}) => {
  const page = 1; // Set your desired page size
  const [pageSize, setPageSize] = useState(10);
  const userCallsData = useCallDetails(page, pageSize);
  const [rejectionReason, setRejectionReason] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const handleRejectionReasonChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setRejectionReason(event.target.value);
  };
  const dispatch = useAppDispatch();
  const callsCount = useAppSelector((state) => state.callsCount.callsCount);
  const handleSendClick = async () => {
    if (!rejectionReason) {
      toast.error('Please add a rejection reason');
      return;
    }
    const userDetails = getUserDetails();
    const callRejectReason = { callRejectReason: rejectionReason };
    try {
      setIsLoader(true);
      const response = await API.rejectScheduleCall(
        userDetails.userId,
        callMeetingId,
        userDetails.orgId,
        userDetails.location,
        callRejectReason
      );
      if (response) {
        dispatch(callsCountActions.updateCount(callsCount - 1));
        // refetch .....
        await userCallsData
          .invalidateQuery()
          .then((res) => {})
          .catch((err) => {})
          .finally(() => {
            userCallsData.refetch();
          });
        toast.success('Call rejected sucessfully');
        onRejectSuccess();
      }
    } catch (e) {
      toast.error('Something went wrong, please try again');
    } finally {
      closeRejectionModal();
      setRejectionReason('');
      setIsLoader(false);
    }
  };
  return (
    <Dialog open={openRejectionCall} onClose={closeRejectionModal} maxWidth="sm" fullWidth>
      {/* Dialog Header */}
      <CommonModalHeader title={`Rejection Reason`} handleCloseModal={closeRejectionModal} />
      <DialogContent>
        <Typography variant="h5" mb={1}>
          Reason
        </Typography>
        <TextField
          multiline
          rows={4}
          placeholder="Write your rejection reason, so student have idea about next  other meets."
          onChange={handleRejectionReasonChange}
        />
      </DialogContent>
      <DialogActions className="pt-0">
        <LoadingButton loading={isLoader} fullWidth disabled={isLoader} variant="contained" onClick={handleSendClick}>
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RejectionCallModal;
