import { useQuery } from '@tanstack/react-query';
import { API } from '../api';

const useGroupOverview = ({ orgId, grpId }: { orgId: string; grpId: string }) => {
  const { data, isError, isSuccess, isLoading } = useQuery({
    queryKey: ['groupOverview', grpId],
    queryFn: () => API.getGroupsOverview({ grpId, orgId }),
    refetchOnWindowFocus: false
  });
  return {
    data: data?.data?.data,
    isLoading,
    isError,
    isSuccess
  };
};

export default useGroupOverview;
