import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Chip,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { API } from '../../../api';
import { toast } from 'react-toastify';
import { getUserDetails } from '../../../utils/orgName';
import { userActions, userHobbiesUpdate } from '../../../redux/slices/user-profile/userProfieSlice';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import { popupCountActions } from '../../../redux/slices/poupsCountsSlice';
import { fetchAllTags } from '../../../redux/slices/tags/alltagslistSlice';
import MyTooltip from './MyTooltip';
import useProfileDetails from '../../../hooks/useProfileDetails';
import _ from 'lodash';
import { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions<any>();

interface HobbyAndInterest {
  _id: string;
  oid: string;
  value: string;
  type: string;
  approved: boolean;
  __v: number;
}

const HobbiesForm = () => {
  const [loading, setLoading] = useState(false);
  const userDetails = useAppSelector((state) => state.userProfile);
  const dataofHobbies = userDetails.data?.personal_details?.hobbies;

  const [values, setValues] = useState<string[]>([]);
  const [hobbiesAndInterests, setHobbiesAndInterests] = useState<HobbyAndInterest[]>([]);
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();
  const { ftue, settings } = useProfileDetails();
  const tokens = localStorage.getItem('tokens') ? JSON.parse(localStorage.getItem('tokens') || '') : {};
  const { location, orgId, userId } = getUserDetails();
  const popuplength = useAppSelector((state) => state.popupsCount.popuplength);
  const count = useAppSelector((state) => state.popupsCount.count);
  const nextPopUpOrNot = popuplength - count;

  const hobbiesDisable = useAppSelector((state) => state.onboardingPopups.data.hobbiesDisable);
  const userData = useAppSelector((state) => state.userProfile.data);
  const userProfile = useAppSelector((state) => state.userProfile.data?.personal_details);
  const getAllEducationDetails: any = useAppSelector((state) => state.userEducation?.updatedData);
  const optionalFields = _.get(userData, 'settings', {
    gender: false,
    ethnicity: false,
    firstGenerationStudent: false,
    disabilityType: false,
    dob: false,
    country: false,
    category: false,
    timezone: false,
    workHistory: false,
    educationHistory: false,
    hobbiesInterests: false
  });
  useEffect(() => {
    if (dataofHobbies) {
      setValues(dataofHobbies);
    }
  }, [dataofHobbies]);

  const filteredData = hobbiesAndInterests.filter((item) => item.type === 'Hobbies and Interests');
  const names: string[] = filteredData.map((item: HobbyAndInterest) => item.value);
  const [hobbies, setHobbies] = useState<string[]>(names);

  useEffect(() => {
    setHobbies(names);
  }, [hobbiesAndInterests]);

  useEffect(() => {
    const data = localStorage.getItem('hobbies');
    // const hobbies = ftue && data ? JSON.parse(data) : [];
    // setValues(hobbies);
    setValues(dataofHobbies || []);
  }, [ftue]);

  useEffect(() => {
    dispatch(fetchAllTags({ orgId, location }));
  }, [dispatch, ftue, orgId, location]);

  const list = useAppSelector((state) => state?.alltags?.data);

  useEffect(() => {
    if (Array.isArray(list) && list?.length > 0) {
      setHobbiesAndInterests(list);
    }
  }, [list]);

  const handleHobbies = (event: any, value: string[]) => {
    setValues(value);
    // to add the user created tag to the original list, so that duplicate won't be created
    const tempHobbies = [...hobbies];
    value.forEach((item: any) => {
      if (typeof item !== 'string') {
        tempHobbies.push(item?.inputValue);
      }
    });
    setHobbies(tempHobbies);
    if (settings?.hobbiesInterests) {
      setError(value.length === 0);
    }
  };

  const updateFtue = async () => {
    setLoading(true);
    try {
      const response = await API.updateUserFtue({ ftue: false, userId });
      if (response.status === 200) {
        dispatch(userActions.userFtueUpdate(0));
        setLoading(false);
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Hobby details failed to update`));
      setLoading(false);
    }
  };
  const onSubmit = async () => {
    if (values.length === 0 && settings?.hobbiesInterests) {
      setError(true);
    } else {
      setError(false);
      if (ftue && nextPopUpOrNot !== 1) {
        dispatch(popupCountActions.increment(0));
      }
      setLoading(true);
      try {
        localStorage.setItem(
          'hobbies',
          JSON.stringify(values.map((item: any) => (typeof item === 'string' ? item : item?.inputValue)))
        );
        const response = await API.updateHobbies({
          orgId,
          token: tokens.access_token,
          idtoken: tokens.id_token,
          data: values.map((item: any) => (typeof item === 'string' ? item : item?.inputValue))
        });
        if (response.status === 200) {
          localStorage.removeItem('hobbies');
          setLoading(false);
          handleClose();
          dispatch(userHobbiesUpdate(response.data.hobbies));
          setValues(response.data.hobbies);
          if (nextPopUpOrNot === 1) {
            await updateFtue();
          }
          if (!ftue) {
            dispatch(popupActions.updatePopups({ hobbies: false, hobbiesDisable: false }));
          }
          if (
            !ftue &&
            optionalFields.educationHistory &&
            userData?.educationHistory.length &&
            getAllEducationDetails.length === 0
          ) {
            dispatch(
              popupActions.updatePopups({
                education: { open: true, educationPopUpDisable: true, action: 'Add', item: {} }
              })
            );
          }
          // toast.success('Hobbies updated successfully');
        } else {
          toast.error('Something went wrong, please try again. server');
        }
      } catch (e) {
        toast.error('Something went wrong, please try again');
      }
    }
  };

  const handleClose = () => {
    if (!hobbiesDisable) {
      dispatch(popupActions.updatePopups({ hobbies: false, hobbiesDisable: false }));
    }
  };

  const getDialogActions = () => (
    <DialogActions>
      {ftue && (
        <LoadingButton
          variant="outlined"
          color="secondary"
          fullWidth
          id="hobbiesSubmitBack"
          onClick={() => dispatch(popupCountActions.decrement(0))}
        >
          Back
        </LoadingButton>
      )}
      <LoadingButton
        color="primary"
        variant="contained"
        fullWidth
        loading={loading}
        onClick={onSubmit}
        id="hobbiesSubmit"
      >
        {nextPopUpOrNot === 1 && ftue ? 'Finish' : nextPopUpOrNot > 1 && ftue ? 'Next' : 'Save'}
      </LoadingButton>
    </DialogActions>
  );

  return (
    <>
      <DialogContent>
        <Grid item xs>
          <InputLabel>
            Hobbies{settings && settings.hobbiesInterests ? ' *' : ''}
            <MyTooltip
              gapleft={0.5}
              title="We add this to your profile as an ice breaker and to our matching algorithm to optimize mentoring matching"
            />
          </InputLabel>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              clearOnBlur
              selectOnFocus
              handleHomeEndKeys
              options={hobbies}
              getOptionLabel={(option: any) => {
                if (typeof option === 'string') {
                  return option;
                }
                return option?.title;
              }}
              // filterOptions={(options, state) =>
              //   options?.filter((option) => option?.toLowerCase()?.startsWith(state?.inputValue?.toLowerCase()))
              // }
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // user can add a new value
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue.trim() !== '' && !isExisting && !values.includes(inputValue)) {
                  filtered.push({
                    inputValue: inputValue.trim(),
                    title: `Add "${inputValue.trim()}"`
                  });
                }

                return filtered;
              }}
              value={values.map((value: any) => (typeof value === 'string' ? value : value?.inputValue))}
              id="hobbies"
              onChange={handleHobbies}
              disableCloseOnSelect
              getOptionDisabled={(option) =>
                option !== 'Prefer not to say (default)' && values?.includes('Prefer not to say (default)')
              }
              // freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  placeholder={values.length ? '' : 'Select Hobbies'}
                  // onKeyDown={(event: any) => {
                  //   if (event.key === 'Enter') {
                  //     event.preventDefault();
                  //     const newValue = event.target.value;
                  //     if (newValue.trim() && !values.includes(newValue)) {
                  //       setValues([...values, newValue]);
                  //     }
                  //   }
                  // }}
                />
              )}
              renderTags={(value, getTagProps) =>
                value?.map((option, index) => {
                  return <Chip label={option} {...getTagProps({ index })} />;
                })
              }
            />
          </FormControl>
          {settings?.hobbiesInterests && (
            <FormHelperText error sx={{ mt: 1 }}>
              {error && 'Please select a hobby'}
            </FormHelperText>
          )}
        </Grid>
      </DialogContent>
      {getDialogActions()}
    </>
  );
};

export default HobbiesForm;
