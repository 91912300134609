/* eslint-disable react/no-unstable-nested-components */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Box,
  Link,
  Grid,
  Stack,
  Table,
  Paper,
  Select,
  Button,
  Dialog,
  Tooltip,
  Popover,
  Divider,
  Checkbox,
  MenuItem,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  InputLabel,
  Typography,
  IconButton,
  Autocomplete,
  DialogActions,
  DialogContent,
  FormHelperText,
  TableContainer,
  FormControlLabel,
  DialogContentText
} from '@mui/material';
import _, { set } from 'lodash';
import { toast } from 'react-toastify';
// import TaskPreview from './TaskPreview';
import { LoadingButton } from '@mui/lab';
// import ContentsModal from './ContentsModal';
import { useParams } from 'react-router-dom';
import { API } from '../../../../../../../api';
import LevelsDialog from './Dialog/LevelsDialog';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import upload from '../../../../../../../assets/images/upload.svg';
import { GoalSettingsStyle } from './GoalSettingsStyling';
import { GroupBasedRoleFormatter, truncateText } from '../../../../../components/common';
import downloadblue from '../../../../../../../assets/images/downloadblue.svg';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import CommonModalHeader from '../../../../../../../components/DialogUi/CommonModalHeader';
import { grpDetailsActions } from '../../../../../../../redux/slices/group-details/groupDetails';
import { DeleteIcon, EditIcon, PreviewIcon } from '../../../../../../../utils/Icons';
import { fetchAllTemplates } from '../../../../../../../redux/slices/goals-templates/goalsTemplatesSlice';
import { IAdminTask, ITaskData, adminTaskActions } from '../../../../../../../redux/slices/tasksSlice';
import GoalSettingsLoader from '../../../../../../../Loader/GoalSettingsLoader';
import { getUserDetails } from '../../../../../../../utils/orgName';
// import { customProgramActions } from '../../../../../../../redux/slices/custom-program/customProgramSlice';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { fetchAllArticles } from '../../../../../../../redux/slices/article/getAllArticleSlice';
import ProgramDeletDialog from '../../ProgramDeletDialog';
import { ORFIUM } from '../../../../../../../utils/constants';

interface Props {
  handlePreviewClick: any;
}
const XLSX = require('xlsx');

const GoalSettings: React.FC<Props> = ({ handlePreviewClick }) => {
  const classes = GoalSettingsStyle();
  const dispatch = useAppDispatch();

  const userData = useAppSelector((state) => state.groupDetails.data?.goals);
  const loading = useAppSelector((state) => state.groupDetails.loading);
  /** If there is no task */
  const noData = false;
  const grpData = useAppSelector((state) => state.groupDetails.data);
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);

  /** Levels Modal */
  const [Levels, setOpenLevels] = React.useState(false);
  const handleClickLevels = () => {
    setOpenLevels(true);
  };
  const handleCloseLevels = () => {
    setOpenLevels(false);
  };
  const mentorRoleText = GroupBasedRoleFormatter('mentor', 'Mentor'); // Mentor
  const mentorRoleTextLowerCase = mentorRoleText.toLowerCase(); // mentor
  const menteeRoleText = GroupBasedRoleFormatter('mentee', 'Mentee'); // Mentee

  /** Preview */
  const [open, setOpen] = React.useState(false);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setOpen(false);
  };

  /** Add popupcode */
  const [openAdd, setOpenAdd] = React.useState(false);
  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  //* * Action preview */
  const [openPreview, setOpenPreview] = React.useState(false);
  const handleClickOpenPreview = () => {
    setOpenPreview(true);
  };
  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  /** Edit */
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenPreview(false);
  };

  /** Delete popup code */
  // const [openDelete, setOpenDelete] = React.useState(false);
  const handleClickOpenDelete = () => {
    setOpenEdit(true);
  };
  const handleCloseDelete = () => {
    setOpenPreview(false);
  };
  const sampleGoalRef: any = useRef(null);

  /** Popover code */
  const [anchorElopenMore, setAnchorElopenMore] = React.useState<HTMLButtonElement | null>(null);
  const handleClickMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElopenMore(event.currentTarget);
  };
  const handleCloseMore = () => {
    setAnchorElopenMore(null);
  };
  const openMore = Boolean(anchorElopenMore);
  const idMore = openMore ? 'simple-popover' : undefined;

  /** Custom program btn */
  const [anchorElCustomProgram, setAnchorElCustomProgram] = React.useState<HTMLButtonElement | null>(null);
  const handleClickCustomProgram = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElCustomProgram(event.currentTarget);
  };
  const handleCloseCustomProgram = () => {
    setAnchorElCustomProgram(null);
  };
  const customProgram = Boolean(anchorElCustomProgram);
  const idCustomProgram = customProgram ? 'simple-popover' : undefined;
  const templates = useAppSelector((state) => state.goalsTemplates.data);

  /** Fields options */
  const options = ['label 1', 'label 2'];
  const ProgramKPIsOptions = ['Retained', 'Employed', 'Achieved personal goal'];
  const MenteeAnswersTypes = [
    '1 - 5 Rating',
    'List of Industries',
    'List of Roles',
    'Yes, No',
    'Yes, No, I Don’t Know',
    'Add Answer Manually',
    'Open inout fields'
  ];

  // Upload Goal Settings csv file upload code

  const [openCsvFileUploader, setOpenCsvFileUploader] = React.useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [goalfileName, setGoalFileName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [bulkUserLoading, setBulkUserLoading] = useState(false);
  // const [goalCsvFile, setGoalCsvFile] = useState<File | null>(null);
  const [tasksData, setTasksData] = useState<ITaskData | undefined>(undefined);
  const grpId = useParams().id || '';
  const orgId = useParams().orgId || '';
  const [tasksLoading, setTaskLoaidng] = useState(false);
  const templateLoading = useAppSelector((state) => state.goalsTemplates.loading);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleClickOpenCsvFileUploader = () => {
    setOpenCsvFileUploader(true);
  };

  const handleCloseCsvFileUploader = () => {
    setOpenCsvFileUploader(false);
    setGoalFileName('');
  };

  const handleGoalFileChanges = (event: ChangeEvent<HTMLInputElement>) => {
    const selectFile = event.target.files?.[0];

    if (selectFile) {
      handleFileInputChange(selectFile);
    }
  };

  const handleDragEnter: React.DragEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      // setIsHovering(true);
    } else if (event.type === 'dragleave') {
      // setIsHovering(false);
    }
  };

  const handleDrop: React.DragEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const selectFile = event.dataTransfer.files;
    if (selectFile?.[0]) {
      handleFileInputChange(selectFile[0]);
    }
  };

  const handleFileInputChange = (selectFile: File) => {
    // const maxFileSize = 50 * 1024; // 50kb file size

    if (selectFile?.name.endsWith('.xlsx')) {
      // if (selectFile.size > maxFileSize) {
      //   toast.error('File Size limit should not exceed 50kb.');
      //   return;
      // } else {
      //   setGoalFileName(selectFile.name);
      // }
      setGoalFileName(selectFile.name);
    } else {
      toast.error('Only xlsx files can be uploaded');
      return;
    }
  };

  const handlegoalSubmit = async () => {
    setBulkUserLoading(true);

    if (goalfileName) {
      const goalFormData = new FormData();
      goalFormData.append('goal', fileInputRef.current?.files?.[0] as File); // xlsx file accessed

      try {
        const response = await API.goalFilesUpload({
          data: goalFormData,
          orgId,
          grpId
        });

        if (response.status === 200) {
          toast.success(_.get(response, 'data.message', 'File uploaded successfully'));
          handleCloseCsvFileUploader();
          setBulkUserLoading(false);

          dispatch(grpDetailsActions.updateGroupInfo({ goals: response.data.goals }));
        } else {
          toast.error('File failed to upload');
        }
      } catch (error: any) {
        // toast.error(error.message);
        toast.error('Something went wrong, please try again');
        setBulkUserLoading(false);
      }
    } else {
      setErrorMessage('*Please Choose a File to Upload.');
      setBulkUserLoading(false);
      return;
    }
  };
  const handleDownload = () => {
    const dataSheet1 = [
      ['Goal Name', 'Program Type', 'Program KPIS'],
      ['Maneesha Template', 'MBA', 'Employed']
    ];

    const dataSheet2 = [
      ['Level No.', 'Level Name', 'Check In With ' + mentorRoleText],
      ['1', 'Understanding your goal', 'Yes'],
      ['2', 'Do the ground work', 'No']
    ];

    const dataSheet3 = [
      [
        'Level',
        'Task Position',
        'Task Description',
        'Task Name',
        mentorRoleText + ' Question',
        mentorRoleText + 'Mentor Answer Type',
        'Max ' + mentorRoleText + ' Answers',
        'Min ' + mentorRoleText + ' Answers',
        menteeRoleText + ' Question',
        menteeRoleText + ' Answer Type',
        'Max ' + menteeRoleText + ' Answers',
        'Min ' + menteeRoleText + ' Answers',
        menteeRoleText + ' Assessment Question',
        menteeRoleText + ' Assessment Answer Type',
        'Tags',
        'Library Articles'
      ],
      [
        '1',
        '1',
        '<p>Unlock proven, scalable development programs through your organizations resources. <a href="https://dosen.io/"><b>Dosen</b></a></p>',
        'Complete Onboarding',
        'Have finished your onboarding',
        '1-5 rating',
        '5',
        '1',
        'Have finished your onboarding',
        '1-5 rating',
        '5',
        '1',
        'Have you answered the above one??',
        'Yes, No',
        'company',
        '64ddb2cdb57b68e995c3da94, 64ddb31f00426fefb29aba81,64ddb2cdb57b68e995c3da94, 64ddcc6900426fefb29ac986'
      ],
      [
        '1',
        '2',
        '<p>Unlock proven, scalable development programs through your organizations resources. <a href="https://dosen.io/"><b>Dosen</b></a></p>',
        'Identify Industries you could work in',
        'Choose among the following?',
        'List of industries',
        '4',
        '2',
        'Choose among the following?',
        'List of industries',
        '4',
        '2',
        'Have you answered the above one??',
        'Yes, No, I dont know',
        'Industries',
        '64ddb2cdb57b68e995c3da94, 64ddb31f00426fefb29aba81'
      ],
      [
        '1',
        '3',
        ' ',
        'Identify desirable jobs within your industry',
        'Choose among the following?',
        'List of roles',
        '10',
        '5',
        'Choose among the following?',
        'List of roles',
        '10',
        '5',
        'Have you answered the above one??',
        'Yes, No',
        'Roles',
        '64ddcc6900426fefb29ac986, 64ddb2cdb57b68e995c3da94'
      ],
      [
        '1',
        '4',
        ' ',
        'Complete your experience assessment',
        'InputField',
        'Yes, No',
        ' ',
        ' ',
        'InputField',
        'Yes, No',
        ' ',
        ' ',
        'Have you answered the above one??',
        'Yes, No, I dont know',
        'Career',
        '64ddcc6900426fefb29ac986, 64ddb2cdb57b68e995c3da94, 64ddb31f00426fefb29aba81, 64ddccc56770fe93615a4461'
      ],
      [
        '2',
        '1',
        '….',
        'Update your open to action',
        'Have finished your open to action?',
        'Yes, No, I dont know',
        ' ',
        ' ',
        'Have finished your open to action?',
        'Yes, No, I dont know',
        ' ',
        ' ',
        'Have you answered the above one??',
        'Yes, No',
        'FYE'
      ],
      [
        '2',
        '2',
        ' ',
        'modify and finalize the selection',
        'Enter your hobbies',
        'Open input field',
        '5',
        '2',
        ' ',
        'Single Open input field',
        '5',
        '2',
        'Have you answered the above one??',
        'Yes,No I dont know',
        'Career'
      ],
      [
        '2',
        '3',
        ' ',
        'learn the new topics',
        ' ',
        'Be spoke answers',
        ' ',
        ' ',
        ' ',
        'Be spoke answers',
        ' ',
        ' ',
        'Rate your experience of Dosen?',
        '1-5 rating',
        'Career'
      ],
      [
        '3',
        '1',
        'nothing',
        'Complete Onboarding',
        'Have finished your onboarding',
        '1-5 rating',
        '5',
        '1',
        'Have finished your onboarding',
        '1-5 rating',
        '5',
        '1',
        'Have you answered the above one??',
        'Yes, No',
        'company',
        ' '
      ],
      [
        '3',
        '2',
        '……..',
        'Identify Industries you could work in',
        'Choose among the following?',
        'List of industries',
        '4',
        '2',
        'Choose among the following?',
        'List of industries',
        '4',
        '2',
        'Have you answered the above one??',
        'Yes, No, I dont know',
        'Industries',
        ' '
      ],
      [
        '3',
        '3',
        ' ',
        'Identify desirable jobs within your industry',
        'Choose among the following?',
        'List of roles',
        '10',
        '5',
        'Choose among the following?',
        'List of roles',
        '10',
        '5',
        'Have you answered the above one??',
        'Yes, No',
        'Roles',
        ' '
      ],
      [
        '3',
        '4',
        ' ',
        'Complete your experience assessment',
        'InputField',
        'Yes, No',
        ' ',
        ' ',
        'InputField',
        'Yes, No',
        ' ',
        ' ',
        'Have you answered the above one??',
        'Yes, No, I dont know',
        'Career',
        ' '
      ],
      [
        '4',
        '1',
        '….',
        'Update your open to action',
        'Have finished your open to action?',
        'Yes, No, I dont know',
        ' ',
        ' ',
        'Have finished your open to action?',
        'Yes, No, I dont know',
        ' ',
        ' ',
        'Have you answered the above one??',
        'Yes, No',
        'FYE',
        '  '
      ],
      [
        '4',
        '2',
        ' ',
        'modify and finalize the selection',
        'Enter your hobbies',
        'Open input field',
        '5',
        '2',
        ' ',
        'Single Open input field',
        '5',
        '2',
        'Have you answered the above one??',
        'Yes, No, I dont know',
        'Career',
        ' '
      ],
      [
        '4',
        '3',
        ' ',
        'learn the new topics',
        ' ',
        'Bespoke answers',
        ' ',
        ' ',
        ' ',
        'Bespoke answers',
        ' ',
        ' ',
        'Rate your experience of Dosen?',
        '1-5 rating',
        'Career',
        ' '
      ]
    ];

    const filename = 'Goals.xlsx';
    const workbook = XLSX.utils.book_new();

    const sheet1 = XLSX.utils.aoa_to_sheet(dataSheet1);
    const sheet2 = XLSX.utils.aoa_to_sheet(dataSheet2);
    const sheet3 = XLSX.utils.aoa_to_sheet(dataSheet3);

    XLSX.utils.book_append_sheet(workbook, sheet1, 'Goal');
    XLSX.utils.book_append_sheet(workbook, sheet2, 'Levels');
    XLSX.utils.book_append_sheet(workbook, sheet3, 'Tasks');

    XLSX.writeFile(workbook, filename);
  };
  const [selectedTemplate, setSelectedTemplate] = useState<string>(grpData?.goals.programType || '');
  const [planName, setPlanName] = useState(grpData?.goals.goalName || '');
  const [error, setError] = useState(false);
  const handleEditClick = ({ task, levelId }: any) => {
    if (!planName.trim() || error) {
      return setError(true);
    }
    localStorage.setItem('task', JSON.stringify({ goalName: planName, programType: selectedTemplate }));
    handlePreviewClick({ task, levelId, goalName: planName });
  };

  const submitTasks = async () => {
    if (tasksData?.levels) {
      const payload =
        tasksData?.levels.map((level, index) => {
          const tasks = level.tasks.map((each, taskIndex) => {
            return {
              taskName: each.taskName,
              taskDescription: each.taskDescription,
              menteeAnsType: each.menteeAnsType,
              menteeQues: each.menteeQues,
              tags: each.tags,
              taskPosition: taskIndex + 1,
              menteeAsmtQues: 'Have you completed this task?',
              menteeAsmtAnsType: 'Yes, No',
              menteeAsmtAnsOptions: ['Yes', 'No'],
              libraryArticles: each?.libraryArticles || []
            };
          });
          return {
            levelName: level.levelName,
            level: index + 1,
            CheckInWithMentor: 'no',
            tasks
          };
        }) || [];
      try {
        const response = await API.saveGroupTasks({
          data: { goalName: planName, programType: selectedTemplate, levels: payload },
          grpId,
          location,
          orgId
        });
        if (response.status) {
          await publishTasks();
        }
      } catch (e) {
        toast.error('Task failed to save');
      }
    }
  };
  const [openDelete, setOpenDelete] = React.useState(false);

  const publishTasks = async () => {
    if (error || planName === '' || selectedTemplate === '') {
      setError(true);
      return;
    }
    const response = await API.publishTask({ location, grpId, orgId });
    if (response.status === 200) {
      dispatch(grpDetailsActions.updatePublishKey(true));
      toast.success('Tasks published successfully');
    } else {
      toast.error('Task failed to publish');
    }
  };

  const handleApis = async () => {
    try {
      setSubmitLoading(true);
      if (grpData?.goals.templateId) {
        publishTasks();
      } else {
        submitTasks();
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', 'Task failed to save'));
    } finally {
      setSubmitLoading(false);
      setOpenDelete(false);
    }
  };

  const handlePublish = () => {
    if (error || planName === '' || selectedTemplate === '') {
      setError(true);
    }
    if (tasksData?.levels) {
      // let taskObj: IAdminTask = {};
      // let levelId = '';
      let error = false;
      const newLevels = tasksData?.levels.map((level) => {
        const newTasks = level.tasks.map((task) => {
          const isValid = (task.menteeAnsType && task.menteeQues) || (!task.menteeAnsType && !task.menteeQues);
          if (!task.taskName || !isValid || !task.taskDescription?.trim() || task?.tags?.length === 0) {
            error = true;
            // taskObj = task;
            // levelId = level._id;
            return { ...task, error: true };
          } else {
            return { ...task, error: false };
          }
        });
        return { ...level, tasks: newTasks };
      });
      if (error) {
        toast.error(`Please fill all the fields`);
        setTasksData({ ...tasksData, levels: newLevels });
      } else {
        setOpenDelete(true);
      }
    }
  };
  useEffect(() => {
    if (grpData?.goals.templateId) {
      setSelectedTemplate(grpData?.goals.programType || '');
      setPlanName(grpData?.goals.goalName || '');
    }
  }, [grpData]);
  /** Mentee answers section */
  const MenteeAnswers = () => {
    const answers = ['Answer 1', 'Answer 2', 'Answer 3']; // Replace with your actual answers array
    const totalAnswers = answers.length;
    const showDivider = totalAnswers > 1;

    return (
      <>
        {answers.map((answer, index) => (
          <React.Fragment key={index}>
            <Stack direction="row" spacing={1} py={2}>
              <DragHandleIcon sx={{ color: '#ABB5BE' }} />
              <Stack width="100%">
                <Typography fontWeight="600" color="#ABB5BE" marginBottom="16px">
                  Number {index + 1}
                </Typography>
                <Box>
                  <InputLabel>Answer</InputLabel>
                  <TextField fullWidth placeholder={answer} />
                </Box>
              </Stack>
            </Stack>
            {showDivider && index !== totalAnswers - 1 && <Divider />}
          </React.Fragment>
        ))}
      </>
    );
  };

  useEffect(() => {
    dispatch(fetchAllTemplates(grpId));
  }, []);

  const getTasksByProgramType = async (template: string) => {
    // clears previous tasks data, reolves issue where default is previous seleced task
    dispatch(adminTaskActions.updateTasks(null));
    if (template && template !== 'Custom' && !grpData?.goals.templateId) {
      setTaskLoaidng(true);
      try {
        const response = await API.getTemplateByType({ programType: template, location });
        if (response.status === 200) {
          setTasksData(response.data.templates);
          dispatch(adminTaskActions.updateTasks(response.data.templates));
        }
      } catch (e) {
      } finally {
        setTaskLoaidng(false);
      }
    }
  };

  // useEffect(() => {
  //   getTasksByProgramType();
  // }, [selectedTemplate]);
  const { location } = getUserDetails();

  let staticTemplates = [{ id: 'Custom', programType: 'Custom' }];
  if (oid === ORFIUM) {
    staticTemplates = [
      ...staticTemplates,
      { id: 'Onboarding', programType: 'Onboarding' },
      { id: 'Management Training', programType: 'Management Training' },
      { id: 'Leadership', programType: 'Leadership' },
      { id: 'DEI Training', programType: 'DEI Training' },
      { id: 'Offboarding', programType: 'Offboarding' }
    ];
  }

  const getTemplateByTemplateId = async () => {
    const response = await API.getTemplateByTemplateId({
      templateId: grpData?.goals.templateId || '',
      location,
      grpId,
      orgId
    });
    if (response.status === 200) {
      setTasksData(response.data.data);
      dispatch(adminTaskActions.updateTasks(response.data.data));
    }
  };

  useEffect(() => {
    dispatch(fetchAllArticles({ orgId: orgId, groupId: grpId, helpSettings: false, useCache: true, role: 'mentee' }));
  });

  useEffect(() => {
    if (grpData?.goals.templateId) {
      getTemplateByTemplateId();
    }
  }, []);

  return (
    <>
      {tasksLoading || templateLoading ? (
        <GoalSettingsLoader />
      ) : (
        <Box height="100%" bgcolor="#FFFFFF" overflow="hidden auto" borderRadius="8px" border="1px solid #EFF0F4">
          <Stack direction="row" justifyContent="space-between" alignItems="center" px={2}>
            <Typography variant="h5" py={2}>
              Goal Settings
            </Typography>
            {/* Create Custom program btn */}
            {/* <Button
            className={classes.createButton}
            aria-describedby={idCustomProgram}
            onClick={handleClickCustomProgram}
          >
            Create Custom program
          </Button>
          <Popover
            id={idCustomProgram}
            open={customProgram}
            anchorEl={anchorElCustomProgram}
            onClose={handleCloseCustomProgram}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Typography px={2} py={1} className="cursor-pointer"
              // onClick={() => dispatch(customProgramActions.updateCustomProgramFlag(true))}
            >
              Use Blank template
            </Typography>
            <Typography px={2} py={1} className="cursor-pointer">Use existing Programs</Typography>
          </Popover> */}
          </Stack>
          <Divider />
          {/* Old design */}
          {/* <Box p={2}>
          {userData?.goalName ? (
            <Typography>{userData?.goalName}</Typography>
          ) : (
            <Box textAlign="center">
              <Button color="primary" variant="contained" className='h-50px' onClick={handleClickOpenCsvFileUploader}>
                Upload Goal Settings
              </Button>
            </Box>
          )}
        </Box> */}

          {/* New design */}
          <Box p={2}>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item lg={4} md={6} xs={12}>
                <InputLabel>Development Plan Template</InputLabel>
                {templates && (
                  <Autocomplete
                    options={[...templates, ...staticTemplates]?.map((each) => each.programType) || []}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <TextField {...params} placeholder="Development Plan Template" />}
                    value={selectedTemplate}
                    onChange={(event, newValue) => {
                      setSelectedTemplate(newValue);
                      getTasksByProgramType(newValue);
                      setTasksData(undefined);
                    }}
                    disableClearable
                    disabled={grpData?.goals.templateId ? true : false}
                  />
                )}
              </Grid>
              <Grid item lg={8} md={6} xs={12}>
                <InputLabel>Development Plan Name *</InputLabel>
                <TextField
                  value={planName}
                  onChange={(e) => {
                    setPlanName(e.target.value);
                    if (e.target.value.trim() === '') {
                      setError(true);
                    } else {
                      setError(false);
                    }
                  }}
                  disabled={grpData?.goals.templateId ? true : false}
                />
                {error && <FormHelperText error>Plan name is required *</FormHelperText>}
              </Grid>
            </Grid>
          </Box>
          <Divider />

          <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" px={2}>
              <Typography variant="h5" py={2}>
                Task List
              </Typography>
              {tasksData && (
                <IconButton onClick={() => handleEditClick({ task: undefined, levelId: '', goalName: planName })}>
                  <EditIcon />
                </IconButton>
              )}
            </Stack>

            <Divider />
            <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer className="b-none">
                <Table width="100%">
                  <TableHead>
                    <TableRow>
                      <TableCell>LEVEL</TableCell>
                      <TableCell>LEVEL NAME</TableCell>
                      <TableCell align="center">TASK POSITION</TableCell>
                      <TableCell>TASK NAME</TableCell>
                      <TableCell width="0%" sx={{ p: '0' }} />
                      {/* <TableCell align="center">ACTION</TableCell> */}
                    </TableRow>
                  </TableHead>
                  {tasksData && (
                    <TableBody>
                      {tasksData.levels.map((level: any, levelIndex: number) => {
                        return level.tasks.map((task: any, taskIndex: number) => {
                          return (
                            <TableRow key={level._id}>
                              <TableCell className={classes.tableText}>{levelIndex + 1}</TableCell>
                              <TableCell className={classes.tableText}>
                                <Tooltip title={level.levelName}>{truncateText(level.levelName, 25)}</Tooltip>
                              </TableCell>
                              <TableCell className={classes.tableText} align="center">
                                {taskIndex + 1}
                              </TableCell>
                              <TableCell
                                className={`${classes.tableText} cursor-pointer`}
                                sx={{ color: '#0071A9 !important' }}
                                onClick={() => handleEditClick({ task, levelId: level._id })}
                              >
                                <Tooltip title={task.taskName}>{truncateText(task.taskName, 50)}</Tooltip>
                                {task.error && (
                                  <IconButton style={{ marginLeft: 'auto' }}>
                                    <ErrorOutlineIcon fontSize="small" color="error" />
                                  </IconButton>
                                )}
                              </TableCell>
                              <TableCell width="0%" sx={{ p: '0' }} />
                              {/* <TableCell>
                                <IconButton onClick={handleClickMore}>
                                  <MoreVertIcon color="primary" />
                                </IconButton>
                              </TableCell> */}
                              {/* <Popover
                                id={idMore}
                                open={openMore}
                                anchorEl={anchorElopenMore}
                                onClose={handleCloseMore}
                                anchorOrigin={{
                                  vertical: 'center',
                                  horizontal: 'left'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                  <Box
                                   className="PopoverListStyle"
                                   //onClick={handleClickOpenPreview}
                                 >
                                   <PreviewIcon />
                                  <Typography>Preview</Typography>
                                 </Box> 
                                <Box className="PopoverListStyle" onClick={handleClickOpenEdit}>
                                  <EditIcon />
                                  <Typography>Edit</Typography>
                                </Box>
                                 <Box
                                   className="PopoverListStyle"
                                   //onClick={handleClickOpenDelete}
                                 >
                                  <DeleteIcon />
                                   <Typography>Delete</Typography>
                                 </Box> 
                              </Popover> */}
                            </TableRow>
                          );
                        });
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Paper>
            {tasksData && (
              <Stack
                p="16px"
                spacing={2}
                direction="row"
                textAlign="right"
                alignItems="center"
                justifyContent="flex-end"
              >
                {/* <Typography variant="caption" color="error" className="cursor-pointer">
                  Delete Program
                </Typography> */}
                <LoadingButton
                  color="primary"
                  variant="contained"
                  className="w-150px h-50px"
                  onClick={handlePublish}
                  disabled={grpData?.goalPublished || false}
                >
                  Publish
                </LoadingButton>
              </Stack>
            )}
          </Box>

          {selectedTemplate === 'Custom' && !grpData?.goals.templateId && (
            <Box
              height="50%"
              display="flex"
              textAlign="center"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <Button
                color="primary"
                variant="contained"
                sx={{ height: '50px', width: '146px' }}
                onClick={() => {
                  dispatch(
                    adminTaskActions.updateTasks({ goalName: planName, programType: selectedTemplate, levels: [] })
                  );
                  handleEditClick({ task: undefined, levelId: '', goalName: planName });
                }}
              >
                Create Plan
              </Button>
            </Box>
          )}

          {!selectedTemplate && (
            <Box
              height="50%"
              display="flex"
              textAlign="center"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              {!selectedTemplate && (
                <Typography pb={2}>
                  Please select any template
                  <br />
                  You can also select Cutom to create your own template
                </Typography>
              )}

              {selectedTemplate === 'Custom' && (
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ height: '50px', width: '146px' }}
                  onClick={() => {
                    dispatch(
                      adminTaskActions.updateTasks({ goalName: planName, programType: selectedTemplate, levels: [] })
                    );
                    handleEditClick({ task: undefined, levelId: '', goalName: planName });
                  }}
                >
                  Create Plan
                </Button>
              )}
            </Box>
          )}
        </Box>
      )}

      <ProgramDeletDialog
        openDelete={openDelete}
        deleteLoader={submitLoading}
        handleDelete={handleApis}
        setDelete={setOpenDelete}
        text="Publishing will update all members plans now"
        btnText="Publish"
      />

      {/* csv file upload dialog */}
      <Dialog open={openCsvFileUploader} onClose={handleCloseCsvFileUploader} fullWidth>
        <CommonModalHeader title="Upload Goal settings" handleCloseModal={handleCloseCsvFileUploader} />
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component="div">
            <Typography>
              In order to upload goal settings, please download the template file to proceed.
              <br />
              First name, last name, email address, and role are required.
            </Typography>
            <Box
              py={1}
              px={0}
              gap={0.5}
              display="flex"
              alignItems="center"
              onClick={() => sampleGoalRef.current?.click()}
            >
              <img width="20px" height="20px" src={downloadblue} alt="downloadblueimg" className="cursor-pointer" />
              <Link
                download
                color="#0071A9"
                fontWeight="600"
                ref={sampleGoalRef}
                href={require('../../../../../../../assets/files/samplegoal.xlsx')}
              >
                Download Goal settings Template
              </Link>
            </Box>

            <Box
              mx={0}
              my={1.5}
              p="5px 46px 15px"
              bgcolor="#FFFFFF"
              borderRadius="8px"
              border="1px dashed #DEDFDF"
              className="cursor-pointer"
              onClick={handleButtonClick}
            >
              <Box textAlign="center" onDragEnter={handleDragEnter} onDragOver={handleDragEnter} onDrop={handleDrop}>
                <IconButton>
                  <img src={upload} alt="upload" width="25px" height="25px" />
                </IconButton>
                <input
                  ref={fileInputRef}
                  type="file"
                  className="d-none"
                  accept=".xlsx"
                  placeholder="file"
                  onChange={handleGoalFileChanges}
                />
                <Box fontWeight={600} fontSize="12px" color="#999999" fontFamily="Open Sans">
                  <span className="color-blue"> Click to upload </span>
                  or drag
                  <br />
                  Excel file only (max. 50 KB)
                  <br />
                  {goalfileName ? (
                    <label className="fs-20">{goalfileName}</label>
                  ) : (
                    <>
                      <label className="color-blue text-center">{errorMessage}</label>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            onClick={handlegoalSubmit}
            loading={bulkUserLoading}
          >
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Levels */}
      <Dialog open={Levels} onClose={handleCloseLevels} fullWidth>
        <CommonModalHeader title="Task Preview" handleCloseModal={handleCloseLevels} />
        <DialogContent>
          <LevelsDialog />
        </DialogContent>
        <DialogActions>
          <Button> Save </Button>
        </DialogActions>
      </Dialog>

      {/* Task Preview modal */}
      <Dialog open={open} onClose={handleClose} fullWidth>
        <CommonModalHeader title="Task Preview" handleCloseModal={handleClose} />
        <DialogContent>
          <Box pb={1.5}>
            <Typography fontWeight="700" color="#ABB5BE">
              Level 1
            </Typography>
            <Typography fontSize="16px" fontWeight={600} color="#000000">
              Understanding your goal
            </Typography>
          </Box>
          <Divider />
          <Typography variant="h3" my={1.5}>
            Identifying industries you could work in
          </Typography>
          <Typography color="#6C757D">
            Identifying a list of targeted industries that interest you will help you land a job you love by focusing
            your search on the aspects of a job that matters to you.
          </Typography>
          <Typography mx={0} my={1.5} color="#6C757D">
            Use the experience of your {mentorRoleTextLowerCase} and others in your community, in addition to the
            curated content below, to identify the industries that feel right for you.
          </Typography>
        </DialogContent>
      </Dialog>

      {/* Add Goal modal */}
      <Dialog open={openAdd} onClose={handleCloseAdd} fullWidth>
        <CommonModalHeader title="Add Task" handleCloseModal={handleCloseAdd} />
        <DialogContent>
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid item xs={12} sm={6}>
              <InputLabel>Level *</InputLabel>
              <Autocomplete disablePortal options={options} renderInput={(params) => <TextField {...params} />} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel>Level Name *</InputLabel>
              <Autocomplete disablePortal options={options} renderInput={(params) => <TextField {...params} />} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel>Task Position *</InputLabel>
              <TextField fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel>Tags *</InputLabel>
              <Autocomplete disablePortal options={options} renderInput={(params) => <TextField {...params} />} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Task Name *</InputLabel>
              <TextField fullWidth />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Task Description *</InputLabel>
              <TextField fullWidth multiline rows={2} maxRows={3} placeholder="Write Description about task" />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{menteeRoleText} Question</InputLabel>
              <TextField fullWidth />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{menteeRoleText} Answers Type</InputLabel>
              <Autocomplete
                disablePortal
                options={MenteeAnswersTypes}
                renderInput={(params) => <TextField {...params} placeholder="Select" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel>How many answer choices should display</InputLabel>
              <TextField fullWidth type="number" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel>Maximum number of selections</InputLabel>
              <TextField fullWidth type="number" />
            </Grid>

            {/* Mentee Answers */}
            <Grid item xs={12}>
              <Box px={2} bgcolor="#F9FAFC" borderRadius="8px">
                <MenteeAnswers />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>How many answer choices should display</InputLabel>
              <TextField fullWidth />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel control={<Checkbox />} label={`No ${mentorRoleText} Question`} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{mentorRoleText} Question</InputLabel>
              <Autocomplete disablePortal options={options} renderInput={(params) => <TextField {...params} />} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{mentorRoleText} Answers</InputLabel>
              <Autocomplete disablePortal options={options} renderInput={(params) => <TextField {...params} />} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>{mentorRoleText} Answer - No. of Min. Selections</InputLabel>
              <Autocomplete disablePortal options={options} renderInput={(params) => <TextField {...params} />} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>{mentorRoleText} Answer - No. of Max. Selections</InputLabel>
              <Autocomplete disablePortal options={options} renderInput={(params) => <TextField {...params} />} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" fullWidth>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Task Preview modal */}
      <Dialog open={openPreview} onClose={handleClosePreview} fullWidth>
        <CommonModalHeader title="Task Preview" handleCloseModal={handleClose} />
        <DialogContent>
          <Box sx={{ paddingBottom: '15px' }} pb={1.5}>
            <Typography fontWeight="700" color="#ABB5BE">
              Level 1
            </Typography>
            <Typography fontSize="16px" fontWeight="600" color="#000000">
              Understanding your goal
            </Typography>
          </Box>
          <Divider />
          <Typography variant="h3" my={1.5}>
            Identifying industries you could work in
          </Typography>
          <Typography color="#6C757D">
            Identifying a list of targeted industries that interest you will help you land a job you love by focusing
            your search on the aspects of a job that matters to you.
          </Typography>
          <Typography color="#6C757D" my={1.5} mx={0}>
            Use the experience of your {mentorRoleTextLowerCase} and others in your community, in addition to the
            curated content below, to identify the industries that feel right for you.
          </Typography>
        </DialogContent>
      </Dialog>

      {/* Edit Goal modal */}
      <Dialog open={openEdit} onClose={handleCloseEdit} fullWidth>
        <CommonModalHeader title="Edit Goal" handleCloseModal={handleClose} />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} sm={6}>
              <InputLabel>Level *</InputLabel>
              <Select id="levelselect" autoWidth placeholder="Member type">
                <MenuItem>Member type</MenuItem>
                <MenuItem>Member type</MenuItem>
              </Select>
            </Grid>
            <Grid item lg={6} md={6} sm={6}>
              <InputLabel>Task Position *</InputLabel>
              <Select id="task-position-select" autoWidth placeholder="Member type">
                <MenuItem>Member type</MenuItem>
                <MenuItem>Member type</MenuItem>
              </Select>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <InputLabel>Level Name *</InputLabel>
              <TextField fullWidth variant="outlined" size="small" placeholder="Understanding your goal" />
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <InputLabel>Task Name *</InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Identifying industries you could work in"
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12}>
              <InputLabel>Task Description *</InputLabel>

              <Box
                color="#ABB5BE"
                height="140px"
                fontWeight="400"
                fontSize="14px"
                borderRadius="8px"
                border="1px solid #ABB5BE"
              >
                <Typography color="#ABB5BE" p="15px">
                  Identifying a list of targeted industries that interest you will help you land a job you love by
                  focusing your search on the aspects of a job that matters to you. <br />
                  <br /> Use the experience of your {mentorRoleTextLowerCase} and others in your community, in addition
                  to the curated content below, to identify the industries that feel right for you.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" fullWidth>
            Remove
          </Button>
          <Button color="primary" variant="contained" fullWidth>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation v */}
      <Dialog open={openEdit} onClose={handleCloseDelete} fullWidth>
        <CommonModalHeader title="Confirmation" handleCloseModal={handleCloseDelete} />
        <DialogContent>
          <Typography variant="h5" textAlign="center">
            Are you sure to remove <br /> this task ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" fullWidth>
            No
          </Button>
          <Button color="primary" variant="contained" fullWidth>
            Yes, Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GoalSettings;
