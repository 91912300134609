import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import ReviewTable from './ReviewTable';
import EngagedTable from './EngagedTable';
import Valuation from '../../../home/cards/Valuation';
import UsageSummary from '../../../home/cards/UsageSummary';
import { showMyLoader } from '../../../../components/common';
import Accessibility from '../../../home/cards/Accessibility';
// import FeelConnected from '../../../home/cards/FeelConnected';
import CommonFrequency from '../../../home/cards/CommonFrequency';
import SatisfactionRating from '../../../home/cards/SatisfactionRating';
import { AdvisorCardsLoader, EngagedTableLoader, ReviewTableLoader } from '../../../../../../Loader/AdvisorLoader';
import { API } from '../../../../../../api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';

export interface IRatingData {
  reviews: { name: string; headshot: string; review: string; createdAt: number }[];
  totalRatings: number;
  ratings: {
    '5stars': number;
    '4stars': number;
    '3stars': number;
    '2stars': number;
    '1stars': number;
  };
  averageRating: number;
}

const UserInsightsCards = ({
  tabName,
  metricsValue,
  currencySymbol
}: {
  tabName?: any;
  metricsValue?: any;
  currencySymbol?: any;
}) => {
  /** custom progress data */
  const customProgressData = [80, 60, 40, 20, 10];
  // const loading = showMyLoader();
  const grpId = useParams().id || '';
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<IRatingData>();

  /** Review */
  const reviews = [
    {
      name: 'Rob Ross',
      avatarSrc: '',
      comment: 'Best call ever, getting all the information in a very super way, all are with nice hearts.',
      date: '2 Days ago'
    },
    {
      name: 'Raj Koley',
      avatarSrc: '',
      comment: 'Best call ever, getting all the information in a very super way, all are with nice hearts.',
      date: '1 Days ago'
    }
  ];

  /** Engaged Table */
  const engagedData = [
    { name: 'Rob Ross', department: 'Computer Science', userImage: '', likes: 225 },
    { name: 'Raj', department: 'Computer Science', userImage: '', likes: 225 }
  ];

  const getReviews = async () => {
    try {
      setLoading(true);
      const response = await API.getReviews({ grpId, category: tabName });
      if (response.status === 200) {
        setData(response.data.data);
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Insights failed to display`));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReviews();
  }, [tabName]);
  console.log(tabName);
  return (
    <>
      <Grid container spacing={2}>
        {loading ? (
          [...Array(3).keys()].map((_, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <AdvisorCardsLoader minHeight="298px" />
            </Grid>
          ))
        ) : (
          <>
            <Grid item xs={12} sm={4} md={4}>
              <UsageSummary tabName={tabName} metricsValue={metricsValue} />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <CommonFrequency
                heading="Feel connected with organization"
                value="69%"
                respondents="117 Respondents"
                minHeight={'298px'}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <SatisfactionRating data={data} />
            </Grid>
          </>
        )}
      </Grid>

      <Grid container spacing={2} pt={2}>
        {loading ? (
          [...Array(3).keys()].map((_, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <AdvisorCardsLoader minHeight="211px" />
            </Grid>
          ))
        ) : (
          <>
            <Grid item xs={12} sm={4} md={4}>
              <CommonFrequency heading="Revenue" value={`${currencySymbol}50K`} minHeight={'211px'} />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <CommonFrequency heading="Funding" value={`${currencySymbol}65K`} minHeight={'211px'} />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Valuation heading="Valuation" progressValue={86} />
            </Grid>
          </>
        )}
      </Grid>

      <Grid container spacing={2} pt={2}>
        {loading ? (
          [...Array(4).keys()].map((_, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <AdvisorCardsLoader minHeight="450px" />
            </Grid>
          ))
        ) : (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <Accessibility heading="Accessibility" />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Accessibility heading="Gender" />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Accessibility heading="1st Generation" />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Accessibility heading="Ethnicity" />
            </Grid>
          </>
        )}
      </Grid>

      <Grid container spacing={2} pt={2}>
        <Grid item xs={12}>
          {loading ? <ReviewTableLoader /> : <ReviewTable reviews={data?.reviews || []} />}
        </Grid>
      </Grid>

      <Grid container spacing={2} pt={2}>
        {loading ? (
          [...Array(2).keys()].map((_, index) => (
            <Grid item xs={12} sm={12} md={6} key={index}>
              <EngagedTableLoader />
            </Grid>
          ))
        ) : (
          <>
            <Grid item xs={12} sm={12} md={6}>
              <EngagedTable heading="Most Engaged" engagedData={engagedData} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <EngagedTable heading="Least Engaged" engagedData={engagedData} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default UserInsightsCards;
