/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Stack, Dialog, TextField, Typography, DialogActions, DialogContent } from '@mui/material';
import _ from 'lodash';
// import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { API } from '../../../../../api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getUserDetails } from '../../../../../utils/orgName';
import { acsChannelActions } from '../../../../../redux/slices/acs-channels/acsChannels';
import ChatAvatarComponent from '../ChatAvatarComponent';
import { handleImageCompression } from '../../../components/common';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
// import ChatAvatarComponent from "./ChatAvatarComponent";

interface Props {
  openChatInfoForm: boolean;
  handleChatInfoForm: (flag: boolean) => void;
  channelName: string;
  threadId: string;
  removeParticipantFromThread: any;
  groupChatInfo: any;
}
const MB = 1000000;
const requiredSize = 10 * MB;

const checkImageSize = (image: any) => {
  if (_.size(image)) {
    const { size } = image[0];
    if (size < requiredSize) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const ChatThreadDetails = ({
  openChatInfoForm,
  handleChatInfoForm,
  channelName,
  threadId,
  removeParticipantFromThread,
  groupChatInfo
}: Props) => {
  const [openChatNameEdit, setChatNameEdit] = useState(false);
  const [imgProfileGroup, setImgProfileGroup] = useState();
  const [loading, setLoading] = useState(false);
  const acsToken = useAppSelector((state) => state?.acsToken?.data?.token);
  const handleClose = () => {
    handleChatInfoForm(false);
    setValue('image', '');
    reset();
  };

  const allGroupChats = useAppSelector((state) => state.acsChannels.groupThreads) || [];
  const currentGroupChat = allGroupChats.find((each, idx) => each.id === threadId);
  const schema = yup
    .object({
      topic: yup.string().required('Group name is required').max(75, 'Max 75 characters'),
      image: yup
        .mixed()
        .test('fileSize', 'We cannot upload this file, please try another', (value) => {
          return !value || value[0]?.size <= 10 * 1024 * 1024;
        })
        // .required('Image is required') // 10 MB
        .test('filePresence', (value) => {
          const currentImageValue = currentGroupChat?.image;
          if (currentImageValue) {
            return true;
          }
          // Always return true, meaning no validation error will occur.
          return true;
        })
    })
    .required();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    setImgProfileGroup(groupChatInfo);
  }, [groupChatInfo]);

  useEffect(() => {
    setValue('topic', channelName);
  }, [groupChatInfo, openChatInfoForm]);

  // const updatedChatProfileImg = async (img: File) => {
  //   const tokens = localStorage.getItem("tokens")
  //     ? JSON.parse(localStorage.getItem("tokens") || "")
  //     : {};

  //   const formData = new FormData();
  //   formData.append("image", img);
  //   formData.append("threadId", threadId);

  //   const config = {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       orgId: orgId,
  //       Authorization: `Bearer ${tokens.access_token}`,
  //       idtoken: tokens.id_token,
  //       location: "us",
  //     },
  //   };

  //   try {
  //     const response = await axios.post(
  //       "https://dosen-v2.azurewebsites.net/api/groupChatPicUpload",
  //       formData,
  //       config
  //     );

  //     queryClient.fetchQuery({ queryKey: ["getAcsChatThreads"] });
  //     queryClient.fetchQuery({ queryKey: ["recieveMessages", threadId] });

  //     if (response.status === 200) {
  //       toast.success(response.data.message);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error(error);
  //   }
  // };
  const dispatch = useAppDispatch();

  const updateGroupName = async (newName: string) => {
    const { orgId } = getUserDetails();

    const response = await API.updateGroupChatName({
      orgId,
      acsToken,
      threadId,
      topic: newName
    });

    if (response.status === 200) {
      dispatch(
        acsChannelActions.updateGroupInfo({
          threadId,
          info: { topic: newName }
        })
      );
    }
  };

  const updateGroupImage = async (imageFile: any) => {
    const { orgId, location } = getUserDetails();
    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append('threadId', threadId);

    const response = await API.updateGroupPic({
      orgId,
      location,
      formData
    });
    if (!response) {
      toast.error('Only jpeg, png and gif files can be uploaded');
    }

    if (_.get(response, 'status') === 200) {
      const imageUrl = response?.data?.url;
      setImgProfileGroup(imageFile);
      dispatch(
        acsChannelActions.updateGroupInfo({
          threadId,
          info: { image: imageUrl }
        })
      );
    }
  };

  const onSubmit = async (values: any) => {
    setLoading(true);
    let hasChanges = false;

    try {
      if (channelName !== values?.topic) {
        await updateGroupName(values?.topic);
        hasChanges = true;
      }

      if (values?.image && values?.image?.length > 0) {
        const compressedImage = await handleImageCompression(values.image[0]);
        if (compressedImage) {
          await updateGroupImage(compressedImage);
        }
        hasChanges = true;
      }

      if (hasChanges) {
        setLoading(false);
        setValue('image', '');
        // setChatNameEdit(false);
        handleClose();
      } else {
        setLoading(false);
        toast.info('No changes were made');
      }
    } catch (err: any) {
      setLoading(false);
      toast.error(_.get(err, 'content.message', 'Something went wrong, please try again'));
    }
  };

  const ValidationError = ({ errors, fieldName }: { errors: Object; fieldName: string }) => (
    <Typography color="red" px={0.5} variant="subtitle2">
      {_.get(errors, `${fieldName}.message`, '')}
    </Typography>
  );

  const handleCloseEditForm = () => {
    setChatNameEdit(false);
    setValue('image', '');
    reset();
  };

  const handleOpenEditForm = () => {
    setValue('topic', channelName);
    setChatNameEdit(true);
  };
  const imageErrorMessage = errors['image']?.message;

  const editForm = () => (
    <Dialog open={openChatInfoForm} onClose={handleClose} fullWidth>
      <CommonModalHeader title={`Edit Group`} handleCloseModal={handleClose} />
      <DialogContent>
        <Box display="flex" flexDirection="column">
          {/* Group Image and Upload Image button */}
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box className="profileImageBox" style={{ position: 'relative' }}>
              <Controller
                name="image"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <input
                      type="file"
                      onChange={(e) => {
                        if (_.get(e, 'target.files.[0]')) {
                          onChange(e.target.files);
                        }
                      }}
                      accept="image/jpeg,image/jpg,image/png"
                      id="image-upload"
                      className="d-none"
                    />
                    <label htmlFor="image-upload" className="cursor-pointer">
                      <Box
                        className={
                          (value && value[0]) || currentGroupChat?.image
                            ? 'ProfileCameraBgOverlay'
                            : 'EdirProfileCameraIcon'
                        }
                      >
                        <CameraAltOutlinedIcon
                          className="IfNoIcon"
                          sx={{ color: (value && value[0]) || currentGroupChat?.image ? '#FFFFFF' : '#68717A' }}
                        />
                      </Box>
                      {value && value[0] ? (
                        <img className="profileImage" src={URL.createObjectURL(value[0])} alt="Group image" />
                      ) : currentGroupChat?.image ? (
                        <img className="profileImage" src={currentGroupChat.image} alt="Group image" />
                      ) : null}
                    </label>
                  </>
                )}
              />
            </Box>
            <Box>
              <Typography variant="h6">Group Image</Typography>
              <Typography className="miniText">
                jpg, png, svg or gif (max size 10MB, ideal dimensions 800x800 px)
              </Typography>
              {_.get(errors, 'image', '') ? <Typography color="red"></Typography> : null}
            </Box>
          </Stack>
          {/* Group Name */}
          <Box my={3}>
            <Typography>Group Name *</Typography>
            <Controller
              name="topic"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  size="small"
                  sx={{ marginTop: '10px' }}
                  fullWidth
                  autoFocus
                />
              )}
            />
            <ValidationError errors={errors} fieldName="topic" />
          </Box>
        </Box>
        {imageErrorMessage ? (
          <Box marginTop="30px">
            <ValidationError errors={{ error: { message: imageErrorMessage } }} fieldName="error" />
          </Box>
        ) : null}
        <DialogActions sx={{ p: 0 }}>
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            sx={{ textTransform: 'none', margin: 0 }}
            variant="contained"
            fullWidth
            loading={loading}
          >
            Save Changes
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );

  return editForm();

  // return (
  //   <div>
  //     <Dialog
  //       open={openChatInfoForm}
  //       onClose={handleClose}
  //       fullWidth
  //     >
  //       <DialogTitle
  //         display="flex"
  //         alignItems="center"
  //         justifyContent="space-between"
  //         borderBottom="1px solid lightgray"
  //       >
  //         {/* <Typography sx={{ fontSize: "25px", fontWeight: "bold" }}>
  //           {channelName}
  //         </Typography>

  //         <ChatAvatarComponent image={imgProfileGroup} /> */}
  //         <Box display="flex" alignItems="center" width="100%" sx={{ wordBreak: 'break-all' }}>
  //           {/* <Box width="50px" height="50px" borderRadius={"50%"}>
  //             <img
  //               src={imgProfileGroup}
  //               alt=""
  //               style={{
  //                 width: "50px",
  //                 height: "50px",
  //                 borderRadius: "50%",
  //                 objectFit: "cover",
  //               }}
  //             />
  //           </Box> */}
  //           <ChatAvatarComponent firstLetter={_.size(channelName) ? channelName[0] : ''} image={imgProfileGroup} />
  //           <Typography sx={{ fontSize: '25px', fontWeight: 'bold', marginLeft: '5%' }}>{channelName}</Typography>
  //         </Box>
  //         <IconButton onClick={handleClose}>
  //           <CloseIcon fontSize="small" />
  //         </IconButton>
  //       </DialogTitle>
  //       <DialogContent
  //         sx={{
  //           backgroundColor: '#fcfafa',
  //           paddingTop: '20px !important',
  //           paddingBottom: '30px !important'
  //         }}
  //       >
  //         <Box
  //           border="1px solid lightgray"
  //           padding={1.5}
  //           bgcolor="#fff"
  //           sx={{
  //             ':hover': { backgroundColor: '#f5f3f2' },
  //             cursor: 'pointer',
  //             borderTopRightRadius: '6px',
  //             borderTopLeftRadius: '6px'
  //           }}
  //           onClick={handleOpenEditForm}
  //         >
  //           <Box display="flex" alignItems="center" justifyContent="space-between">
  //             <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Channel name</Typography>
  //             <Button
  //               variant="text"
  //               sx={{
  //                 textTransform: 'none',
  //                 ':hover': {
  //                   boxShadow: 'none',
  //                   backgroundColor: 'transparent',
  //                   textDecoration: 'underline'
  //                 },
  //                 margin: '0px !important',
  //                 padding: '0px !important',
  //                 minWidth: 'fit-content'
  //               }}
  //             >
  //               Edit
  //             </Button>
  //           </Box>
  //           <Typography sx={{ wordBreak: 'break-all' }}>{channelName}</Typography>
  //         </Box>
  //         <Box
  //           border="1px solid lightgray"
  //           padding={1.5}
  //           bgcolor="#fff"
  //           sx={{
  //             ':hover': { backgroundColor: '#f5f3f2' },
  //             cursor: 'pointer',
  //             borderBottomRightRadius: '6px',
  //             borderBottomLeftRadius: '6px',
  //             borderTop: 'none'
  //           }}
  //           onClick={() => {
  //             removeParticipantFromThread();
  //             handleClose();
  //           }}
  //         >
  //           <Typography sx={{ fontSize: '14px', color: '#E01E5A' }}>Leave Channel</Typography>
  //         </Box>
  //       </DialogContent>
  //     </Dialog>
  //     {editForm()}
  //   </div>
  // );
};

export default ChatThreadDetails;
