/* eslint-disable radix */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Divider, IconButton, Typography, Grid, Stack } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { API } from '../../../api';
import { getUserDetails } from '../../../utils/orgName';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchUserWorkHistory, userWorkHistoryActions } from '../../../redux/slices/user-work/userWorkHistorySlice';
import _ from 'lodash';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import WorkAndEduDesign from './WorkAndEduDesign';
export interface IWorkHistory {
  company_name: string;
  roleType: string[];
  title: string;
  start_date: string | null;
  end_date: string | null;
  industry: string;
  currentlyWorking: boolean;
}
export interface IWorkHistoryError {
  company_name?: string;
  roleType?: string;
  title?: string;
  start_date?: string;
  end_date?: string | null;
  industry?: string;
  currentlyWorking?: boolean;
}

export interface endDateError {
  end_date?: string | null;
}

export interface startDateError {
  start_date?: string | null;
}

const initialValues: IWorkHistory = {
  company_name: '',
  roleType: [],
  title: '',
  start_date: null,
  end_date: null,
  industry: '',
  currentlyWorking: true
};

interface IWorkHistoryState {
  action: string;
  activeId: string;
  roleKeyword: string;
}

const initialWorkHistoryState: IWorkHistoryState = {
  action: '',
  activeId: '',
  roleKeyword: ''
};

const WorkHistory = () => {
  const dispatch = useAppDispatch();
  const { updatedData: data, error, loading, errorText } = useAppSelector((state) => state.userWorkHistory);
  const { userId, location } = getUserDetails();

  const [workHistoryState, setWorkHistoryState] = useState<IWorkHistoryState>(initialWorkHistoryState);

  const [values, setValues] = useState<IWorkHistory>(initialValues);
  const [dataInAscendingDate, setDataInAscendingDate] = useState<IWorkHistory[]>([initialValues]);
  useEffect(() => {
    const sortedData = _.sortBy(data, (each) => _.parseInt(each?.start_date));
    _.isArray(data) && !_.isEmpty(data) ? setDataInAscendingDate(sortedData) : setDataInAscendingDate([]);
  }, [data]);

  useEffect(() => {
    getWorkHistory();
  }, []);

  const handleClickOpenWorkHistory = () => {
    dispatch(popupActions.updatePopups({ workHistory: { open: true, action: 'Add', item: {} } }));
  };

  const handleEditWorkHistory = (item: IWorkHistory) => {
    dispatch(popupActions.updatePopups({ workHistory: { open: true, action: 'Edit', item } }));
  };

  const getWorkHistory = async () => {
    dispatch(fetchUserWorkHistory(userId));
    if (error) {
      toast.error('Work history failed to update');
    }
  };

  return (
    <Box>
      <Box
        px={2}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography variant="h5" py={2}>Work history</Typography>
        <IconButton
          size='small'
          onClick={() => {
            setWorkHistoryState({ ...workHistoryState, action: 'Add' });
            setValues(initialValues);
            handleClickOpenWorkHistory();
          }}
        >
          <AddCircleOutlineIcon color='info' />
        </IconButton>
      </Box>
      <Divider />
      <Box p={2}>
        <WorkAndEduDesign
          data={data}
          itemType="work"
          wherePrint='profile'
          onEdit={(item) => {
            if ('company_name' in item && 'roleType' in item) {
              setWorkHistoryState({ ...workHistoryState, action: 'Edit', activeId: item.company_name });
              setValues(item as IWorkHistory);
              handleEditWorkHistory(item as IWorkHistory);
            }
          }}
        />
      </Box>
    </Box>
  );
};


export default WorkHistory;
