/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { API } from '../../../../api';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
// import ContentFilter from '../Program/components/program tabs/ContentFilter';
import ContentList from './components/ContentList';
import AddEditContent from './components/AddEditContent';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { articleActions, fetchAllArticles } from '../../../../redux/slices/article/getAllArticleSlice';
import PreviewContent from '../Program/components/PreviewContent';
import ArticleDeletDialog from '../Program/components/ArticleDeletDialog';
import { FilterIcon, PlusCircleIcon } from '../../../../utils/Icons';
import { updateInsightDataByArticleId } from '../../components/common';
import MySkeleton from '../../../../components/Skeleton';
import HelpEdit from '../Program/components/program tabs/HelpEdit';

interface Article {
  articleId: string;
  orgId: string;
  groups: string[];
  articleName: string;
  type: string;
  coverImageUrl: string;
  fileUrl: string;
  authorName: string;
  authorId: string;
  createdBy?: string;
  readTime: string;
  accessFor: string;
  tags: string[];
  articleDescription: string;
  approved: boolean;
  upVotes: number;
  downVotes: number;
  views: number;
  isPublished: boolean;
  __v: number;
  createdAt: number;
}

const HelpSettings = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const grpId = params.id || '';
  const orgId = params.orgId || '';
  const [allData, setAllData] = useState<Article[]>([]);
  const allArticleData = useAppSelector((state) => state?.allArticle?.data?.ArticleList ?? []);
  useEffect(() => {
    if (allArticleData && allArticleData) {
      setAllData(allArticleData);
    }
  }, [allArticleData]);
  const loading = useAppSelector((state) => state?.allArticle?.loading);
  const error = useAppSelector((state) => state?.allArticle?.error);
  const [openForm, setOpenForm] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [singleListData, setSingleListData] = useState<any>();
  const [selectedActiveId, setSelectedActiveId] = useState<string>('');
  const [anchorElPopover, setAnchorElPopover] = useState<HTMLElement | null>(null);
  const [openDelete, setDelete] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [action, setAction] = useState('');
  // const [openFilter, setopenFilter] = useState(false);
  // const handleopenFilter = (flag: boolean) => setopenFilter(flag);

  useEffect(() => {
    if (orgId && grpId) {
      dispatch(fetchAllArticles({ orgId: orgId, groupId: grpId, helpSettings: true, useCache: false }));
    }
  }, [orgId, grpId, dispatch]);

  const handleClickOpenForm = () => {
    setOpenForm(true);
    setAction('Add');
  };

  const handleClickCloseForm = () => {
    setOpenForm(false);
  };

  const handleClickOnArticleName = async (articleId: string, location: any) => {
    setSingleListData(null);
    try {
      const response = await API.getAllArticleById({
        orgId: orgId,
        articleId: articleId,
        groupId: grpId,
        isContentSettings: true,
        articleLocation: location ? location : ''
      });

      if (response && response.status === 200) {
        setSingleListData(response.data.article);
        setOpenPreview(true);
      }
    } catch (error) {
      toast.error('Something went wrong, please try again');
    }
    updateInsightDataByArticleId(orgId, articleId, location);
  };

  const handleClickPreiew = async (location: any) => {
    setAnchorElPopover(null);
    try {
      const response = await API.getAllArticleById({
        orgId: orgId,
        articleId: selectedActiveId,
        groupId: grpId,
        isContentSettings: true,
        articleLocation: location ? location : ''
      });

      if (response && response.status === 200) {
        setSingleListData(response.data.article);
        setOpenPreview(true);
      }
    } catch (error) {
      toast.error('Something went wrong, please try again');
    }
    setOpenPreview(true);
    updateInsightDataByArticleId(orgId, selectedActiveId, location);
  };

  const handleOpendelete = () => {
    setAnchorElPopover(null);
    setDelete(true);
  };

  const handelDeleteArticle = async () => {
    setDeleteLoader(true);
    setAnchorElPopover(null);
    try {
      const response = await API.DeleteArticle({
        orgId: orgId,
        articleId: selectedActiveId,
        groupId: grpId
      });

      if (response.status === 200) {
        toast.success('Article deleted successfully');
        setDeleteLoader(false);
        setDelete(false);
        dispatch(articleActions.deleteArticle(selectedActiveId));
      } else {
        toast.error('Something went wrong, please try again');
        setDeleteLoader(false);
      }
    } catch (error) {
      setDeleteLoader(false);
      toast.error('Something went wrong, please try again');
    }
  };

  const handleClickEdit = async (location: any) => {
    setAction('Edit');
    setAnchorElPopover(null);
    setOpenForm(true);
    try {
      const response = await API.getAllArticleById({
        orgId: orgId,
        articleId: selectedActiveId,
        groupId: grpId,
        isContentSettings: true,
        articleLocation: location ? location : ''
      });

      if (response && response.status === 200) {
        let data = response?.data?.article;
        setSingleListData(data);
      }
    } catch (error) {}
    updateInsightDataByArticleId(orgId, selectedActiveId, location);
  };

  return (
    <Box
      height={'100%'}
      bgcolor={'#FFF'}
      borderRadius="8px"
      border="1px solid #EFF0F4"
      overflow={loading ? 'hidden' : 'auto'}
    >
      <Box px={2} py={0.5} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">{loading ? <MySkeleton width={150} /> : 'Help Center Settings'}</Typography>
        <Box display="flex" alignItems="center" justifyContent="flex-end" gap={'10px'}>
          {/* <IconButton onClick={() => handleopenFilter(true)}>
           <FilterIcon size={34} />
           </IconButton> */}
          <IconButton disabled={loading} onClick={handleClickOpenForm}>
            {loading ? <MySkeleton variant="circular" height={34} width={34} /> : <PlusCircleIcon size={34} />}
          </IconButton>
        </Box>
      </Box>

      {/* Content list popup */}
      <ContentList
        allData={allData}
        setAllData={setAllData}
        loading={loading}
        error={error}
        handleClickOnArticleName={handleClickOnArticleName}
        setSelectedActiveId={setSelectedActiveId}
        handleClickPreiew={handleClickPreiew}
        setAnchorElPopover={setAnchorElPopover}
        anchorElPopover={anchorElPopover}
        handleOpendelete={handleOpendelete}
        handleClickEdit={handleClickEdit}
      />

      {/* Add & Edit content popup */}
      {openForm ? (
        <HelpEdit
          handleClickCloseForm={handleClickCloseForm}
          openForm={openForm}
          setOpenForm={setOpenForm}
          selectedActiveId={selectedActiveId}
          singleListData={singleListData}
          action={action}
          setAction={setAction}
        />
      ) : null}

      {/* Preview content popup */}
      <PreviewContent openPreview={openPreview} setOpenPreview={setOpenPreview} singleListData={singleListData} />

      {/* Article delet popup */}
      <ArticleDeletDialog
        openDelete={openDelete}
        setDelete={setDelete}
        deleteLoader={deleteLoader}
        handleDelete={handelDeleteArticle}
      />

      {/* Filter */}
      {/* <ContentFilter
        loading={loading}
        openFilter={openFilter}
        handleopenFilter={handleopenFilter}
      /> */}
    </Box>
  );
};

export default HelpSettings;
