import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// import LinearProgress, {
//   linearProgressClasses,
// } from "@mui/material/LinearProgress";
// import { styled } from "@mui/material/styles";
import { Box, IconButton, Popover, TableCell, TableRow, Typography } from '@mui/material';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IGroupData, groupActions } from '../../../../../redux/slices/getAllGroups/getAllGroupsSlice';
import moment from 'moment';
import _ from 'lodash';
// import { alphaColors } from "../../../../../utils/alphabates-colors";
import ProgramDeletDialog from './ProgramDeletDialog';
import { API } from '../../../../../api';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { breadCrumbsActions } from '../../../../../redux/slices/breadcrumbs/breadcrumbsSlice';
import ROLES from '../../../../../utils/roles';
import ChatAvatarComponent from '../../chat/ChatAvatarComponent';
import { makeStyles } from '@mui/styles';
import { DeleteIcon, EditIcon } from '../../../../../utils/Icons';
import { fetchGroupDetails } from '../../../../../redux/slices/group-details/groupDetails';
import { getUserDetails } from '../../../../../utils/orgName';
import { groupLoaderActions } from '../../../../../redux/slices/groupLoaderSlice';
import { formatNumberWithCommas } from '../../../components/common';
interface Props {
  data: IGroupData;
}

const ProgramTableRow = ({ data }: Props) => {
  const navigate = useNavigate();
  const orgId = useParams().orgId || '';
  const [openDelete, setDelete] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const dispatch = useAppDispatch();
  const { location } = getUserDetails();
  const [searchParams] = useSearchParams();
  const orgName = searchParams.get('org') || '';
  const role = localStorage.getItem('role') || '';
  let param = '';
  if (role === ROLES.platAdmin) {
    param = `/?org=${orgName}`;
  }

  const text =
    data?.type === 'level'
      ? 'Are you sure you want to delete this level? All of the level details and data will be deleted'
      : 'Are you sure you want to delete this group? All of the group details and data will be deleted';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    setDeleteLoader(true);
    try {
      const response = await API.deleteGroup(data.grpId);
      if (response.status === 200) {
        dispatch(groupActions.deleteGroup(data.grpId));
        setDeleteLoader(false);
        setDelete(false);
        handleClose();
      }
    } catch (e) {
      setDeleteLoader(false);

      toast.error('Group failed to delete');
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const groupNameAbbriviation = data.name
    .split(' ')
    .map((each) => each[0])
    .join('')
    .toUpperCase();

  const startDate = moment.unix(data.startDate).format('MMM DD, YYYY');
  const color = data.status === 'Active' ? '#28A745' : '#6C757D';
  const useStyles = makeStyles({
    textStyle: {
      fontWeigth: '400!important',
      width: '220px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: '#0071A9',
      fontSize: '16px',
      fontFamily: 'Open Sans',
      marginLeft: '8px',
      cursor: 'pointer'
    }
  });

  const textCells = {
    fontWeigth: '400px !important',
    color: '#152536',
    fontSize: '16px',
    fontFamily: 'Open Sans'
  };

  //   const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  //     height: 10,
  //     borderRadius: 5,
  //     [`&.${linearProgressClasses.colorPrimary}`]: {
  //       backgroundColor:
  //         theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  //     },
  //     [`& .${linearProgressClasses.bar}`]: {
  //       borderRadius: 5,
  //       backgroundColor: "#28A745",
  //     },
  //   }));
  const classes = useStyles();
  return (
    <>
      <TableRow>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ChatAvatarComponent
              firstLetter={groupNameAbbriviation.slice(0, 3)}
              width="34px"
              height="34px"
              type="noStatus"
              image={_.get(data, 'logo', '')}
              fontSize="12px"
            />
            <span
              onClick={() => {
                dispatch(groupLoaderActions.updateState(true));
                navigate(`/app/programdetails/${orgId}/${data.orgId}/${data.grpId}/overview${param}`);
              }}
              className={classes.textStyle}
            >
              {data.name}
            </span>
          </Box>
        </TableCell>

        <TableCell style={textCells}>{formatNumberWithCommas(data.enrolled)}</TableCell>
        {/* <TableCell>350</TableCell> */}
        {/* <TableCell>$100</TableCell> */}
        {/* <TableCell>
        {" "}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <BorderLinearProgress
            variant="determinate"
            value={30}
            sx={{
              width: "70px",
              height: "8px",
              background: "#DF6438",
            }}
          />
          <span
            style={{
              color: "#68717A",
              opacity: "0.8",
              marginLeft: "6px",
              fontSize: "14px",
            }}
          >
            30%
          </span>
        </Box>
       </TableCell> */}
        {/* <TableCell>12</TableCell> */}
        {/* <TableCell>28</TableCell> */}
        <TableCell style={textCells}>{startDate}</TableCell>
        {/* <TableCell>May 14, 2023</TableCell> */}
        <TableCell style={textCells}>
          <Box
            sx={{
              background: '#fff',
              border: `1px solid ${color}`,
              borderRadius: '5px',
              width: '54px',
              height: '20px',
              color,
              fontSize: '12px',
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {data.status}
          </Box>
        </TableCell>
        <TableCell style={textCells}>
          <IconButton
            onClick={handleClick}
            disabled={(role === ROLES.orgAdmin || role === ROLES.member) && data.type === 'level'}
          >
            <MoreVertIcon color="primary" />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            // disablePortal={true}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right'
            }}
          >
            {data.type !== 'level' && (
              <Box
                className="PopoverListStyle"
                onClick={() => {
                  dispatch(groupLoaderActions.updateState(true));
                  dispatch(
                    breadCrumbsActions.updateBreadCrumbs({
                      id: data.grpId,
                      name: data.name,
                      url: `/app/programdetails/${orgId}/${data.orgId}/${data.grpId}/overview`
                    })
                  );
                  navigate(`/app/programdetails/${orgId}/${data.orgId}/${data.grpId}/settings`, {
                    state: { noUpdateAPICall: true }
                  });
                }}
              >
                <EditIcon />
                <Typography color="primary">Edit</Typography>
              </Box>
            )}

            {/* show delete group only for platform admin */}
            {role === ROLES.platAdmin && (
              <Box className="PopoverListStyle" onClick={() => setDelete(true)}>
                <DeleteIcon />
                <Typography color="primary">Delete</Typography>
              </Box>
            )}
          </Popover>
        </TableCell>
      </TableRow>

      <ProgramDeletDialog
        openDelete={openDelete}
        setDelete={setDelete}
        deleteLoader={deleteLoader}
        handleDelete={handleDelete}
        // text={`Are you sure you want to delete this group? All of the group details
        // and data will be deleted`}
        text={text}
      />
    </>
  );
};

export default ProgramTableRow;
