/* eslint-disable guard-for-in */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useState } from 'react';
import { Grid, TextField, InputLabel, DialogContent, DialogActions } from '@mui/material';
import { Social, socialIconsArray } from './SocialIcon';
import { LoadingButton } from '@mui/lab';
import { Controller, SubmitHandler, FieldValues, useForm } from 'react-hook-form';
import { API } from '../../../api';
import { getUserDetails } from '../../../utils/orgName';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { userActions, userSocialUpdate } from '../../../redux/slices/user-profile/userProfieSlice';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import { popupCountActions } from '../../../redux/slices/poupsCountsSlice';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { companyActions } from '../../../redux/slices/companySlice';

interface Props {
  socialData: any;
}
interface RootState {
  popupsCounts: {
    count: number;
    popuplength: number;
  };
}
const schema = yup.object({
  linkedIn: yup.string().matches(/^(?:$|(https?:\/\/)?(?:www\.)?(linkedin\.com\/.*)$)/, 'Invalid Url'),
  facebook: yup
    .string()
    .matches(/^(?:$|(https?:\/\/)?(?:www\.)?facebook\.com\/.*)$/, 'Invalid Url')
    .nullable(),
  twitter: yup
    .string()
    .nullable()
    .matches(/^(?:$|(https?:\/\/)?(?:www\.)?twitter\.com\/.*)$/, 'Invalid Url'),
  instagram: yup
    .string()
    .matches(/^(?:$|(https?:\/\/)?(?:www\.)?instagram\.com\/.*)$/, 'Invalid Url')
    .nullable(),
  youtube: yup
    .string()
    .nullable()
    .matches(/^(?:$|(https?:\/\/)?(?:www\.)?(?:youtube\.com\/.*|youtu\.be\/.*))$/, 'Invalid Url')
});

const resolver = yupResolver(schema);
const checkError = (errors: Record<string, any>, fieldName: string) => Boolean(errors[fieldName]);
const getError = (errors: Record<string, any>, fieldName: string) => errors[fieldName]?.message;

const SocialHandlesForm = () => {
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const socialData = useProfileDetails().social;
  const tasksData = useAppSelector((state) => state.mentorTasks);
  const popuplength = useAppSelector((state) => state.popupsCount.popuplength);
  const count = useAppSelector((state) => state.popupsCount.count);
  const nextPopUpOrNot = popuplength - count;
  const { userId, location } = getUserDetails();
  const [socialLoader, setSocialLoader] = useState(false);
  const { ftue } = useProfileDetails();

  const handleCloseSocial = () => {
    dispatch(popupActions.updatePopups({ social: false }));
    reset(socialData);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset
  } = useForm({
    resolver: resolver
  });

  useEffect(() => {
    if (isSubmitClicked) {
      const hasInvalidUrlError = Object.values(errors).some((error) => error && error.message === 'Invalid Url');
      if (hasInvalidUrlError) {
        toast.error(`We can't find this social handle, please try again`);
      }
      setIsSubmitClicked(false);
    }
  }, [errors]);

  const dispatch = useAppDispatch();

  const updateFtue = async () => {
    setSocialLoader(true);
    try {
      const response = await API.updateUserFtue({ ftue: false, userId });
      if (response.status === 200) {
        dispatch(userActions.userFtueUpdate(0));
        setSocialLoader(false);
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Social handles failed to update`));
      setSocialLoader(false);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    if (ftue && nextPopUpOrNot !== 1) {
      dispatch(popupCountActions.increment(0));
    }
    setSocialLoader(true);
    const { facebook, instagram, linkedIn, twitter, youtube } = values;
    const data: Social = { facebook, instagram, linkedIn, twitter, youtube };
    const { orgId } = getUserDetails();

    try {
      const response = await API.updateSocialLinks({ orgId, data });
      localStorage.setItem('social', JSON.stringify(data));
      if (response.status === 200) {
        setSocialLoader(false);
        handleCloseSocial();
        dispatch(userSocialUpdate(response.data?.user_metadata?.social));
        if (nextPopUpOrNot === 1) {
          dispatch(companyActions.update(true));

          await updateFtue();
        }
      }
    } catch (e) {
      setSocialLoader(false);
      toast.error('Social handles failed to update');
    }
  };

  useEffect(() => {
    const data = localStorage.getItem('social');
    const requiredData = data && ftue ? JSON.parse(data) : socialData;
    reset(requiredData);
  }, []);

  useEffect(() => {
    return () => {
      handleCloseSocial();
    };
  }, []);

  const getDialogActions = () => {
    return (
      <DialogActions>
        {ftue && (
          <LoadingButton
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={() => dispatch(popupCountActions.decrement(0))}
          >
            Back
          </LoadingButton>
        )}

        <LoadingButton
          color="primary"
          variant="contained"
          fullWidth
          loading={socialLoader}
          onClick={() => {
            setIsSubmitClicked(true);
            handleSubmit(onSubmit)();
          }}
          id="socialHandlesSubmit"
        >
          {nextPopUpOrNot === 1 ? 'Finish' : nextPopUpOrNot > 1 ? 'Next' : 'Save'}
        </LoadingButton>
      </DialogActions>
    );
  };

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          {socialIconsArray?.map((each) => {
            return (
              <Grid item xs={12} sm={6} key={each.id}>
                <InputLabel>{each.label === 'Linkedin' ? 'Linkedin' : each.label}</InputLabel>
                <Controller
                  name={each.name}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      required
                      name={each.name}
                      value={value || ''}
                      type="text"
                      id={each.name}
                      placeholder="Enter URL"
                      variant="outlined"
                      fullWidth
                      onChange={onChange}
                      error={checkError(errors, each.name)}
                      helperText={getError(errors, each.name)?.toString()}
                    />
                  )}
                />
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      {getDialogActions()}
    </>
  );
};

export default SocialHandlesForm;
