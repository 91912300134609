/* eslint-disable no-duplicate-imports */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import favicon from '../../../../assets/images/favicon.svg';
import {
  Box,
  Grid,
  // Radio,
  Select,
  Divider,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  // RadioGroup,
  // FormControl,
  // Autocomplete,
  FormHelperText
  // FormControlLabel
} from '@mui/material';
import _ from 'lodash';
import { useForm, Controller, FieldValues, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { API } from '../../../../api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import ROLES from '../../../../utils/roles';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../../../redux/hooks';
import { getUserDetails } from '../../../../utils/orgName';
import useOrgSettings from '../../../../hooks/useOrgSettings';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { fetchGroupDetails } from '../../../../redux/slices/group-details/groupDetails';
import { Members } from '../Program/components/program tabs/settings components/Members';
import OrganizationInformationLoader from '../../../../Loader/OrganizationInformationLoader';
import { handleImageCompression, imageSizeLimit, showMyLoader } from '../../components/common';
import { IOrgData, orgSettingsActions } from '../../../../redux/slices/orgDetails/orgDetailsSlice';
import CurrencySelect from '../Program/components/program tabs/settings components/CurrencySelect';
import { ORG_TYPES } from '../../../../utils/constants';
import useGroupDetails from '../../../../queries/useGroupDetails';

const imgSize = imageSizeLimit();

interface Props {
  data: IOrgData;
}

const OrganizationInformation = ({ data }: Props) => {
  const loading = showMyLoader();
  const [saveLoader, setSaveLoader] = useState(false);
  const [imgError, setImgError] = useState('');
  const dispatch = useAppDispatch();

  const schema = yup.object({
    orgName: yup
      .string()
      .required('Organization name is required')
      .matches(/^[a-zA-Z\s\S]+$/, `Name should contain only letters, spaces, and special characters`)
      .test('no-leading-spaces', 'Name cannot accept empty spaces', (value) => {
        if (value) {
          return !/^\s+$/.test(value);
        }
        return true;
      })
      .min(3, 'Minimum 3 characters'),
    orgType: yup.string().required('Organization type is required'),

    userTypes: yup.array().min(1, 'Member type is required').required('Member type is required'),
    mentee: yup
      .mixed()
      .nullable()
      .test({
        message: 'This field is required',
        test: (value) => {
          const menteeValues: any = getValues('mentee');
          const menteeValuesArray = typeof menteeValues === 'string' ? [menteeValues] : menteeValues;
          return Array.isArray(menteeValuesArray) && menteeValuesArray.length > 0;
        }
      }),
    mentor: yup
      .mixed()
      .nullable()
      .test({
        message: 'This field is required',
        test: (value) => {
          const mentorValues: any = getValues('mentor');
          const mentorValuesArray = typeof mentorValues === 'string' ? [mentorValues] : mentorValues;
          return Array.isArray(mentorValuesArray) && mentorValuesArray.length > 0;
        }
      }),

    description: yup.string().max(1500, 'Maximum 1500 characters'),
    image: yup
      .mixed()
      .test('fileValidation', '', (value, { createError }) => {
        if (!value || !(value instanceof File)) {
          return true;
        }
        if (value.size > imgSize.limitValue) {
          setImgError('');
          return createError({
            message: 'The file is too large. Allowed maximum size is ' + imgSize.limitText
          });
        }
        if (!/\.(jpg|jpeg|png|svg)$/i.test(value.name)) {
          setImgError('');
          return createError({
            message: 'Only jpg, png and svg files are allowed'
          });
        }
        return true;
      })
      .nullable()
  });

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema)
  });
  const { location } = getUserDetails();
  const grpId = useParams().id || '';
  const checkError = (fieldName: string) => Boolean(errors[fieldName]);
  const getError = (fieldName: string) => errors[fieldName]?.message;
  const isOrgSettings = useOrgSettings();

  const { refetch: fetchGroupDetails } = useGroupDetails(grpId, location);

  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    setSaveLoader(true);
    const trimmedValues: FieldValues = {
      ...values,
      orgName: values.orgName.trim()
    };
    const formData = new FormData();
    Object.keys(trimmedValues).forEach((key) => formData.append(key, trimmedValues[key]));
    try {
      const response = await API.updateOrganizationSettings({
        orgId: data.authId,
        formData
      });
      if (response.status === 200) {
        dispatch(orgSettingsActions.updateOrgSettings(response.data));
        setSaveLoader(false);
        toast.success('Organization settings updated successfully');
        const role = localStorage.getItem('role');
        if (!isOrgSettings && role !== ROLES.platAdmin) {
          window.location.replace('/');
        }
        if (!isOrgSettings && role === ROLES.platAdmin) {
          fetchGroupDetails();
        }
      }
    } catch (e) {
      setSaveLoader(false);
      toast.error(`Organization settings failed to update`);
    }
  };

  useEffect(() => {
    reset({
      orgName: data.name,
      description: data.description,
      image: data.orgLogo,
      orgType: data.orgType
    });
  }, []);

  useEffect(() => {
    // setValue('userTypes', data?.displayRoles?.admin ? [ data.displayRoles.admin ] : []);
    setValue('mentee', data?.displayRoles?.mentee ? [data.displayRoles.mentee] : []);
    setValue('mentor', data?.displayRoles?.mentor ? [data.displayRoles.mentor] : []);
    setValue('currency', data?.currency || []);
    setValue('userTypes', data?.userTypes || []);
  }, [data, setValue]);

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setImgError('');
    const photo = event.target.files ? event.target.files[0] : null;

    if (photo && photo.size > imgSize.limitValue) {
      setImgError('The file is too large. Allowed maximum size is ' + imgSize.limitText);
    }

    if (photo) {
      const fileType = photo.type.toLowerCase();

      if (fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/png') {
        try {
          const compressedImage = await handleImageCompression(photo);
          if (compressedImage) {
            // Use setValue from react-hook-form to update the form value
            setValue('image', compressedImage);
          }
        } catch (error) {
          // Handle compression error
        }
      } else {
        setImgError('Only JPG, JPEG, and PNG files are allowed for the cover image.');
      }
    }
  };

  if (loading) {
    return <OrganizationInformationLoader />;
  }

  return (
    <Box bgcolor={'#FFFFFF'} borderRadius={'8px'} border={'1px solid #EFF0F4'}>
      <Box p={2}>
        <Typography variant="h5">Organization Information</Typography>
      </Box>
      <Divider />
      <Box>
        <Box p={2}>
          {/* Profile Image */}
          <Box display="flex" gap={2} alignItems="center" mb={2}>
            <Controller
              name="image"
              control={control}
              render={({ field }) => (
                <>
                  <Box className="profileImageBox" position="relative">
                    <label htmlFor="selectedImage" className="cursor-pointer">
                      <Box position="relative">
                        <ChatAvatarComponent
                          height="80px"
                          width="80px"
                          firstLetter={'Organization'.slice(0, 1)}
                          image={
                            field.value
                              ? typeof field.value === 'string'
                                ? field.value
                                : URL.createObjectURL(field.value)
                              : favicon
                          }
                          type="noStatus"
                          cursor="pointer"
                        />
                        <Box top="0px" className="ProfileCameraBgOverlay">
                          <CameraAltOutlinedIcon className="IfNoIcon color-white" />
                        </Box>
                      </Box>
                    </label>
                    <input
                      id="selectedImage"
                      name="image"
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={handleImageChange}
                    />
                  </Box>
                  <Box>
                    <Box display="flex" alignItems="center" gap="8px">
                      <Typography variant="h5" color={'#000'}>
                        Profile Image
                      </Typography>
                      {/* <Typography color="error" variant="caption" className="cursor-pointer">Remove</Typography> */}
                    </Box>
                    <Typography variant="caption" color="#999999">
                      *Only JPG, PNG, SVG, or GIF (max. 800x800 px)
                    </Typography>
                  </Box>
                </>
              )}
            />
          </Box>

          <Grid container spacing={2}>
            {/* Organization Name */}
            <Grid item xs={12} md={6}>
              <InputLabel>Organization name *</InputLabel>
              <Controller
                control={control}
                name="orgName"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    className="org-settings"
                    required
                    size="small"
                    onChange={onChange}
                    value={value || ''}
                    name="orgName"
                    type="text"
                    placeholder="Organization name"
                    variant="outlined"
                    error={checkError('orgName')}
                    helperText={getError('orgName')?.toString()}
                  />
                )}
              />
            </Grid>

            {/* Organization Type */}
            <Grid item xs={12} md={6}>
              <InputLabel>Organization type *</InputLabel>
              <Controller
                name="orgType"
                control={control}
                // defaultValue="" // Set the default value as an empty string
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="orgType"
                    id="orgType"
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                    required
                    displayEmpty
                    error={checkError('orgType')}
                    disabled={data.orgType === ORG_TYPES.govt}
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value={ORG_TYPES.highEd}>{ORG_TYPES.highEd}</MenuItem>
                    <MenuItem value={ORG_TYPES.govt}>{ORG_TYPES.govt}</MenuItem>
                    <MenuItem value={ORG_TYPES.corporate}>{ORG_TYPES.corporate}</MenuItem>
                  </Select>
                )}
              />
              <FormHelperText error>{getError('orgType')?.toString()}</FormHelperText>
            </Grid>

            {/* Description */}
            <Grid item xs={12}>
              <Controller
                control={control}
                name="description"
                render={({ field: { onChange, value } }) => (
                  <>
                    <Box display={'flex'} alignItems="center" justifyContent="space-between">
                      <InputLabel>Description</InputLabel>
                      <InputLabel>{value ? value.length : 0}/1500</InputLabel>
                    </Box>
                    <TextField
                      multiline
                      minRows={4}
                      maxRows={10}
                      name="description"
                      onChange={onChange}
                      value={value}
                      inputProps={{
                        maxLength: 1500
                      }}
                      placeholder="Description"
                    />
                    <FormHelperText error>{getError('description')?.toString()}</FormHelperText>
                  </>
                )}
              />
            </Grid>

            {/* Language */}
            {/* <Grid item xs={6}>
              <InputLabel>Language</InputLabel>
              <Controller
                control={control}
                name="language"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    options={languageOptions}
                    getOptionLabel={(option) => (option ? _.startCase(option) : '')}
                    renderInput={(params) => <TextField {...params} placeholder="Select" />}
                    value={value}
                    onChange={(_event, newValue) => onChange(newValue)}
                  />
                )}
              />
            </Grid> */}

            {/* Timezone */}
            {/* <Grid item xs={6}>
              <InputLabel>Timezone</InputLabel>
              <Controller
                control={control}
                name="timeZone"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    options={timeZoneOptions}
                    getOptionLabel={(option) => option || ''}
                    renderInput={(params) => <TextField {...params} placeholder="Select" />}
                    value={value}
                    onChange={(_event, newValue) => onChange(newValue)}
                  />
                )}
              />
            </Grid> */}

            {/*Location */}
            {/* <Grid item xs={12}>
              <InputLabel>Location</InputLabel>
              <Controller
                control={control}
                name="location"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    options={locationOptions}
                    getOptionLabel={(option) => (option ? _.startCase(option) : '')}
                    renderInput={(params) => <TextField {...params} placeholder="Select" />}
                    value={value}
                    onChange={(_event, newValue) => onChange(newValue)}
                  />
                )}
              />
            </Grid> */}
          </Grid>

          {/* Require microsite */}
          {/* <Box>
            <FormControl>
              <Box display={'flex'} alignItems={'center'}>
                <Typography py={2}>Require microsite</Typography>
                <Box ml={2}>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="requireMicrosite"
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </Box>
              </Box>
            </FormControl>
          </Box> */}

          {/* Microsite name */}
          {/* <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>Microsite name</InputLabel>
              <TextField
                required
                id="micrositeName"
                name="micrositeName"
                placeholder="organisationname.dosen.io"
                variant="outlined"
              />
            </Grid>
          </Grid> */}

          {/* Members */}
          <Divider className="my-20" />
          <Members control={control} place="OrganizationInformation" checkError={checkError} getError={getError} />

          {/* Currency */}
          <Divider className="my-20" />
          <Box>
            <CurrencySelect control={control} />
          </Box>

          {/* Save Button */}
          <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
            <LoadingButton
              color="primary"
              variant="contained"
              loading={saveLoader}
              className="w-150px h-50px"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrganizationInformation;
