/* eslint-disable guard-for-in */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { API } from '../../../api';
import { getUserDetails } from '../../../utils/orgName';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import { fetchUserEducation, userEducationActions } from '../../../redux/slices/user-education/userEducationSlice';
import WorkAndEduDesign from './WorkAndEduDesign';

export interface Education {
  [key: string]: string | string[] | null;
  minor: string[] | null;
  major: string;
  graduation_date: string;
  university: string;
  department: string;
}

export interface EducationError {
  minor: string;
  major: string;
  graduation_date: string;
  university: string;
  department: string;
}

export interface graduationDateError {
  graduation_date?: string;
}

const EducationAndSkills = () => {
  const { userId, location } = getUserDetails();

  const { updatedData: data, error, loading, errorText } = useAppSelector((state) => state.userEducation);
  const [dataInAscendingDate, setDataInAscendingDate] = useState<Education[]>([]);
  const education = useAppSelector((state) => state.onboardingPopups.data.education);
  // const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState('');
  const [action, setAction] = useState('Add');
  const dispatch = useAppDispatch();

  useEffect(() => {
    getEducationDetails();
  }, []);

  const handleClickOpenEducationDetailed = () => {
    dispatch(popupActions.updatePopups({ education: { open: true, action: 'Add', item: {} } }));
  };

  const handleEditEducation = (education: any) => {
    setActiveId(education._id);
    dispatch(popupActions.updatePopups({ education: { item: education, open: true, action: 'Edit' } }));
  };

  const getEducationDetails = async () => {
    dispatch(fetchUserEducation(userId));
    if (error) {
      toast.error('Education details failed to load');
    }
  };

  return (
    <Box>
      <Box
        px={2}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography variant="h5" py={2}>Education and Skills</Typography>
        <IconButton size='small' onClick={() => { handleClickOpenEducationDetailed() }}>
          <AddCircleOutlineIcon color='info' />
        </IconButton>
      </Box>
      <Divider />
      <Box p={2}>
        <WorkAndEduDesign
          data={data}
          itemType="education"
          wherePrint="profile"
          onEdit={handleEditEducation}
        />
      </Box>
    </Box>
  );
};

export default EducationAndSkills;
